import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../components/userEnd/common/UserLayout';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import { Box, Button, ButtonGroup, Center, Flex, Heading, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import Space from '../../components/utility/Space';
import { APPLY_FOR, STATUS } from '../../components/constants/Constants';

export default function UserDashboard() {
    const token = localStorage.getItem('my_token');
    const userDetails = useContext(UserContext);
    const {id} = userDetails;
    const finalRef = React.useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [applies, setApplies] = useState([]);

    const [ historyId, setHistoryId ] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        fetchApplies();
    }, [id]);

    const fetchApplies = async() => {
        setIsLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-applies-by-user-id/${id}`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setApplies(apiResponse.results?.applies);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    return (
        <>
            <Center>
                <Heading as='h2' size='xl'>
                    Apply Status
                </Heading>
            </Center>

            <Space />
        </>
    )
}
