import { ChakraProvider, extendTheme, Heading, ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
})

export default function Procedure(){
    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            PROCEDURES
                        </Heading>
                        <div className='header-text inner-box'>
                            <Heading size='sm'>
                                Now it is simple to apply for your ID Card. You have to go through the following simple steps
                            </Heading>
                            <div className='card-box'>
                                <div className='box-card card1'>
                                    <Heading size='sm'>
                                        1. Browse PIS Registration
                                    </Heading>
                                    <div className='desc'>
                                        Open your favorite browser and browse <Link to="#">pis.juniv.edu</Link>
                                    </div>
                                </div>
                                <div className='box-card card2'>
                                    <Heading size='sm'>
                                        2. Select Your Id card type
                                    </Heading>
                                    <div className='desc'>
                                        Choose between Teacher, Officer, Student & Staff
                                    </div>
                                </div>
                                <div className='box-card card3'>
                                    <Heading size='sm'>
                                        3. Fill up the forms
                                    </Heading>
                                    <div className='desc'>
                                        Provide correct informations about yourself
                                    </div>
                                </div>
                                <div className='box-card card4'>
                                    <Heading size='sm'>
                                        4. Review your information
                                    </Heading>
                                    <div className='desc'>
                                        Check your provided information again and proceed
                                    </div>
                                </div>
                                <div className='box-card card5'>
                                    <Heading size='sm'>
                                        5. Check Confirmation Email
                                    </Heading>
                                    <div className='desc'>
                                        Check your mail-box for the confirmation email
                                    </div>
                                </div>
                                <div className='box-card card6'>
                                    <Heading size='sm'>
                                        6. Make Payment
                                    </Heading>
                                    <div className='desc'>
                                        Make payment with the instructions given in the email
                                    </div>
                                </div>
                                <div className='box-card card7'>
                                    <Heading size='sm'>
                                        7. Track Your Id card
                                    </Heading>
                                    <div className='desc'>
                                        Login to the site with the provided credentials via email to track your Id card
                                    </div>
                                </div>
                                <div className='box-card card8'>
                                    <Heading size='sm'>
                                        8. Collect your Id card
                                    </Heading>
                                    <div className='desc'>
                                        Collect the Id card from University Central Library when you receive notification via email
                                    </div>
                                </div>
                            </div>

                            <div className='special-notice'>
                                <Heading size='md'>
                                    NB:
                                </Heading>

                                <UnorderedList className='lists'>
                                    <ListItem>You can also make your account first with the registration form</ListItem>
                                    <ListItem>With the registration you can browse your previous history and logs </ListItem>
                                    <ListItem>Moreover you can save your application as draft</ListItem>
                                    <ListItem>We highly recommend you to register to the site first and apply for Id card later</ListItem>
                                </UnorderedList>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
