// @ts-nocheck
import { ChakraProvider, extendTheme, Heading, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// @ts-ignore
import styles from "../../assets/css/modular/apply-form.module.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import customStyle from '../../assets/css/modular/custom-form.module.css';
import TextError from '../../components/common/TextError';
import HonsMastersStudentIdCard from '../../components/id-card/HonsMastersStudentIdCard';
import MphilPhdStudentIdCard from '../../components/id-card/MphilPhdStudentIdCard';
import EmployeeIdCard from '../../components/id-card/EmployeeIdCard';
import ImageResizeModal from '../../components/image-upload/ImageResizeModal';
import ToastMessage from '../../components/utility/ToastMessage';
import axios from 'axios';
import EveningCourseStudentIdCard from '../../components/id-card/EveningCourseStudentIdCard';

const theme = extendTheme({
    fonts: {
        heading: "Raleway",
        body: "Raleway",
    },
});

export default function ImageUpload() {
    const navigate = useNavigate();
    let application_data = localStorage.getItem('application_data');

    const [back_url, setBackUrl] = useState('');
    const [thumb, setThumb] = useState(application_data?.thumb);
    const [apiThumb, setApiThumb] = useState(application_data?.api_thumb);
    const [displayModal, setDisplayModal] = useState(false);
    const [err, setErr] = useState('');
    const [finalThumb, setFinalThumb] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
 
    let is_checked = true;
    let is_first_step = true;
    let is_second_step = true;
    let is_third_step = true;

    if (!application_data) {
        is_checked = false;
    } else {
        application_data = JSON.parse(application_data);
        if (!application_data.declaration || application_data.declaration !== true) {
            is_checked = false;
        }
        if (!application_data.declaration || application_data.first_step !== true) {
            is_first_step = false;
        }
        if (!application_data.declaration || application_data.second_step !== true) {
            is_second_step = false;
        }
        if (!application_data.declaration || application_data.third_step !== true) {
            is_third_step = false;
        }
    }

    let halls = [];
    let departments = [];
    let batches = [];
    let institutes = [];
    let offices = [];
    let designations = [];
    let eveningCourses = [];

    if(localStorage.getItem('halls')){
        halls = JSON.parse(localStorage.getItem('halls'));
    }
    if(localStorage.getItem('departments')){
        departments = JSON.parse(localStorage.getItem('departments'));
    }
    if(localStorage.getItem('batches')){
        batches = JSON.parse(localStorage.getItem('batches'));
    }
    if(localStorage.getItem('institutes')){
        institutes = JSON.parse(localStorage.getItem('institutes'));
    }
    if(localStorage.getItem('offices')){
        offices = JSON.parse(localStorage.getItem('offices'));
    }
    if(localStorage.getItem('designations')){
        designations = JSON.parse(localStorage.getItem('designations'));
    }
    if(localStorage.getItem('eveningCourses')){
        eveningCourses = JSON.parse(localStorage.getItem('eveningCourses'));
    }

    const checkIsDisabled = () => {
        if(application_data?.api_thumb && application_data?.api_thumb !== '') {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }

    useEffect(() => {
        if (is_checked == false) {
            ToastMessage("warning","Please check the declaration first");
            navigate('/apply-for-id');
        } else if (is_first_step == false) {
            ToastMessage("warning","Please complete first step first");
            navigate('/apply-first-step');
        } else if (is_second_step == false) {
            ToastMessage("warning","Please complete the second step first");
            navigate('/apply-second-step');
        } else {
            //set back url
            let prev_url = '';
            if(application_data?.user_type == 3){
                prev_url = '/register-teacher-data';
            }else if(application_data?.user_type == 4){
                prev_url = '/register-student-data';
            }else if(application_data?.user_type == 5){
                prev_url = '/register-mphil-phd-student-data';
            }else if(application_data?.user_type == 6){
                prev_url = '/register-officer-data';
            }else if(application_data?.user_type == 7){
                prev_url = '/register-staff-data';
            }else if(application_data?.user_type == 8){
                prev_url = '/register-evening-course-student-data';
            }else{
                prev_url = '/apply-for-id';
            }

            setBackUrl(prev_url);

            if (is_third_step == false) {
                ToastMessage("warning","Please complete previous steps first");
                navigate(back_url);
            }
        }
    }, [is_checked, is_first_step, is_second_step, is_third_step]);

    useEffect(() => {
        checkIsDisabled();
    },[]);

    const onSubmit = () => {
        let data = {...application_data};

        let form_data = new FormData();
        form_data.append('thumb',finalThumb);

        for(let key in data){
            if(typeof(data[key]) == 'object'){
                form_data.append(key, JSON.stringify(data[key]));
            }else{
                form_data.append(key,data[key]);
            }
        }

        let config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            }
        }
        axios.post(`${process.env.REACT_APP_API_URL}/api/apply-for-id-card`, form_data, config)
            .then((response) => {
                ToastMessage('success','Successfully applied. Please wait for approval. Check email for login info');
                localStorage.removeItem('application_data');
                setTimeout(
                    function() {
                        navigate('/apply-for-id');
                    }, 5000);
            })
            .catch((error) => {
                ToastMessage('error','Something went wrong');
                setIsDisabled(false);
            });
    };

    // const onSubmit = () => {
    //     if (thumb) {
    //         setIsDisabled(true);
    //         let data = {...application_data};

    //         let form_data = new FormData();
    //         form_data.append('thumb',finalThumb);

    //         for(let key in data){
    //             if(typeof(data[key]) == 'object'){
    //                 form_data.append(key, JSON.stringify(data[key]));
    //             }else{
    //                 form_data.append(key,data[key]);
    //             }
    //         }

    //         let config = {
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'multipart/form-data',
    //             }
    //         }
    //         axios.post(`${process.env.REACT_APP_API_URL}/api/apply-for-id-card`, form_data, config)
    //             .then((response) => {
    //                 ToastMessage('success','Successfully applied. Please wait for approval. Check email for login info');
    //                 localStorage.removeItem('application_data');
    //                 setTimeout(
    //                     function() {
    //                         navigate('/apply-for-id');
    //                     }, 5000);
    //             })
    //             .catch((error) => {
    //                 ToastMessage('error','Something went wrong');
    //                 setIsDisabled(false);
    //             });
    //     } else {
    //         setErr('Required');
    //     }
    // };

    const handleChange = (event) => {
        setErr('');
        setDisplayModal(true);
        setThumb(URL.createObjectURL(event.target.files[0]));
    }

    const showPreviewCards = (user_type) => {
        if(user_type == 4){
            return(
                <HonsMastersStudentIdCard
                    halls={halls}
                    departments={departments}
                    institutes={institutes}
                    dept_or_institute={application_data.dept_or_institute}
                    blood_group={application_data.blood_group}
                    registration_no={application_data.registration_no}
                    fullname={application_data.fullname}
                    batches={batches}
                    hall_id={application_data.hall_id}
                    batch={application_data.batch}
                    institute_id={application_data.institute_id}
                    department_id={application_data.department_id}
                    finalThumb={finalThumb}
                    api_thumb={application_data?.api_thumb}
                />
            )
        }
        else if(user_type == 5){
            return(
                <MphilPhdStudentIdCard
                    halls={halls}
                    departments={departments}
                    institutes={institutes}
                    dept_or_institute={application_data.dept_or_institute}
                    blood_group={application_data.blood_group}
                    registration_no={application_data.registration_no}
                    fullname={application_data.fullname}
                    apply_for={application_data.apply_for == 0 ? 'Mphil' : application_data.apply_for == 1 ? 'Phd' : '' }
                    hall_id={application_data.hall_id}
                    regularity={application_data.type == 0 ? 'Regular' : application_data.type == 1 ? 'Irregular' : '' }
                    institute_id={application_data.institute_id}
                    department_id={application_data.department_id}
                    session={application_data.session}
                    validity={application_data.validity}
                    finalThumb={finalThumb}
                />
            )
        }
        else if(user_type == 3 || user_type == 6 || user_type == 7){
            return(
                <EmployeeIdCard
                    offices={offices}
                    designations={designations}
                    fullname={application_data.fullname}
                    employee_id={application_data.employee_id}
                    designation_id={application_data.designation_id}
                    blood_group={application_data.blood_group}
                    office_id={application_data.office_id}
                    nid={application_data.nid}
                    dob={application_data.dob}
                    finalThumb={finalThumb}
                />
            )
        }

        else if(user_type == 8){
            return(
                <EveningCourseStudentIdCard
                    halls={halls}
                    eveningCourses={eveningCourses}
                    fullname={application_data.fullname}
                    registration_no={application_data.registration_no}
                    hall_id={application_data.hall_id}
                    blood_group={application_data.blood_group}
                    evening_course_id={application_data.evening_course_id}
                    session={application_data.session}
                    admission_year={application_data.admission_year}
                    batch={application_data.batch}
                    validity={application_data.validity}
                    finalThumb={finalThumb}
                />
            )
        }

    }

    return (
        <ChakraProvider theme={theme}>
            <Header />
            <div className='contents'>
                <div className='short-contents'>
                    <Heading className='header-text' size='lg'>
                        Apply for Id card : Final Step (Image Upload & Confirmation)
                    </Heading>
                    <br />

                    <div className={styles.apply_form}>
                        <form encType='multipart/form-data'>
                            <div className={`form-control ${customStyle.custom_form}`}>
                                <label className={customStyle.custom_label} htmlFor='thumb'>Thumb <span className='required'>*</span></label>
                                <input type='file' accept='image/*' name='thumb' onChange={handleChange}></input>
                                {err &&
                                    <TextError>{err}</TextError>
                                }
                            </div>
                            <div className={styles.neighbor_buttons}>
                                <Link to={back_url}><Button colorScheme="blue" type="button">Prev</Button></Link>
                                {console.log({isDisabled})}
                                <Button colorScheme="green" type="button" onClick={onSubmit} disabled={isDisabled}>Submit</Button>
                            </div>
                        </form>
                    </div>

                    <div className={styles.preview}>
                        <Heading className='header-text' size='md'>
                            Preview Id Card
                        </Heading>

                        {application_data?.user_type && showPreviewCards(application_data.user_type)}
                    </div>

                    {thumb && displayModal &&
                        <ImageResizeModal
                            setDisplayModal={setDisplayModal}
                            thumb={thumb}
                            setFinalThumb={setFinalThumb}
                            setIsDisabled={setIsDisabled}
                        />
                    }
                </div>
            </div>
            <Footer />
        </ChakraProvider>
    )
}
