import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function AddFaculty(){
    return <AddFacultyForm />;
}

const AddFacultyForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const [errors, setErrors] = useState({
        faculty: []
    });

    const fetchData = () => {
        let init = {
            faculty: ''
        };

        return init;
    }

    const validataionSchema = Yup.object({
        faculty: Yup.string()
                .required('Required'),
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/faculty`, values, config)
            .then((response) => {
                ToastMessage('success','Successfully added');
                navigate('/faculties');
            })
            .catch((error) => {
                const {data, status} = error?.response;
                const { errors } = data;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }

                if(status == 400) {
                    message = 'Data already exists';
                }

                setErrors({
                    ...errors,
                    faulty: errors?.faculty
                });

                ToastMessage('error',message);
            });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD FACULTY</Text>
            </Flex>

            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validataionSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='text' label='Faculty' name='faculty' placeholder="i.e.Faculty of ABC" />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}
