// @ts-nocheck
import { ChakraProvider, extendTheme, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import "../../assets/css/about.css";
// @ts-ignore
import aboutImg from "../../assets/images/sangsaptak.jpg";
import tamal from "../../assets/images/tamal.jpg";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
})

export default function About(){
    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='grey-contents'>
                        <div className='short-contents'>
                            <Heading className='header-text' size='lg'>
                                ABOUT US
                            </Heading>
                            <div className='about-step1'>
                                <div className='about-text'>
                                    <Text fontSize='md'>
                                        Jahangirnagar University accustomed as a residential accessible university in 1970. The sprawling arresting campus is anchored 30 kilometres from the basic Dhaka, able-bodied affiliated with a civic highway. Located at Savar abreast Dhaka, Jahangirnagar University is one of the arch Universities in Bangladesh. The University is a residential one. It was formally launched on 12 January 1970 by its aboriginal adjudicator Rear Admiral S.M. Ahsan, Governor of above East Pakistan. After Emergence of Bangladesh the Government allowable the Jahangirnagar University Act. 1973, which repealed the antecedent authorization and renamed the University as Jahangirnagar University.The University owes its present appearance to this act beneath which it is now functioning.
                                    </Text>
                                    <br />
                                    <Text fontSize='md'>
                                        This is a website that facilitates the university family members with Id card generation system.
                                        Teachers, Officers, Students and Staffs can apply for their ID card via online.
                                        The administration panel can manage the whole progress flow after application. If they approves the application
                                        the ID card is generated automatically for printing.
                                    </Text>
                                </div>
                                <div className='about-img'>
                                    <img src={aboutImg}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='white-contents'>
                        <div className='short-contents'>
                            <Heading className='header-text' size='lg'>
                                ADMINISTRATION PANEL
                            </Heading>

                            <Heading className='header-text-special' size='sm'>
                                We are really grateful to these people always
                            </Heading>
                            <hr />

                            <div className='images'>
                                <div>
                                    <img src={tamal}></img>
                                    <div className='short-desc'>
                                        <Heading className='header-text' size='sm'>
                                            MD. ABDUR RAHMAN
                                        </Heading>
                                        <p>Admin of JUPIS, Jahangirnagar University</p>
                                    </div>
                                </div>

                                {/* <div className='second-part'>
                                    <div className='first'>
                                        <img src={devi}></img>
                                        <div className='short-desc'>
                                            <Heading className='header-text' size='sm'>
                                                TEST NAME
                                            </Heading>
                                            <p>Head of Library, Jahangirnagar University</p>
                                        </div>
                                    </div>

                                    <div className='second'>
                                        <img src={devi}></img>
                                        <div className='short-desc'>
                                            <Heading className='header-text' size='sm'>
                                                TEST NAME
                                            </Heading>
                                            <p>Head of Library, Jahangirnagar University</p>
                                        </div>
                                    </div>

                                    <div className='third'>
                                        <img src={devi}></img>
                                        <div className='short-desc'>
                                            <Heading className='header-text' size='sm'>
                                                TEST NAME
                                            </Heading>
                                            <p>Head of Library, Jahangirnagar University</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
