import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';

const token = localStorage.getItem('my_token');

export default function EditFaculty(){
    const [isLoading, setIsLoading] = useState(false);
    const [ facultyInfo, setFacultyInfo ] = useState({});
    const { id } = useParams();

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    useEffect(() => {
        fetchFaculty(id);
    }, [id]);

    const fetchFaculty = async (id) => {
        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/faculty/${id}`, {
                    headers: config
                })
            ).data;

            const results = apiResponse.results;

            if (results?.faculty) {
                setFacultyInfo(results);
            }else{
                setFacultyInfo({});
            }
        } catch (err) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    const DisplayData = () => {
        return (
            <>
                { isLoading
                    ? <CustomSpinner />
                    : facultyInfo?.id
                        ? <EditFacultyForm facultyInfo={facultyInfo} id={id} />
                        : <p>Nothing Found with this</p>
                }
            </>
        )
    }

    return <DisplayData />;
}

const EditFacultyForm = ({facultyInfo, id}) =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const [errors, setErrors] = useState({
        faculty: []
    });

    const fetchData = () => {
        let init = {
            faculty: facultyInfo?.faculty
        };

        return init;
    }

    const validationSchema = Yup.object({
        faculty: Yup.string()
                .required('Required'),
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        axios
            .patch(`${process.env.REACT_APP_API_URL}/api/faculty/${id}`, values, config)
            .then((response) => {
                ToastMessage('success','Successfully added');
                navigate('/faculties');
            })
            .catch((error) => {
                const {data, status} = error?.response;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }

                const { errors } = data;

                setErrors({
                    ...errors,
                    faculty: errors?.faculty
                });

                ToastMessage('error',message);
            });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">EDIT FACULTY</Text>
            </Flex>

            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='text' label='Faculty' name='faculty' placeholder="i.e.Faculty of ABC" />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}
