// @ts-nocheck
import { ChakraProvider, extendTheme, Heading, Checkbox, Button } from '@chakra-ui/react';
import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
// @ts-ignore
import styles from "../../assets/css/modular/apply.module.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

import {
    Alert,
    AlertIcon,
  } from '@chakra-ui/react'
import ToastMessage from '../../components/utility/ToastMessage';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
});


export default function Apply(){
    let application_data = JSON.parse(localStorage.getItem('application_data'));
    const navigate = useNavigate();

    const [declaration, setDeclaration] = useState(() => {
        let data_declaration = false;
        if(application_data){
            if(application_data.declaration && application_data.declaration == true){
                data_declaration = true;
            }
        }
        return data_declaration;
    });

    const handleDeclaration = () => {
        let decide = declaration == true ? false : true;
        setDeclaration(decide);

        let application_data = JSON.parse(localStorage.getItem('application_data'));
        if(!application_data){
            application_data = {};
        }
        application_data.declaration = decide;
        localStorage.setItem('application_data', JSON.stringify(application_data));
    }

    const handleProceed = () => {
        if(declaration == true){
            navigate('/apply-first-step');
        }else{
            ToastMessage("warning","Please check the checkbox first");
        }
    }

    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            Apply for Id card
                        </Heading>
                        <br />
                        <div className={styles.apply_short_note}>
                            <Alert status='info'>
                                <AlertIcon />
                                There are three steps necessary to complete the application your Id card. You need to go through all the steps and provide neccessary informations.
                                We will make an account with the informations you provide and send login information to your email.
                            </Alert>
                        </div>

                        <div className={styles.disclaimer}>
                            <h3>Disclaimer:</h3>
                            <div>
                                Please go through the following declarations before proceeding. <br /><br/>
                                <ul>
                                    <li>You are applying for a hardcopy of identity card of Jahangirnagar University.</li>
                                    <li>You are ensuring that you are a valid member of Jahangirnagar University.</li>
                                    <li>You will not use this card after expiration of card.</li>
                                    <li>You are declaring that all the informations you will provide would be correct.</li>
                                    <li>You will be responsible for any wrong information provided.</li>
                                    <li>You will go through all the procedures to complete the registration.</li>
                                </ul>
                            </div>
                        </div>

                        <div className={styles.disclaimer_check}>
                            <Checkbox onChange={handleDeclaration} size="lg" colorScheme="green" defaultChecked={declaration}>I have read the instructions and agree</Checkbox>
                            <Button disabled={declaration == true ? false : true} onClick={handleProceed}>Proceed</Button>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
