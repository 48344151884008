import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Flex, IconButton, Input, Select, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillPeopleFill, BsFillTrashFill } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ceil } from 'lodash';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import ToastMessage from '../../components/utility/ToastMessage';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';

export default function Employees(){
    return <EmployeeList />;
}

const EmployeeList = () =>{
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [designations, setDesignations] = useState([]);
    const [offices, setOffices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const token = localStorage.getItem('my_token');
    const navigate = useNavigate();

    const [filterEmployeeId, setFilterEmployeeId] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterOffice, setFilterOffice] = useState('');
    const [filterDesignation, setFilterDesignation] = useState('');

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const user_type_param = useParams().user_type;
    let title = '';
    let user_type = '';
    if(user_type_param == 'teachers'){
        title = 'TEACHERS';
        user_type = '3';
    }
    else if(user_type_param == 'officers'){
        title = 'OFFICERS';
        user_type = '6';
    }
    else if(user_type_param == 'staffs'){
        title = 'STAFFS';
        user_type = '7';
    }else{
        navigate(-1);
    }

    useEffect(() => {
        fetchUsers();
        fetchDesignations();
        fetchOffices();
    }, [currentPage, user_type]);

    const fetchUsers = async () => {
        setIsLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                'user_type': user_type,
                'employee_id': filterEmployeeId,
                'name': filterName,
                'office': filterOffice,
                'designation': filterDesignation,
                'limit': limit,
                'page': currentPage
            }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-employees`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setUsers(apiResponse.results.users);
                setTotal(apiResponse.results.count);

                if (apiResponse.results.count) {
                    setTotalPages(ceil(apiResponse.results.count / limit));
                }
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    };

    const fetchDesignations = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params:{
                'user_type': user_type
            }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/designation`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setDesignations(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const fetchOffices = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params:{
                'types': [1,2]
            }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/office`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setOffices(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const getOfficeOptions = (offices_data) => {
        let return_data = [];

        if(offices_data.length > 0){
            for(let i=0;i<offices_data.length;i++){
                return_data.push(
                    <option key={i} value={offices_data[i].id}>{offices_data[i].office}</option>
                )
            }
        }
        return return_data;
    }

    const getDesignationOptions = (designations_data) => {
        let return_data = [];

        if(designations_data.length > 0){
            for(let i=0;i<designations_data.length;i++){
                return_data.push(
                    <option key={i} value={designations_data[i].id}>{designations_data[i].designation}</option>
                )
            }
        }
        return return_data;
    }

    const deleteData = (id) => {
        ToastMessage('info','Not developed yet');
        // axios
        //     .delete(`${process.env.REACT_APP_API_URL}/api/admin/${id}`,{
        //         headers: config
        //     })
        //     .then((response) => {
        //         fetchAdmins();
        //         ToastMessage("success","Deleted Successfully");
        //     })
        //     .catch((error) => {
        //         ToastMessage('error', 'Something went wrong');
        //     });
    }

    const onClickDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const handleEdit = (id) => {
        navigate('/edit-admin/'+id);
    }

    const handleSearch = () => {
        fetchUsers();
    }

    const handleClear = () => {
        setFilterEmployeeId('');
        setFilterName('');
        setFilterOffice('');
        setFilterDesignation('');
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>Employee Id</Th>
                        <Th textAlign={'center'}>Fullname</Th>
                        <Th textAlign={'center'}>Office</Th>
                        <Th textAlign={'center'}>Designation</Th>
                        <Th textAlign={'center'}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {users.length > 0 &&
                        users.map((user, idx) => (
                            <Tr
                                key={idx}
                                display={{
                                    base: 'grid',
                                    md: 'table-row',
                                }}
                                sx={{
                                    '@media print': {
                                    display: 'table-row',
                                    },
                                    gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                    gridGap: '10px',
                                }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    <Link className='link_details' to={'/user_details/'+user.id}>{user.employee_id}</Link>
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.fullname}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.office}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.designation}
                                </Td>
                                <Td textAlign={'center'}>
                                <ButtonGroup variant="solid" size="sm" spacing={3}>
                                    <IconButton
                                        colorScheme="green"
                                        aria-label="edit"
                                        icon={<AiFillEdit />}
                                        //onClick={() => handleEdit(user.id)}
                                    />
                                    <IconButton
                                        colorScheme="red"
                                        variant="outline"
                                        aria-label="delete"
                                        icon={<BsFillTrashFill />}
                                        onClick={() => onClickDelete(user.id)}
                                    />
                                </ButtonGroup>
                            </Td>
                            </Tr>
                        )
                        )
                    }
                </Tbody>
            </Table>
        )
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title" mb={2}>
                <Text fontSize="xl">{title}</Text>
            </Flex>

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Employee Id' size='md' value={filterEmployeeId} onChange={(e) => setFilterEmployeeId(e.target.value)} />
                    <Input placeholder='Name' size='md' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                    <Select placeholder='Select Office' size='md' value={filterOffice} onChange={(e) => setFilterOffice(e.target.value)}>
                        {getOfficeOptions(offices)}
                    </Select>
                    <Select placeholder='Select Designation' size='md' value={filterDesignation} onChange={(e) => setFilterDesignation(e.target.value)}>
                        {getDesignationOptions(designations)}
                    </Select>
                </Flex>

                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
                className="admin_table"
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>

            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}
