import React from 'react'

export const STATUS = [
    'Applied',
    'Payment Completed',
    'Application Confirmed',
    'Printed',
    'Delivered'
];

export const APPLY_FOR = [
    'Apply for hons',
    'Reissue for hons',
    'Apply for masters',
    'Reissue for masters',
    'Apply for mphill',
    'Reissue for mphill',
    'Apply for phd',
    'Reissue for phd',
    'Apply for teacher',
    'Reissue for teacher',
    'Apply for officer',
    'Reissue for officer',
    'Apply for staff',
    'Reissue for staff',
];

export const STUDENT_USER_TYPES=['4','5'];
export const EMPLOYEE_USER_TYPES=['3','6','7'];
