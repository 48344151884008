// @ts-nocheck
import React from 'react';
import { ErrorMessage, FieldArray } from 'formik';
import TextError from '../common/TextError';
import customStyle from '../../assets/css/modular/custom-form.module.css';
import FormikControl from './FormikControl';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';

export default function FieldArrResp(props) {
    const { name, values, office_options, designation_options, sample, ...rest } = props;
    return (
        <FieldArray name={name}>
            {fieldArrayProps => {
                return(
                    values.map((resp, index) => (
                        <div key={index}>
                            <FormikControl control='select' label='Office' name={`${name}[${index}].office_id`} options={office_options} />
                            <FormikControl control='select' label='Designation' name={`${name}[${index}].designation_id`} options={designation_options} />
                            <FormikControl control='date' type='date' label='Start From' name={`${name}[${index}].start_date`} />
                            <div className={customStyle.buttons}>
                                <ButtonGroup spacing={2}>
                                    <Button colorScheme='blackAlpha' size="sm" onClick={() => (fieldArrayProps.push(sample))}><AddIcon /></Button>
                                    {index > 0 &&
                                        <Button colorScheme='red' size="sm" onClick={() => (fieldArrayProps.remove(index))}><MinusIcon /></Button>
                                    }
                                </ButtonGroup>
                            </div>
                        </div>
                    ))
                )
            }}
        </FieldArray>
    )
}
