import React from "react";
import banner from "../../assets/jahangirnagar-university-logo.png";
import "../../assets/css/footer.css";
import { Heading, Text } from "@chakra-ui/react";

export default function Footer(){
    return(
        <div className="footer-container">
            <div className="title-div">
                <Heading as='h3' size='md'>
                    JU ID Card Management
                </Heading>
                <Text fontSize='md'> Visit Ju Website <a href='https://juniv.edu/' target="_blank">Click Here</a> </Text>
            </div>
            <div className="logo-div">
                <img src={banner} />
            </div>
            <div className="others-div">
                Copyright: ©Techavancer 2023
            </div>
        </div>
    )
}
