import React, { useEffect, useState } from 'react';
import './batches.css';
import {
  Flex,
  ButtonGroup,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Badge,
  Box,
  Button,
  Input,
  Select,
  Text
} from '@chakra-ui/react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { ceil } from 'lodash';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import Space from '../../components/utility/Space';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../../components/common/CommonModal';

export const Batches = () => {
  return <BatchTableDemo />;
};

function BatchTableDemo() {
    const header = ['Batch', 'Session', 'Validity', 'Availability', 'Action'];
    const [batches, setBatches] = useState([]);
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [filterBatch, setFilterBatch] = useState('');
    const [filterAvailability, setFilterAvailability] = useState('');

    const token = localStorage.getItem('my_token');

    const getBatches = async () => {
        const params = {
            limit,
            page: currentPage,
            batch: filterBatch,
            availability: filterAvailability
        };

        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/batches`, {
                params: params,
                })
            ).data;
            const results = apiResponse.results;
            if (results[0] && results[0].length > 0) {
                setBatches(results[0]);
                if (results[1].count) {
                setTotalPages(ceil(results[1].count / limit));
                }
            }else{
                setBatches([]);
            }
        } catch (err) {
            ToastMessage('Something went wrong');
        }

        setIsLoading(false);
    };

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/batch/${id}`,{
                headers: config
            })
            .then((response) => {
                getBatches();
                alert("Deleted Successfully");
            })
            .catch((error) => {
                const {status} = error?.response;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }
                ToastMessage('error',message);
            });
    }

    useEffect(() => {
        getBatches();
    }, [currentPage]);

    const onClickBatchDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
            <Thead
                display={{
                base: 'none',
                md: 'table-header-group',
                }}
                sx={{
                '@media print': {
                    display: 'table-header-group',
                },
                }}
            >
                <Tr>
                {header.map((x) => (
                    <Th key={x} textAlign={'center'}>
                    {x}
                    </Th>
                ))}
                </Tr>
            </Thead>
            <Tbody
                display={{
                base: 'block',
                lg: 'table-row-group',
                }}
                sx={{
                '@media print': {
                    display: 'table-row-group',
                },
                }}
            >
                {batches.length > 0 &&
                    batches.map((batch, idx) => (
                        <Tr
                            key={idx}
                            display={{
                                base: 'grid',
                                md: 'table-row',
                            }}
                            sx={{
                                '@media print': {
                                display: 'table-row',
                                },
                                gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                gridGap: '10px',
                            }}
                        >
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {batch.batch}
                            </Td>
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {batch.session}
                            </Td>
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {batch.validity}
                            </Td>
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {batch.is_available == '1'
                                    ? <Badge w="4.5rem" colorScheme="green">Active</Badge>
                                    : <Badge w="4.5rem" colorScheme="red">Inactive</Badge>
                                }
                            </Td>
                            <Td textAlign={'center'}>
                                <ButtonGroup variant="solid" size="sm" spacing={3}>
                                    <IconButton
                                        colorScheme="green"
                                        aria-label="edit"
                                        icon={<AiFillEdit />}
                                        onClick={() => navigate(`/batch/${batch?.batch}/edit`)}
                                    />
                                    <IconButton
                                        colorScheme="red"
                                        variant="outline"
                                        aria-label="delete"
                                        icon={<BsFillTrashFill />}
                                        onClick={() => onClickBatchDelete(batch?.batch)}
                                    />
                                </ButtonGroup>
                            </Td>
                        </Tr>
                    )
                    )
                }
            </Tbody>
            </Table>
        )
    }

    const handleSearch = () => {
        setCurrentPage(1);
        getBatches();
    }

    const handleClear = () => {
        setFilterBatch('');
        setFilterAvailability('');
    }

    const navigate = useNavigate();

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">BATCHES</Text>
            </Flex>

            <Flex justifyContent={'center'} w={{ base: '100%', md: '100%', sm: 'full' }} className="admin_add_button">
                <Box>
                    <Button
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        variant={'solid'}
                        size="sm"
                        onClick={() => navigate('/batch/add')}
                    >
                        Add New
                    </Button>
                </Box>
            </Flex>

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={handleDelete}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Batch' size='md' value={filterBatch} onChange={(e) => setFilterBatch(e.target.value)} />
                    <Select placeholder='Select Availability' size='md' value={filterAvailability} onChange={(e) => setFilterAvailability(e.target.value)}>
                        <option value='1'>Active</option>
                        <option value='0'>Inactive</option>
                    </Select>
                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
}
