import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function AddInstitute() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const initialValues = {
        institute: '',
        shortname: ''
    };

    const validationSchema = Yup.object({
        institute: Yup.string().max(256, 'Please enter within 256 characters').required('Institute is required'),
        shortname: Yup.string().max(256, 'Please enter within 256 characters').required('Shortname is required'),
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        setIsLoading(true);

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/institute`, values, config)
            .then((response) => {
                setIsLoading(false);
                ToastMessage('success', 'Successfully added');
                navigate('/institutes');
            })
            .catch((error) => {
                const {data, status} = error?.response;
                const { errors } = data;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }

                if(status == 400) {
                    message = 'Data already exists';
                }

                setIsLoading(false);
                ToastMessage('error',message);
            });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD INSTITUTE</Text>
            </Flex>
            <div className={styles.apply_form}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='text' placeholder='Enter Institute' label='Institute' name='institute' />
                                <FormikControl required={true} control='input' type='text' placeholder='Enter shortname' label='Shortname' name='shortname' />

                                <div>
                                    <Button disabled={isLoading} colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>

        </>
    )
}
