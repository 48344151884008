import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, ChakraProvider, extendTheme, Heading } from '@chakra-ui/react';
import React from 'react';
import "../../assets/css/faq.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
})

export default function Faq(){
    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            FAQ
                        </Heading>
                        <br />
                        <Heading className='header-text' size='sm'>
                            You will find most of your answers here
                        </Heading>

                        <div className='faq-contents'>
                            <Accordion defaultIndex={[0]} allowMultiple>
                                <AccordionItem>
                                    <h2>
                                    <AccordionButton className='faq-button' _expanded={{ bg: '#262162', color: '#b3ff00' }}>
                                        <Box flex='1' textAlign='left'>
                                            Section 1 title
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} className="faq-body">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat.
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    {({ isExpanded }) => (
                                    <>
                                        <h2>
                                        <AccordionButton className='faq-button' _expanded={{ bg: '#262162', color: '#b3ff00' }}>
                                            <Box flex='1' textAlign='left'>
                                                Section 2 title
                                            </Box>
                                            {isExpanded ? (
                                            <MinusIcon fontSize='12px' />
                                            ) : (
                                            <AddIcon fontSize='12px' />
                                            )}
                                        </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} className="faq-body">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat.
                                        </AccordionPanel>
                                    </>
                                    )}
                                </AccordionItem>
                                {["1","2","3","4","5"].map((item, index) => (
                                    <AccordionItem key={index}>
                                    {({ isExpanded }) => (
                                    <>
                                        <h2>
                                        <AccordionButton className='faq-button' _expanded={{ bg: '#262162', color: '#b3ff00' }}>
                                            <Box flex='1' textAlign='left'>
                                                Section {item} title
                                            </Box>
                                            {isExpanded ? (
                                            <MinusIcon fontSize='12px' />
                                            ) : (
                                            <AddIcon fontSize='12px' />
                                            )}
                                        </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} className="faq-body">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat.
                                        </AccordionPanel>
                                    </>
                                    )}
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
