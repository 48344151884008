import React from "react";
import {
  Box,
  Stack,
  Heading,
  Flex,
  Text,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

const Header = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen());
  const navigate = useNavigate();

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding={3}
      bg="#262162"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5} color="#b3ff00">
        <Heading as="h1" size="md" letterSpacing={"tighter"}>
          <Link to="/">JUPIS</Link>
        </Heading>
      </Flex>

      <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
        <HamburgerIcon />
      </Box>
      <ToastContainer />

      <Stack
        direction={{ base: "column", md: "row" }}
        display={{ base: isOpen ? "block" : "none", md: "flex" }}
        width={{ base: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        fontSize="14px"
        gap={"8px"}
        mt={{ base: 3, md: 0 }}
      >
        <Link className="nav-link" to="/"><Text>Home</Text></Link>
        <Link className="nav-link" to="/procedure"><Text>Procedure</Text></Link>
        <Link className="nav-link" to="/about_us"><Text>About Us</Text></Link>
        <Link className="nav-link" to="/contact"><Text>Contact Us</Text></Link>
        <Link className="nav-link" to="/faq"><Text>FAQ</Text></Link>
        <Link className="nav-link" to="/register"><Text>Register</Text></Link>
        <Link className="nav-link" to="/apply-for-id"><Text>Apply for Id</Text></Link>
      </Stack>

      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        mt={{ base: 4, md: 0 }}
      >
        <Link to="/login">
            <Button
            variant="outline"
            _hover={{ bg: "white.700", borderColor: "rgb(166, 253, 4)" }}
            >
                Login
            </Button>
        </Link>
      </Box>
    </Flex>
  );
};

export default Header;
