// @ts-nocheck
import React, { useEffect } from 'react';
import { ChakraProvider, extendTheme, Heading, Button } from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import {Formik, Form} from 'formik';
// @ts-ignore
import styles from "../../assets/css/modular/apply-form.module.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import ToastMessage from '../../components/utility/ToastMessage';
import { isEmployee } from '../../components/common/CommonFunctions';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
});

export default function ApplySecondStep(){
    const navigate = useNavigate();
    let application_data = localStorage.getItem('application_data');
    let is_checked = true;
    let is_first_step = true;

    if(!application_data){
        is_checked = false;
    }else{
        application_data = JSON.parse(application_data);
        if(!application_data.declaration || application_data.declaration !== true){
            is_checked = false;
        }
        if(!application_data.declaration || application_data.first_step !== true){
            is_first_step = false;
        }
    }

    useEffect(() => {
        if(is_checked == false){
            ToastMessage("warning","Please check the declaration first");
            navigate('/apply-for-id');
        }

        if(is_first_step == false){
            ToastMessage("warning","Please complete the previous step first");
            navigate('/apply-first-step');
        }

    }, [is_checked, is_first_step]);

    const fetchData = () => {
        let initialValues = {
            fullname: '',
            blood_group: '',
            sex: '',
            dob: '',
            nid: '',
            birth_certificate: '',
            passport: '',
            present_address: '',
            permanent_address: '',
            is_fetched: false
        };

        initialValues.fullname = application_data?.fullname;
        initialValues.blood_group = application_data?.blood_group;
        initialValues.sex = application_data?.sex;
        initialValues.dob = application_data?.dob;
        initialValues.nid = application_data?.nid;
        initialValues.birth_certificate = application_data?.birth_certificate;
        initialValues.passport = application_data?.passport;
        initialValues.present_address = application_data?.present_address;
        initialValues.permanent_address = application_data?.permanent_address;
        initialValues.is_fetched = application_data?.is_fetched;

        return initialValues;
    }

    const validataionSchema = Yup.object({
        fullname: Yup.string().max(200, 'Please enter your name within 200 characters').required("Required"),
        blood_group: Yup.string().required("Required"),
        sex: Yup.string().required("Required"),
        dob: Yup.string().required("Required"),
        nid: isEmployee(application_data?.user_type) ? Yup.string().matches(/^\d+$/, 'Please enter digits only').required('Required') : Yup.string().matches(/^\d+$/, 'Please enter digits only'),
        passport: Yup.string().matches(/^[a-zA-Z0-9]*$/, 'Please enter only digits and characters'),
        birth_certificate: Yup.string().matches(/^\d+$/, 'Please enter digits only'),
    });

    const onSubmit = values => {
        application_data.fullname = values.fullname;
        application_data.blood_group = values.blood_group;
        application_data.sex = values.sex;
        application_data.dob = values.dob;
        application_data.nid = values.nid;
        application_data.birth_certificate = values.birth_certificate;
        application_data.passport = values.passport;
        application_data.present_address = values.present_address;
        application_data.permanent_address = values.permanent_address;
        application_data.second_step = true;
        localStorage.setItem('application_data',JSON.stringify(application_data));

        if(application_data.user_type == 3){
            navigate('/register-teacher-data');
        }else if(application_data.user_type == 4){
            navigate('/register-student-data');
        }else if(application_data.user_type == 5){
            navigate('/register-mphil-phd-student-data');
        }else if(application_data.user_type == 6){
            navigate('/register-officer-data');
        }else if(application_data.user_type == 7){
            navigate('/register-staff-data');
        }else if(application_data.user_type == 8){
            navigate('/register-evening-course-student-data');
        }
    };

    const sex_options = [
        {key: 1, value: "Male"},
        {key: 2, value: "Female"},
        {key: 3, value: "Other"},
    ];

    const blood_group_options = [
        {key: '', value: "Select an option"},
        {key: 'A+', value: "A+"},
        {key: 'A-', value: "A-"},
        {key: 'B+', value: "B+"},
        {key: 'B-', value: "B-"},
        {key: 'AB+', value: "AB+"},
        {key: 'AB-', value: "AB-"},
        {key: 'O+', value: "O+"},
        {key: 'O-', value: "O-"},
    ];

    // const handlePermanentAddress = (isChecked, params) => {
    //     if(isChecked) {
    //         params.permanent_address = params.present_address;
    //     }
    // }

    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            Apply for Id card : Second Step
                        </Heading>
                        <br />

                        <div className={styles.apply_form}>
                            <Formik
                                initialValues={fetchData()}
                                validationSchema={validataionSchema}
                                onSubmit={onSubmit}
                            >
                                {
                                    formik => (
                                        <Form>
                                            <FormikControl placeholder={'Enter your fullname'} required={true} control='input' type='text' label='Fullname' name='fullname' disabled={formik.values.is_fetched} />
                                            <FormikControl placeholder={'Select your blood group'} required={true} control='select' label='Blood Group' name='blood_group' options={blood_group_options} disabled={formik.values.is_fetched} />
                                            <FormikControl required={true} control='radio' label='Sex' name='sex' options={sex_options} disabled={formik.values.is_fetched} />
                                            <FormikControl placeholder={'Enter your date of birth'} required={true} control='date' type='date' label='Date of Birth' name='dob' disabled={formik.values.is_fetched} />
                                            <FormikControl placeholder={'Enter nid no'} required={isEmployee(application_data?.user_type)} control='input' type='text' label='Nid No' name='nid' />
                                            <FormikControl placeholder={'Enter your birth certificate no'} control='input' type='text' label='Birth Certificate No' name='birth_certificate' />
                                            <FormikControl placeholder={'Enter your passport no'} control='input' type='text' label='Passport No' name='passport' />
                                            <FormikControl placeholder={'Enter your present address'} control='textarea' type='text' label='Present Address' name='present_address' />
                                            {/* <input type="checkbox" onChange={(e) => handlePermanentAddress(e.target.checked, formik.values)} /> Permanent Address same as present address */}
                                            <FormikControl placeholder={'Enter your permanent address'} control='textarea' type='text' label='Permanent Address' name='permanent_address' />
                                            <div className={styles.neighbor_buttons}>
                                                <Link to='/apply-first-step'><Button colorScheme="blue" type="button">Prev</Button></Link>
                                                <Button colorScheme="green" type="submit">Next</Button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
