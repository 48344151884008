import React from 'react';
import { Pagination } from 'evergreen-ui';
import { Box } from '@chakra-ui/react';
import './EvergreenPagination.css';

export default function EvergreenPagination({ currentPage, totalPages, setCurrentPage }) {
  const handlePageChange = (targetPage) => {
    setCurrentPage(targetPage);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    return;
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    return;
  };

  return (
    <Box className="paginationArea">
      <Pagination
        page={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      ></Pagination>
    </Box>
  );
}
