import React from 'react';
import "../../assets/css/ju.css";

export default function Ju(){
    return(
        <div className='ju-container'>
            <h3>Welcome to Jahangirnagar University</h3>
            <p>Jahangirnagar University accustomed as a residential accessible university in 1970. The sprawling arresting campus is anchored 30 kilometres from the basic Dhaka, able-bodied affiliated with a civic highway. Located at Savar abreast Dhaka, Jahangirnagar University is one of the arch Universities in Bangladesh. The University is a residential one. It was formally launched on 12 January 1970 by its aboriginal adjudicator Rear Admiral S.M. Ahsan, Governor of above East Pakistan. After Emergence of Bangladesh the Government allowable the Jahangirnagar University Act. 1973, which repealed the antecedent authorization and renamed the University as Jahangirnagar University.The University owes its present appearance to this act beneath which it is now functioning.</p>
        </div>
    )
}
