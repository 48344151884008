import React, { useEffect, useState } from 'react';
import SidebarWithHeader from '../../components/sidebar/sidebar';
import './offices.css';
import {
  Flex,
  useColorModeValue,
  ButtonGroup,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  TableCaption,
  Badge,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  StatLabel,
  StatNumber,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  HStack,
  FormErrorMessage,
  useToast,
  InputGroup,
  InputLeftAddon,
  Text,
  InputLeftElement,
  Select,
} from '@chakra-ui/react';
import { NumberInputControl } from 'formik-chakra-ui';
import { AiFillEdit } from 'react-icons/ai';
import { BsBoxArrowUpRight, BsFillPeopleFill, BsFillTrashFill } from 'react-icons/bs';
import { AddIcon, CheckIcon, SearchIcon, ViewIcon } from '@chakra-ui/icons';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MdCancel } from 'react-icons/md';
import { IoReturnUpBack } from 'react-icons/io5';
import axios from 'axios';
import { ceil } from 'lodash';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import ToastMessage from '../../components/utility/ToastMessage';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../../components/common/CommonModal';

export const Offices = () => {
  return <OfficeTableDemo />;
};


const validationSchema = Yup.object({
  office: Yup.string().required('Office is required'),
  shortname: Yup.string().required('Shortname is required'),
});

function OfficeTableDemo() {
    const header = ['Office', 'Shortname', 'Type', 'Action'];
    const [offices, setOffices] = useState([]);
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const [filterOffice, setFilterOffice] = useState('');
    const [filterShortname, setFilterShortname] = useState('');
    const [filterType, setFilterType] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const token = localStorage.getItem('my_token');

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const getOffices = async () => {
        setIsLoading(true);

        const params = {
            limit,
            page: currentPage,
            office: filterOffice,
            shortname: filterShortname,
            type: filterType,
        };

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/office`, {
                    params: params,
                    headers: config
                })
            ).data;

            const results = apiResponse.results;
            if (results[0] && results[0].length > 0) {
                setOffices(results[0]);
                if (results[1].count) {
                    setTotalPages(ceil(results[1].count / limit));
                }
            }
        } catch (err) {
            ToastMessage('error','Something went wrong');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getOffices();
    }, [currentPage]);

    const handleSearch = () => {
        setCurrentPage(1);
        getOffices();
    }

    const handleClear = () => {
        setFilterOffice('');
        setFilterShortname('');
    }

    const onClickOfficeDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const types = [
        { key: '1', value: 'Department'},
        { key: '2', value: 'Institute'},
        { key: '3', value: 'Others'},
    ];

    const getTypeName = (type) => {
        let rtn = types.find((item) => {
            return item.key == type;
        });

        return rtn?.value;
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/office/${id}`,{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                getOffices();
                alert("Deleted Successfully");
            })
            .catch((error) => {
                const {status} = error?.response;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }
                ToastMessage('error',message);
            });
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                    {header.map((x) => (
                        <Th key={x} textAlign={'center'}>
                        {x}
                        </Th>
                    ))}
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {offices.map((office, idx) => {
                    return (
                        <Tr
                            key={idx}
                            display={{
                                base: 'grid',
                                md: 'table-row',
                            }}
                            sx={{
                                '@media print': {
                                display: 'table-row',
                                },
                                gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                gridGap: '10px',
                            }}
                        >
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {office.office}
                            </Td>
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {office.shortname}
                            </Td>
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {getTypeName(office.type)}
                            </Td>
                            <Td textAlign={'center'}>
                                <ButtonGroup variant="solid" size="sm" spacing={3}>
                                <IconButton
                                    colorScheme="green"
                                    aria-label="edit"
                                    icon={<AiFillEdit />}
                                    onClick={() => navigate(`/office/${office.id}/edit`)}
                                />
                                <IconButton
                                    colorScheme="red"
                                    variant="outline"
                                    aria-label="delete"
                                    icon={<BsFillTrashFill />}
                                    onClick={() => onClickOfficeDelete(office.id)}
                                />
                                </ButtonGroup>
                            </Td>
                        </Tr>
                    );
                    })}
                </Tbody>
            </Table>
        )
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">OFFICES</Text>
            </Flex>

            <Flex justifyContent={'center'} w={{ base: '100%', md: '100%', sm: 'full' }} className="admin_add_button">
                <Box>
                    <Button
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        variant={'solid'}
                        size="sm"
                        onClick={() => navigate('/office/add')}
                    >
                        Add New
                    </Button>
                </Box>
            </Flex>

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={handleDelete}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Office' size='md' value={filterOffice} onChange={(e) => setFilterOffice(e.target.value)} />
                    <Input placeholder='Shortname' size='md' value={filterShortname} onChange={(e) => setFilterShortname(e.target.value)} />
                    <Select placeholder='Select Type' size='md' value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                        {types.map((item, index) => (
                            <option key={index} value={item.key}>{item.value}</option>
                        ))}
                    </Select>
                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
}
