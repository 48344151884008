import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react'
import styles from '../../assets/css/modular/custom-form.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function PasswordChange() {
    const initialValues = {
        current_password: '',
        new_password: '',
        new_confirm_password: ''
    };

    const token = localStorage.getItem('my_token');

    const validataionSchema = Yup.object({
        current_password:  Yup.string().required("Current Password is required"),
        new_password: Yup.string().required("New Password is Required").min(7, 'Your password must be at least 7 characters.'),
        new_confirm_password: Yup.string().required("Confirm new password is required").oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    });

    const onSubmit = (values, { resetForm }) => {
        let config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
              }
        };

        axios
        .post(`${process.env.REACT_APP_API_URL}/api/password_change`, values, config)
        .then((response) => {
            resetForm();
            ToastMessage('success','Password Changed Successfully');
        })
        .catch((error) => {
            ToastMessage('error','Something went wrong');
        });
    };

    const refreshData = () => {

    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title" mb={'3px'}>
                <Heading as='h2' size='xl'>
                    Change Password
                </Heading>
            </Flex>
            <div className={styles.custom_form}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validataionSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} placeholder={'Enter current password'} control='input' type='password' label='Current Password' name='current_password' />
                                <FormikControl required={true} placeholder={'Enter new password'} control='input' type='password' label='New Password' name='new_password' />
                                <FormikControl required={true} placeholder={'Confirm new password'} control='input' type='password' label='Confirm Password' name='new_confirm_password' />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>

        </>
    )
}
