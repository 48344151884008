// @ts-nocheck
import React from 'react';
import { ErrorMessage, FieldArray } from 'formik';
import TextError from '../common/TextError';
import customStyle from '../../assets/css/modular/custom-form.module.css';
import FormikControl from './FormikControl';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';

export default function FieldArrEdu(props) {
    const { name, values, education_institutes_options, sample, ...rest } = props;
    const is_current_options = [
        {key: 1, value: "Yes"},
        {key: 2, value: "No"}
    ]
    return (
        <FieldArray name={name}>
            {fieldArrayProps => {
                return(
                    values.map((resp, index) => (
                        <div key={index}>
                            <FormikControl required={true} control='input' type="text" label='Major' name={`${name}[${index}].major`} />
                            <FormikControl required={true} control='select' label='Institute' name={`${name}[${index}].institute_id`} options={education_institutes_options} />
                            {resp.institute_id == -1 &&
                                <FormikControl required={true} control='input' type="text" label='Mention Institute Name' name={`${name}[${index}].institute_other`} />
                            }
                            <FormikControl required={true} control='input' type="text" label='Start Year' name={`${name}[${index}].start_year`} />
                            <FormikControl required={true} control='radio' label='Still Continuing' name={`${name}[${index}].is_current`} options={is_current_options} />
                            {resp.is_current == 2 &&
                                <FormikControl required={true} control='input' type="text" label='End Year' name={`${name}[${index}].end_year`} />
                            }
                            <div className={customStyle.buttons}>
                                <ButtonGroup spacing={2}>
                                    <Button colorScheme='blackAlpha' size="sm" onClick={() => (fieldArrayProps.push(sample))}><AddIcon /></Button>
                                    {index > 1 &&
                                        <Button colorScheme='red' size="sm" onClick={() => (fieldArrayProps.remove(index))}><MinusIcon /></Button>
                                    }
                                </ButtonGroup>
                            </div>
                        </div>
                    ))
                )
            }}
        </FieldArray>
    )
}
