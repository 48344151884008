import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import { Button, ButtonGroup, Center, Flex, Heading, IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import Space from '../../components/utility/Space';
import { APPLY_FOR, STATUS } from '../../components/constants/Constants';

export default function ApplyDetails() {
    const token = localStorage.getItem('my_token');
    const {apply_id} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [applies, setApplies] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchApplyDetails();
    }, [apply_id]);

    const fetchApplyDetails = async() => {
        setIsLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/my-apply-details/${apply_id}`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                console.log(apiResponse.results);
                //setApplies(apiResponse.results?.applies);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    const DisplayData = () => {
        // return (
        //     <Table
        //         variant="striped"
        //         color={'black'}
        //         display={{
        //             base: 'block',
        //             md: 'table',
        //         }}
        //         sx={{
        //             '@media print': {
        //             display: 'table',
        //             },
        //         }}
        //     >
        //         <Thead
        //             display={{
        //             base: 'none',
        //             md: 'table-header-group',
        //             }}
        //             sx={{
        //             '@media print': {
        //                 display: 'table-header-group',
        //             },
        //             }}
        //         >
        //             <Tr>
        //                 <Th textAlign={'center'}>
        //                     Apply Id
        //                 </Th>
        //                 <Th textAlign={'center'}>
        //                     Sequence
        //                 </Th>
        //                 <Th textAlign={'center'}>
        //                     Apply For
        //                 </Th>
        //                 <Th textAlign={'center'}>
        //                     Apply Status
        //                 </Th>
        //                 <Th textAlign={'center'}>
        //                     Apply Date
        //                 </Th>
        //                 <Th textAlign={'center'}>
        //                     Action
        //                 </Th>
        //             </Tr>
        //         </Thead>
        //         <Tbody
        //             display={{
        //             base: 'block',
        //             lg: 'table-row-group',
        //             }}
        //             sx={{
        //             '@media print': {
        //                 display: 'table-row-group',
        //             },
        //             }}
        //         >
        //             {applies.map((apply, idx) => {
        //                 return (
        //                     <Tr
        //                         key={idx}
        //                         display={{
        //                             base: 'grid',
        //                             md: 'table-row',
        //                         }}
        //                         sx={{
        //                             '@media print': {
        //                             display: 'table-row',
        //                             },
        //                             gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
        //                             gridGap: '10px',
        //                         }}
        //                     >
        //                         <Td
        //                             fontSize="md"
        //                             fontWeight="hairline"
        //                             textAlign={'center'}
        //                         >
        //                             {apply.id}
        //                         </Td>

        //                         <Td
        //                             fontSize="md"
        //                             fontWeight="hairline"
        //                             textAlign={'center'}
        //                         >
        //                             {apply.apply_seq}
        //                         </Td>

        //                         <Td
        //                             fontSize="md"
        //                             fontWeight="hairline"
        //                             textAlign={'center'}
        //                         >
        //                             {APPLY_FOR[apply.apply_for]}
        //                         </Td>

        //                         <Td
        //                             fontSize="md"
        //                             fontWeight="hairline"
        //                             textAlign={'center'}
        //                         >
        //                             {STATUS[apply.apply_status]}
        //                         </Td>

        //                         <Td
        //                             fontSize="md"
        //                             fontWeight="hairline"
        //                             textAlign={'center'}
        //                         >
        //                             {apply.apply_date}
        //                         </Td>

        //                         <Td textAlign={'center'}>
        //                             <ButtonGroup variant="solid" size="sm" spacing={3}>
        //                                 <IconButton
        //                                     colorScheme="green"
        //                                     aria-label="edit"
        //                                     icon={<AiFillEye />}
        //                                     onClick={() => navigate(`/institute/${apply.id}/edit`)}
        //                                 />
        //                             </ButtonGroup>
        //                         </Td>
        //                     </Tr>
        //                 );
        //             })}
        //         </Tbody>
        //     </Table>
        // )
    }

    return (
        <>
            <Center>
                <Heading as='h2' size='xl'>
                    Apply Details Log
                </Heading>
            </Center>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
                className="admin_table"
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
        </>
    )
}
