import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function AddOffice(){
    return <AddOfficeForm />;
}

const AddOfficeForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const [errors, setErrors] = useState({
        office: []
    });

    const fetchData = () => {
        let init = {
            office: '',
            shortname: '',
            type: ''
        };

        return init;
    }

    const validationSchema = Yup.object({
        office: Yup.string().required('Required'),
        shortname: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/office`, values, config)
            .then((response) => {
                ToastMessage('success','Successfully added');
                navigate('/offices');
            })
            .catch((error) => {
                const {data, status} = error?.response;
                const { errors } = data;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }

                if(status == 400) {
                    message = 'Data already exists';
                }

                setErrors({
                    ...errors,
                    faulty: errors?.office
                });

                ToastMessage('error',message);
            });
    };

    const typeOptions = () => {
        let rtn = [
            {key: '', value: "Select an option"},
            {key: 1, value: "Department"},
            {key: 2, value: "Institute"},
            {key: 3, value: "Others"},
        ];

        return rtn;
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD OFFICE</Text>
            </Flex>

            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='text' label='Office' name='office' placeholder="Enter Office" />
                                <FormikControl required={true} control='input' type='text' label='Shortname' name='shortname' placeholder="Enter Shortname" />
                                <FormikControl required={true} control='select' label='Office For (Type)' name='type' options={typeOptions()} />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}
