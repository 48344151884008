import React, { useEffect, useState } from 'react';
import {
  Flex,
  ButtonGroup,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Box,
  Button,
  Input,
  Text,
  Center,
} from '@chakra-ui/react';
import { BsFillTrashFill } from 'react-icons/bs';

import axios from 'axios';
import { ceil } from 'lodash';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import ToastMessage from '../../components/utility/ToastMessage';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../../components/common/CommonModal';

export const Registrations = () => {
    return <RegistrationTableDemo />;
};

function RegistrationTableDemo() {
    const header = ['Email', 'Phone No', 'Created At', 'Action'];
    const [users, setUsers] = useState([]);
    const limit = 50;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);
    const [filterEmail, setFilterEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const token = localStorage.getItem('my_token');
    let config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const getUsers = async () => {
        let params = {
            email: filterEmail,
            limit,
            page: currentPage
        };

        try {
            setIsLoading(true);
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/get-registered-users`, {
                    params: params,
                    headers: config
                })
            ).data;
            const results = apiResponse.results;
            if (results[0] && results[0].length > 0) {
                setUsers(results[0]);
                if (results[1]) {
                    setTotalPages(ceil(results[1] / limit));
                }
            }else{
                setUsers([]);
            }
        } catch (err) {
            ToastMessage('error','Something went wrong');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getUsers();
    }, [currentPage]);

    const handleSearch = () => {
        setCurrentPage(1);
        getUsers();
    }

    const handleClear = () => {
        setFilterEmail('');
    }

    const onClickUserDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                    {header.map((x) => (
                        <Th key={x} textAlign={'center'}>
                            {x}
                        </Th>
                    ))}
                    </Tr>
                </Thead>
                {users?.length == 0 
                    ? 
                        <Center><Text align={'center'} color={'red'}>No Data Found</Text></Center>
                    : 
                    <Tbody
                        display={{
                        base: 'block',
                        lg: 'table-row-group',
                        }}
                        sx={{
                        '@media print': {
                            display: 'table-row-group',
                        },
                        }}
                    >
                        {users.map((user, idx) => {
                            return (
                                <Tr
                                    key={idx}
                                    display={{
                                        base: 'grid',
                                        md: 'table-row',
                                    }}
                                    sx={{
                                        '@media print': {
                                        display: 'table-row',
                                        },
                                        gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                        gridGap: '10px',
                                    }}
                                >
                                    <Td
                                        fontSize="md"
                                        fontWeight="hairline"
                                        textAlign={'center'}
                                    >
                                        {user.email}
                                    </Td>

                                    <Td
                                        fontSize="md"
                                        fontWeight="hairline"
                                        textAlign={'center'}
                                    >
                                        {user.mobile}
                                    </Td>

                                    <Td
                                        fontSize="md"
                                        fontWeight="hairline"
                                        textAlign={'center'}
                                    >
                                        {user.created_at}
                                    </Td>

                                    <Td textAlign={'center'}>
                                        <ButtonGroup variant="solid" size="sm" spacing={3}>
                                            <IconButton
                                                colorScheme="red"
                                                variant="outline"
                                                aria-label="delete"
                                                icon={<BsFillTrashFill />}
                                                onClick={() => onClickUserDelete(user?.id)}
                                            />
                                        </ButtonGroup>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                }
            </Table>
        )
    }

    const deleteData = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/delete-user/only-registered/${id}`,{
                headers: config
            })
            .then((response) => {
                getUsers();
                ToastMessage("success","Deleted Successfully");
            })
            .catch((error) => {
                ToastMessage('error', 'Something went wrong');
            });
    }

    return (
        <>
            <Flex justifyContent={'center'} mb={3}>
                <Text fontSize="xl">Registration Done Users</Text>
            </Flex>

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={deleteData}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Email' size='md' value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} />
                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
            {users.length > 0 &&
                <EvergreenPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                />
            }
        </>
    );
}
