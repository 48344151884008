import React, { useEffect, useState } from 'react';
import StudentStyle from '../../assets/css/modular/evening-course-student-id.module.css';
import Logo from '../../assets/images/assets/Logo.jpg';
import Banner from '../../assets/images/assets/banner.jpg';
import SampleImage from '../../assets/images/assets/sample_image.png';
import RegistrarSign from '../../assets/images/assets/registrar_sign.jpg';
import Barcode from 'react-barcode';
import { getNameFontSize } from '../common/CommonFunctions';
import axios from 'axios';
import ToastMessage from '../utility/ToastMessage';

export default function EveningCourseStudentIdCardWithValue({hall, thumb, fullname, registration_no, blood_group, session, admission_year, batch, validity, course_title, course_id ,showHeader}) {
    const [finalThumb, setFinalThumb] = useState('');
    const [signature, setSignature] = useState('');

    useEffect(() => {
        setFinalThumb(thumb);
        getSignature(course_id);
    }, [thumb, course_id]);

    useEffect(() => {

    }, [showHeader]);

    const getSignature = async (param_course_id) => {
        const config = {
            responseType: "blob",
            params: {
                course_id: param_course_id
            }
        };

        try {
            var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-signature-by-course`, config));
            if (response.status == 200) {
                const data = response.data;
                const blob = new Blob([data], { type: data.type });
                const url = URL.createObjectURL(blob);
                setSignature(url);
            }
        } catch ({response}) {
            if(response?.status == 404) {
                ToastMessage('warning', 'Signature not found');
            }else{
                ToastMessage('error','Something went wrong');
            }
        }
    }

    return (
        <div className={StudentStyle.idCardBody}>
            <div className={StudentStyle.leftPart}>
                <p>{course_title}</p>
            </div>
            <div className={StudentStyle.rightPart}>
                <div className={StudentStyle.bannerPart}>
                    {showHeader &&
                        <>
                            <img src={Logo} className={StudentStyle.logo} />
                            <img src={Banner} className={StudentStyle.banner} />
                        </>
                    }
                </div>

                <div className={StudentStyle.studentImg}>
                    <img src={ finalThumb ? finalThumb : SampleImage } className={StudentStyle.image} />
                </div>
                <div className={StudentStyle.naming}>
                    <p className={StudentStyle.fullname} style={{fontSize: getNameFontSize(fullname)}}>{fullname}</p>
                </div>

                <div className={StudentStyle.info}>
                    <div className={StudentStyle.left}>
                        <div className={StudentStyle.items}>
                            <p>Student ID Number: </p>
                            <b className={StudentStyle.value}>{registration_no}</b>
                        </div>
                        <div className={StudentStyle.items}>
                            <p>Blood Group: </p>
                            <b className={StudentStyle.value}>{blood_group}</b>
                        </div>
                    </div>
                    <div className={StudentStyle.right}>
                        <div className={StudentStyle.rightInfoPart}>
                            <div className={StudentStyle.items}>
                                <p>Session: </p>
                                <b className={StudentStyle.value}>
                                    {session} {admission_year}
                                </b>
                            </div>
                            <div className={StudentStyle.items}>
                                <p>Validity: </p>
                                <b className={StudentStyle.value}>
                                    {validity}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={StudentStyle.courseName}>
                    <div className={StudentStyle.items}>
                        <p>Hall: </p>
                        <b className={StudentStyle.value}>{hall}</b>
                    </div>
                </div>

                <div className={StudentStyle.barcodeSign} style={{display: 'flex'}}>
                    <div className={StudentStyle.barcode}>
                        <Barcode value={registration_no} displayValue={false} width={40} height={1100}/>
                    </div>
                    <div className={StudentStyle.img_part} style={{marginTop: '10px'}}>
                            <div id="footerDivContent">
                                <div className={StudentStyle.footerContent}>
                                    <img src={signature} className={StudentStyle.signature} />
                                </div>
                                <p className={StudentStyle.registrar}>Director</p>
                            </div>
                        </div>
                </div>
                {/* <div className={StudentStyle.barcode}>
                    <Barcode value={registration_no} displayValue={false} />
                </div> */}
            </div>
        </div>
    )
}
