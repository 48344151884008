import { FormControl, FormHelperText, FormLabel, Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { Field, useField } from "formik";
import React from "react";

export const RadioButtons = (props) => {
    const { label, name, options, ...rest } = props;
    const [field, meta] = useField(props);
    return(
        <FormControl id={name} isInvalid={!!meta.error && !!meta.touched}>
            {label && (
                <FormLabel mb="1" htmlFor={name}>
                    {label}
                </FormLabel>
            )}
            <Field className="radio_parent" name={name}>
                {
                    ({ field }) => {
                        return options.map(option => {
                            return(
                                <React.Fragment key={option.key}>
                                    <div className="custom_radio">
                                        <input
                                            type="radio"
                                            id={option.key}
                                            {...field}
                                            value={option.key}
                                            {...rest}
                                        />
                                        <label htmlFor={option.key}>{option.value}</label>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                }
            </Field>
            {meta.error && meta.touched && (
                <FormHelperText><span className="error">{meta.error}</span></FormHelperText>
            )}
        </FormControl>
    );
}
