import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Flex, IconButton, Input, Select, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
import { AiFillEdit } from 'react-icons/ai';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import Space from '../../components/utility/Space';
import { getDeptVal, getInstVal } from '../../components/common/CommonFunctions';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import { ceil } from 'lodash';
import CommonModal from '../../components/common/CommonModal';

export default function HonsMastersStudents({getDeptInstOptions,getHallOptions,user_type, deleteApplyData}){
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [batches, setBatches] = useState([]);
    const token = localStorage.getItem('my_token');
    const [isLoading, setIsLoading] = useState(false);
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [filterRegistrationNo, setFilterRegistrationNo] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterBatch, setFilterBatch] = useState('');
    const [filterHall, setFilterHall] = useState('');
    const [filterDeptInstitue, setFilterDeptInstitute] = useState('');
    const [filterHonsMasters, setFilterHonsMasters] = useState('');
    const [filterStatus, setFilterStatus] = useState('');

    const navigate = useNavigate();

    const STATUS = [
        'Applied',
        'Payment Completed',
        'Application Confirmed',
        'Printed',
        'Delivered'
    ];

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);
    const [targetDeleteApplyId, setTargetDeleteApplyId] = useState(null);

    useEffect(() => {
        fetchUsers();
    }, [currentPage]);

    useEffect(() => {
        fetchBatches();
    }, []);

    const fetchUsers = async () => {
        setIsLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params:{
                'user_type': user_type,
                'fullname': filterName,
                'registration_no': filterRegistrationNo,
                'batch' : filterBatch,
                'hall' : filterHall,
                'department': getDeptVal(filterDeptInstitue),
                'institute': getInstVal(filterDeptInstitue),
                'hons_masters_option': filterHonsMasters,
                'limit': limit,
                'page': currentPage
            }
        };


        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-hons-masters-students-applies`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setUsers(apiResponse.results.users);
                setTotal(apiResponse.results.count);
                if (apiResponse.results.count) {
                    setTotalPages(ceil(apiResponse.results.count / limit));
                }
            }
        } catch (error) {
            ToastMessage('error', 'Something went wrong');
        }

        setIsLoading(false);
    };

    const fetchBatches = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };


        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/batches`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setBatches(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const hons_or_masters_options = () => {
        let rtn_data = [];
        rtn_data.push(
            <option key={0} value={0}>HONS</option>
        );
        rtn_data.push(
            <option key={1} value={1}>MASTERS</option>
        );
        return rtn_data;
    }

    const getBatchOptions = () => {
        let return_data = [];

        if(batches.length > 0){
            for(let i=0;i<batches.length;i++){
                return_data.push(
                    <option key={i} value={batches[i].id}>{batches[i].batch}</option>
                )
            }
        }
        return return_data;
    }

    const handleEdit = (id) => {
        console.log(id);
    }

    const resetUsers = (id) => {
        const newUsers = users.filter(item => item.id !== id);
        setUsers(newUsers);
    }

    const handleDelete = (id, apply_id) => {
        setTargetDeleteId(id);
        setTargetDeleteApplyId(apply_id);
        setShowDeleteModal(true);
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>Registration No</Th>
                        <Th textAlign={'center'}>Fullname</Th>
                        <Th textAlign={'center'}>Batch</Th>
                        <Th textAlign={'center'}>Hall</Th>
                        <Th textAlign={'center'}>Hons/Masters</Th>
                        <Th textAlign={'center'}>Dept/Inst</Th>
                        <Th textAlign={'center'}>STATUS</Th>
                        <Th textAlign={'center'}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {users.length > 0 &&
                        users.map((user, idx) => (
                            <Tr
                                key={idx}
                                display={{
                                    base: 'grid',
                                    md: 'table-row',
                                }}
                                sx={{
                                    '@media print': {
                                    display: 'table-row',
                                    },
                                    gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                    gridGap: '10px',
                                }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    <Link className='link_details' to={'/user_details/'+user.id+'/apply/'+user.apply_id}>{user.registration_no}</Link>
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.fullname}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.batch}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.hall}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.hons_or_masters == '0' ? 'Hons' : 'Masters'}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.dept_or_inst_name}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {STATUS[user.apply_status]}
                                </Td>
                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        {/* <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEdit />}
                                            onClick={() => handleEdit(user.id)}
                                        /> */}
                                        <IconButton
                                            colorScheme="red"
                                            variant="outline"
                                            aria-label="delete"
                                            icon={<BsFillTrashFill />}
                                            onClick={() => handleDelete(user.id, user.apply_id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        )
                        )
                    }
                </Tbody>
            </Table>
        )
    }

    const handleSearch = () => {
        fetchUsers();
    }

    const handleClear = () => {
        setFilterName('');
        setFilterRegistrationNo('');
        setFilterBatch('');
        setFilterHall('');
        setFilterHonsMasters('');
        setFilterDeptInstitute('');
    }

    return (
        <>
            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Registration No' size='md' value={filterRegistrationNo} onChange={(e) => setFilterRegistrationNo(e.target.value)} />
                    <Input placeholder='Name' size='md' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                    <Select placeholder='Select Batch' size='md' value={filterBatch} onChange={(e) => setFilterBatch(e.target.value)}>
                        {getBatchOptions()}
                    </Select>
                    <Select placeholder='Select Hall' size='md' value={filterHall} onChange={(e) => setFilterHall(e.target.value)}>
                        {getHallOptions}
                    </Select>
                    <Select placeholder='Select Hons/Masters' size='md' value={filterHonsMasters} onChange={(e) => setFilterHonsMasters(e.target.value)}>
                        {hons_or_masters_options()}
                    </Select>
                    <Select placeholder='Select Dept/Inst' size='md' value={filterDeptInstitue} onChange={(e) => setFilterDeptInstitute(e.target.value)}>
                        {getDeptInstOptions}
                    </Select>
                </Flex>

                { showDeleteModal &&
                    <CommonModal
                        reset={setShowDeleteModal}
                        setTargetDeleteId={setTargetDeleteId}
                        setTargetDeleteApplyId={setTargetDeleteApplyId}
                        deleteAction={deleteApplyData}
                        targetDeleteId={targetDeleteId}
                        targetDeleteApplyId={targetDeleteApplyId}
                        reFilterUsers={resetUsers}
                    />
                }

                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}
