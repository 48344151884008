import { Box, Button, ButtonGroup, Flex, IconButton, Input, Select, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillPeopleFill, BsFillTrashFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import { STATUS } from '../../components/constants/Constants';
import ToastMessage from '../../components/utility/ToastMessage';
import { getDeptVal, getInstVal } from '../../components/common/CommonFunctions';
import { ceil } from 'lodash';
import CommonModal from '../../components/common/CommonModal';

export default function MphilPhdStudents(){
    return <StudentList />;
}

const StudentList = () =>{
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const token = localStorage.getItem('my_token');
    const [isLoading, setIsLoading] = useState(false);
    const [halls, setHalls] = useState([]);
    const [dept_inst, setDeptInst] = useState([]);
    
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [filterRegistrationNo, setFilterRegistrationNo] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterHall, setFilterHall] = useState('');
    const [filterDeptInstitue, setFilterDeptInstitute] = useState('');
    const [filterMphilPhd, setFilterMphilPhd] = useState('');
    const [filterRegularIrregular, setFilterRegularIrregular] = useState('');

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        fetchStudents();
    }, [currentPage]);

    useEffect(() => {
        fetchHalls();
        fetchDeptInstitutes();
    }, []);

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const deleteData = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/delete-user/${id}`,config)
            .then((response) => {
                fetchStudents();
                ToastMessage("success","Deleted Successfully");
            })
            .catch((error) => {
                ToastMessage('error', 'Something went wrong');
            });
    }

    const onClickDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const fetchHalls = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/hall`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setHalls(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const fetchDeptInstitutes = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-dept-institutes`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setDeptInst(apiResponse.results);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const getHallOptions = (halls) => {
        let return_data = [];

        if(halls.length > 0){
            for(let i=0;i<halls.length;i++){
                return_data.push(
                    <option key={i} value={halls[i].id}>{halls[i].hall}</option>
                )
            }
        }
        return return_data;
    }

    const getDeptInstOptions = (dept_inst) => {
        let return_data = [];

        if(dept_inst.length > 0){
            for(let i=0;i<dept_inst.length;i++){
                return_data.push(
                    <option key={i} value={dept_inst[i].id+'#'+dept_inst[i].type}>{dept_inst[i].name}</option>
                )
            }
        }
        return return_data;
    }

    const fetchStudents = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params:{
                'fullname': filterName,
                'registration_no': filterRegistrationNo,
                'hall' : filterHall,
                'department': getDeptVal(filterDeptInstitue),
                'institute': getInstVal(filterDeptInstitue),
                'mphil_phd_option': filterMphilPhd,
                'regular_irregular_option': filterRegularIrregular,
                'limit': limit,
                'page': currentPage
            }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-mphil-phd-students`, config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setUsers(apiResponse.results.users);
                setTotal(apiResponse.results.count);
                if (apiResponse.results.count) {
                    setTotalPages(ceil(apiResponse.results.count / limit));
                }
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }
    };

    const handleEdit = (id) => {
        //navigate('/edit-admin/'+id);
    }

    const mphil_or_phd_options = () => {
        let rtn_data = [];
        rtn_data.push(
            <option value={0}>MPHIL</option>
        );
        rtn_data.push(
            <option value={1}>PHD</option>
        );
        return rtn_data;
    }

    const regular_or_irregular_options = () => {
        let rtn_data = [];
        rtn_data.push(
            <option value={0}>Regular</option>
        );
        rtn_data.push(
            <option value={1}>Irregular</option>
        );
        return rtn_data;
    }

    const DisplayData = () => {
        return (
            <Table
                    variant="striped"
                    color={'black'}
                    display={{
                        base: 'block',
                        md: 'table',
                    }}
                    sx={{
                        '@media print': {
                        display: 'table',
                        },
                    }}
                >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>Registration No</Th>
                        <Th textAlign={'center'}>Fullname</Th>
                        <Th textAlign={'center'}>Session</Th>
                        <Th textAlign={'center'}>Hall</Th>
                        <Th textAlign={'center'}>Mphil/Phd</Th>
                        <Th textAlign={'center'}>Dept/Inst</Th>
                        <Th textAlign={'center'}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {users.length > 0 &&
                        users.map((user, idx) => (
                            <Tr
                            key={idx}
                            display={{
                                base: 'grid',
                                md: 'table-row',
                            }}
                            sx={{
                                '@media print': {
                                display: 'table-row',
                                },
                                gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                gridGap: '10px',
                            }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    <Link className='link_details' to={'/user_details/'+user.id}>{user.registration_no}</Link>
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.fullname}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.session}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.hall}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.mphill_or_phd == '0' ? 'Mphil' : 'Phd'}
                                    [{user.type == '0' ? 'Regular' : 'Irregular'}]
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.dept_or_inst_name}
                                </Td>
                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        {/* <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEdit />}
                                            onClick={() => handleEdit(user.id)}
                                        /> */}
                                        <IconButton
                                            colorScheme="red"
                                            variant="outline"
                                            aria-label="delete"
                                            icon={<BsFillTrashFill />}
                                            onClick={() => onClickDelete(user.id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        )
                        )
                    }
                </Tbody>
            </Table>
        )
    }

    const handleSearch = () => {
        fetchStudents();
    }

    const handleClear = () => {
        setFilterName('');
        setFilterRegistrationNo('');
        setFilterRegularIrregular('');
        setFilterHall('');
        setFilterMphilPhd('');
        setFilterDeptInstitute('');
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">MPHIL AND PHD STUDENTS</Text>
            </Flex>

            <Space />

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={deleteData}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Registration No' size='md' value={filterRegistrationNo} onChange={(e) => setFilterRegistrationNo(e.target.value)} />
                    <Input placeholder='Name' size='md' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                    <Select placeholder='Select Regular/Irregular' size='md' value={filterRegularIrregular} onChange={(e) => setFilterRegularIrregular(e.target.value)}>
                        {regular_or_irregular_options()}
                    </Select>
                    <Select placeholder='Select Hall' size='md' value={filterHall} onChange={(e) => setFilterHall(e.target.value)}>
                        {getHallOptions(halls)}
                    </Select>
                    <Select placeholder='Select Mphil/Phd' size='md' value={filterMphilPhd} onChange={(e) => setFilterMphilPhd(e.target.value)}>
                        {mphil_or_phd_options()}
                    </Select>
                    <Select placeholder='Select Dept/Inst' size='md' value={filterDeptInstitue} onChange={(e) => setFilterDeptInstitute(e.target.value)}>
                        {getDeptInstOptions(dept_inst)}
                    </Select>
                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
                className="admin_table"
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>

            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}
