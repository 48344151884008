import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Flex, IconButton, Input, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillPeopleFill, BsFillTrashFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import ToastMessage from '../../components/utility/ToastMessage';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import CommonModal from '../../components/common/CommonModal';

export default function Notices(){
    return <NoticeList />;
}

const NoticeList = () =>{
    const [notices, setNotices] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 20;

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const [filterTitle, setFilterTitle] = useState('');
    const [filterDescription, setFilterDescription] = useState('');
    const [filterNoticeFrom, setFilterNoticeFrom] = useState('');
    const [filterNoticeUntil, setFilterNoticeUntil] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const token = localStorage.getItem('my_token');

    const navigate = useNavigate();

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    useEffect(() => {
        fetchNotices();
    }, []);

    const fetchNotices = async () => {
        const params = {
            limit,
            page: currentPage,
            title: filterTitle,
            description: filterDescription,
            notice_from: filterNoticeFrom,
            notice_until: filterNoticeUntil
        };

        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/notices`, {
                    params: params,
                    headers: config
                })
            ).data;

            if (apiResponse.error === false && apiResponse.results) {
                setNotices(apiResponse.results.notices);
                setTotal(apiResponse.results.count);
            }
        } catch (error) {
            const { data, status } = error.response;
            if(status === 401) {
                ToastMessage('error', data.message);
            }else{
                ToastMessage('error', 'Something went wrong');
            }
        }

        setIsLoading(false);
    };

    const handleEdit = (id) => {
        navigate('/edit-notice/'+id);
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/notice/${id}`,{
                headers: config
            })
            .then((response) => {
                fetchNotices();
                alert("Deleted Successfully");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleSearch = () => {
        setCurrentPage(1);
        fetchNotices();
    }

    const handleClear = () => {
        setFilterTitle('');
        setFilterDescription('');
    }

    const onClickNoticeDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>Title</Th>
                        <Th textAlign={'center'}>Description</Th>
                        <Th textAlign={'center'}>From</Th>
                        <Th textAlign={'center'}>Until</Th>
                        <Th textAlign={'center'}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {notices.length > 0 &&
                        notices.map((notice, idx) => (
                            <Tr
                            key={idx}
                            display={{
                                base: 'grid',
                                md: 'table-row',
                            }}
                            sx={{
                                '@media print': {
                                display: 'table-row',
                                },
                                gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                gridGap: '10px',
                            }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {notice.title}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {notice.description}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {notice.notice_from}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {notice.notice_until}
                                </Td>
                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEdit />}
                                            onClick={() => handleEdit(notice.id)}
                                        />
                                        <IconButton
                                            colorScheme="red"
                                            variant="outline"
                                            aria-label="delete"
                                            icon={<BsFillTrashFill />}
                                            onClick={() => onClickNoticeDelete(notice.id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        )
                        )
                    }
                </Tbody>
            </Table>
        )
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">NOTICES</Text>
            </Flex>

            <Flex justifyContent={'center'} w={{ base: '100%', md: '100%', sm: 'full' }} className="admin_add_button">
                <Box>
                    <Button
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        variant={'solid'}
                        size="sm"
                        onClick={() => navigate('/add-notice')}
                    >
                        Add New
                    </Button>
                </Box>
            </Flex>

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={handleDelete}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Title' size='md' value={filterTitle} onChange={(e) => setFilterTitle(e.target.value)} />
                    <Input placeholder='Description' size='md' value={filterDescription} onChange={(e) => setFilterDescription(e.target.value)} />
                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
                className="admin_table"
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
        </>
    )
}
