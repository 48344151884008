import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
} from '@chakra-ui/react'
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";

export default function ImageResizeModal({thumb, setFinalThumb, setDisplayModal, setIsDisabled}) {
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const [crop, setCrop] = useState({
        aspect: 1/1
    });

    const [image, setImage] = useState(null);

    useEffect(() => {
        onOpen();
    }, [thumb]);

    function getCroppedImage(){
        const targetedImage = image.target;
        const canvas = document.createElement('canvas');
        if(!crop.width){
            alert('Please crop the image');
            return;
        }
        const scaleX = targetedImage.naturalWidth / targetedImage.width;
        const scaleY = targetedImage.naturalHeight / targetedImage.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            targetedImage,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const base64Image = canvas.toDataURL('image/jpeg');
        setFinalThumb(base64Image);
        setDisplayModal(false);
        setIsDisabled(false);
        onClose();
    }

    const handleClose = () => {
        setDisplayModal(false);
        onClose();
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Resize your image</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <ReactCrop crop={crop} onChange={c => setCrop(c)} aspect={1/1}>
                            <img onLoad={setImage} alt='thumb' src={thumb} />
                        </ReactCrop>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={handleClose}>
                            Close
                        </Button>
                        <Button colorScheme='green' onClick={getCroppedImage}>Apply</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
