// @ts-nocheck
import { Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @ts-ignore
import Employees from './Employees';
import HonsMastersStudents from './HonsMastersStudents';
import MphilPhdStudents from './MphilPhdStudents';
import Space from '../../components/utility/Space';
import ToastMessage from '../../components/utility/ToastMessage';
import EveningCourseStudents from './EveningCourseStudents';

export default function Application(){
    return <ApplyList />;
}

const ApplyList = () =>{
    const [halls, setHalls] = useState([]);
    const [dept_inst, setDeptInst] = useState([]);
    const token = localStorage.getItem('my_token');
    const navigate = useNavigate();

    let config = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
    };

    const user_type_param = useParams().user_type;
    let user_type = '';
    let title = '';
    if(user_type_param == 'teachers'){
        user_type = '3';
        title = 'TEACHERS';
    }
    else if(user_type_param == 'hons-masters-students'){
        user_type = '4';
        title = 'HONS MASTERS STUDENTS';
    }
    else if(user_type_param == 'mphil-phd-students'){
        user_type = '5';
        title = 'MPHIL PHD STUDENTS';
    }
    else if(user_type_param == 'officers'){
        user_type = '6';
        title = 'OFFICERS';
    }
    else if(user_type_param == 'staffs'){
        user_type = '7';
        title = 'STAFFS';
    }
    else if(user_type_param == 'evening-course-students'){
        user_type = '8';
        title = 'EVENING COURSE STUDENTS';
    }
    else{
        alert('Invalid Url');
        navigate(-1);
    }

    // let config = {
    //     headers: {
    //         Accept: 'application/json',
    //         Authorization: `Bearer ${token}`
    //     }
    // };

    useEffect(() => {
        fetchHalls();
        fetchDeptInstitutes();
    }, []);

    const fetchHalls = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/hall`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setHalls(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const fetchDeptInstitutes = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-dept-institutes`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setDeptInst(apiResponse.results);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const deleteApplyData = (id,apply_id) => {
        let config = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        };

        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/delete-user-apply/${id}/${apply_id}`,{
                headers: config
            })
            .then((response) => {
                ToastMessage("success","Deleted Successfully");
            })
            .catch((error) => {
                ToastMessage('error', 'Something went wrong');
            });
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">APPLICATIONS ({title})</Text>
            </Flex>
            <Space />
            {user_type != '' && 
                <Decider halls={halls} dept_inst={dept_inst} user_type={user_type} deleteApplyData={deleteApplyData}/>
            }
        </>
    )
}

const Decider = ({halls, dept_inst, user_type, deleteApplyData}) => {
    const getHallOptions = (halls) => {
        let return_data = [];

        if(halls.length > 0){
            for(let i=0;i<halls.length;i++){
                return_data.push(
                    <option key={i} value={halls[i].id}>{halls[i].hall}</option>
                )
            }
        }
        return return_data;
    }

    const getDeptInstOptions = (dept_inst) => {
        let return_data = [];

        if(dept_inst.length > 0){
            for(let i=0;i<dept_inst.length;i++){
                return_data.push(
                    <option key={i} value={dept_inst[i].id+'#'+dept_inst[i].type}>{dept_inst[i].name}</option>
                )
            }
        }
        return return_data;
    }

    return(
        <>
            {halls.length > 0 && dept_inst.length > 0 &&
                user_type == '4'
                ? <HonsMastersStudents getDeptInstOptions={getDeptInstOptions(dept_inst)} getHallOptions={getHallOptions(halls)} user_type={user_type} deleteApplyData={deleteApplyData} />
                : user_type == '5'
                    ? <MphilPhdStudents getDeptInstOptions={getDeptInstOptions(dept_inst)} getHallOptions={getHallOptions(halls)} user_type={user_type} deleteApplyData={deleteApplyData} />
                    : user_type == '8'
                        ? <EveningCourseStudents getHallOptions={getHallOptions(halls)} user_type={user_type} deleteApplyData={deleteApplyData} />
                        : <Employees user_type={user_type} deleteApplyData={deleteApplyData} />
            }
        </>
    )
}
