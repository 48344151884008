import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function AddBatch(){
    return <AddBatchForm />;
}

const AddBatchForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const [errors, setErrors] = useState({
        batch: [],
        session: [],
        validity: []
    });

    const fetchData = () => {
        let init = {
            batch: '',
            session: '',
            validity: '',
        };

        return init;
    }

    const validataionSchema = Yup.object({
        batch: Yup.number()
                .min(10, 'Batch should be greater than or equal to 10')
                .max(100, 'Batch should be less than 100')
                .required('Required'),
        session: Yup.string().matches(/^\d{4}-\d{2}$/, 'Invalid pattern').required("Required"),
        validity: Yup.date().required("Required")
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/batch`, values, config)
            .then((response) => {
                ToastMessage('success','Successfully added');
                navigate('/batches');
            })
            .catch((error) => {
                const {data, status} = error?.response;
                const { errors } = data;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }

                if(status == 400) {
                    message = 'Data already exists';
                }

                setErrors({
                    ...errors,
                    batch: errors?.batch,
                    session: errors?.session,
                    validity: errors?.validity
                });

                ToastMessage('error',message);
            });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD BATCH</Text>
            </Flex>

            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validataionSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='number' label='Batch' name='batch' placeholder="Enter batch" />
                                <FormikControl required={true} control='input' type='text' label='Session' name='session' placeholder="i.e.2013-14" />
                                <FormikControl control='date' type='date' label='Validity' name='validity' placeholder="Enter date"/>

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}
