import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';

const token = localStorage.getItem('my_token');

export default function EditOffice(){
    const [isLoading, setIsLoading] = useState(false);
    const [ officeInfo, setOfficeInfo ] = useState({});
    const { id } = useParams();

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    useEffect(() => {
        fetchOffice(id);
    }, [id]);

    const fetchOffice = async (id) => {
        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/office/${id}`, {
                    headers: config
                })
            ).data;

            const results = apiResponse.results;

            if (results?.office) {
                setOfficeInfo(results);
            }else{
                setOfficeInfo({});
            }
        } catch (err) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    const DisplayData = () => {
        return (
            <>
                { isLoading
                    ? <CustomSpinner />
                    : officeInfo?.id
                        ? <EditOfficeForm officeInfo={officeInfo} id={id} />
                        : <p>Nothing Found with this</p>
                }
            </>
        )
    }

    return <DisplayData />;
}

const EditOfficeForm = ({officeInfo, id}) =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const [errors, setErrors] = useState({
        office: []
    });

    const fetchData = () => {
        let init = {
            office: officeInfo?.office,
            shortname: officeInfo?.shortname,
            type: officeInfo?.type,
        };

        return init;
    }

    const validationSchema = Yup.object({
        office: Yup.string().required('Required'),
        shortname: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        axios
            .patch(`${process.env.REACT_APP_API_URL}/api/office/${id}`, values, config)
            .then((response) => {
                ToastMessage('success','Successfully updated');
                navigate('/offices');
            })
            .catch((error) => {
                const {data, status} = error?.response;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }

                const { errors } = data;

                setErrors({
                    ...errors,
                    office: errors?.office
                });

                ToastMessage('error',message);
            });
    };

    const typeOptions = () => {
        let rtn = [
            {key: '', value: "Select an option"},
            {key: 1, value: "Department"},
            {key: 2, value: "Institute"},
            {key: 3, value: "Others"},
        ];

        return rtn;
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">EDIT OFFICE</Text>
            </Flex>

            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='text' label='Office' name='office' placeholder="Enter Office" />
                                <FormikControl required={true} control='input' type='text' label='Shortname' name='shortname' placeholder="Enter Shortname" />
                                <FormikControl required={true} control='select' label='Office For (Type)' name='type' options={typeOptions()} />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}
