import { useState } from "react";

const { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Stack, Input } = require("@chakra-ui/react")

export default function UpdateValidityModal({reset, action, validity}) {
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const [paramValidity, setParamValidity] = useState(validity);

    const handleClose = () => {
        reset(false);
        onClose();
    }

    const handleAction = () => {
        action(paramValidity);
        handleClose();
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Update Validity
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <Stack spacing={3}>
                            <Input type="date" placeholder='medium size' size='md' value={paramValidity} onChange={(e) => setParamValidity(e.target.value)} />
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='gray' mr={3} onClick={handleClose}>
                            Close
                        </Button>
                        <Button colorScheme='red' onClick={handleAction}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
