import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import MonthTable from './summary/MonthTable';

export default function AdminDashboard(){
    return(
        <Dashboard />
    )
}

const Dashboard = () => {{
    return(
        <div>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">SUMMARY</Text>
            </Flex>

            <Text fontSize="lg" mb={2}>NEW APPLICATIONS</Text>
            <MonthTable />

            {/* <Space />
            <Text fontSize="lg">REISSUE</Text>
            <MonthTable /> */}
        </div>
    )
}}
