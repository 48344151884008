import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function AddHall() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const initialValues = {
        hall: '',
        shortname: ''
    };

    const validationSchema = Yup.object({
        hall: Yup.string().max(256, 'Please enter within 256 characters').required('Hall is required'),
        shortname: Yup.string().max(256, 'Please enter within 256 characters').required('Shortname is required'),
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        setIsLoading(true);

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/hall`, values, config)
            .then((response) => {
                setIsLoading(false);
                ToastMessage('success', 'Successfully added');
                navigate('/halls');
            })
            .catch((error) => {
                setIsLoading(false);
                ToastMessage('error','Something went wrong');
            });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD HALL</Text>
            </Flex>
            <div className={styles.apply_form}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='text' placeholder='Enter hall' label='Hall' name='hall' />
                                <FormikControl required={true} control='input' type='text' placeholder='Enter shortname' label='Shortname' name='shortname' />

                                <div>
                                    <Button disabled={isLoading} colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>

        </>
    )
}
