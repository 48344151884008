// @ts-nocheck
import React,{useEffect, useState} from 'react';
import { ChakraProvider, extendTheme, Heading, Button } from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import {Formik, Form} from 'formik';
// @ts-ignore
import styles from "../../assets/css/modular/apply-form.module.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import ToastMessage from '../../components/utility/ToastMessage';
import axios from 'axios';
import { getEveningCourseSessionOptions, getEveningCourseValidity } from '../../components/common/CommonFunctions';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
});

export default function RegisterEveningCourseStudentData(){
    const [halls, setHalls] = useState([]);
    const [eveningCourses, setEveningCourses] = useState([]);

    const navigate = useNavigate();
    let application_data = localStorage.getItem('application_data');
    let is_checked = true;
    let is_first_step = true;
    let is_second_step = true;

    if(!application_data){
        is_checked = false;
    }else{
        application_data = JSON.parse(application_data);
        if(!application_data.declaration || application_data.declaration !== true){
            is_checked = false;
        }
        if(!application_data.declaration || application_data.first_step !== true){
            is_first_step = false;
        }
        if(!application_data.declaration || application_data.second_step !== true){
            is_second_step = false;
        }
    }

    useEffect(() => {
        fetchOptionData();
        fetchEveningCourses();
        if(is_checked == false){
            ToastMessage("warning","Please check the declaration first");
            navigate('/apply-for-id');
        } else if(is_first_step == false){
            ToastMessage("warning","Please complete first step first");
            navigate('/apply-first-step');
        } else if(is_second_step == false){
            ToastMessage("warning","Please complete previous step first");
            navigate('/apply-second-step');
        }
    }, [is_checked, is_first_step, is_second_step]);

    const fetchData = () => {
        let initialValues = {
            hall_id: '',
            registration_no: '',
            evening_course_id: '',
            session: '',
            batch: '',
            admission_year: ''
        };

        initialValues.hall_id = application_data?.hall_id;
        initialValues.registration_no = application_data?.user_id;
        initialValues.evening_course_id = application_data?.evening_course_id;
        initialValues.session = application_data?.session;
        initialValues.batch = application_data?.batch;
        initialValues.admission_year = application_data?.admission_year;

        return initialValues;
    }

    const fetchEveningCourses = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/evening_course`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setEveningCourses(apiResponse.results.evening_courses);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }
    }

    const fetchOptionData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-options`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setHalls(apiResponse.results.halls);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }
    }

    const validataionSchema = Yup.object({
        hall_id: Yup.string().required("Required"),
        registration_no: Yup.string().required("Required"),
        session: Yup.string().required("Required"),
        admission_year: Yup.string().matches(/^\d{4}$/, 'Invalid year').required("Required"),
        evening_course_id: Yup.string().required("Required"),
        batch: Yup.string().required("Required").max(2)
    });

    const getSelectedCourseValidation = (evening_course_id) => {
        const eveningCourse = eveningCourses.filter((item) => {
            return (item.id == evening_course_id);
        });

        return eveningCourse[0]?.course_duration;
    }

    const onSubmit = values => {
        application_data.hall_id = values.hall_id;
        application_data.registration_no = values.registration_no;
        application_data.session = values.session;
        application_data.batch = values.batch;
        application_data.admission_year = values.admission_year;
        application_data.evening_course_id = values.evening_course_id;
        application_data.validity = getEveningCourseValidity(values.admission_year, getSelectedCourseValidation(values.evening_course_id));
        application_data.third_step = true;
        localStorage.setItem('application_data',JSON.stringify(application_data));
        localStorage.setItem('eveningCourses',JSON.stringify(eveningCourses));

        navigate('/image-upload');
    };

    const getHallOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].hall
                });
            }
        }
        return return_data;
    }

    const getCourseOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].course_title
                });
            }
        }
        return return_data;
    }


    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            Apply for Id card : 3rd Step (Academic Information)
                        </Heading>
                        <br />

                        <div className={styles.apply_form}>
                            <Formik
                                initialValues={fetchData()}
                                validationSchema={validataionSchema}
                                onSubmit={onSubmit}
                            >
                                {
                                    formik => (
                                        <Form>
                                            <FormikControl required={true} control='select' label='Hall' name='hall_id' options={getHallOptions(halls)} />
                                            <FormikControl required={true} control='input' type='text' label='ID Number' name='registration_no' disabled />
                                            <FormikControl required={true} control='select' label='Course' name='evening_course_id' options={getCourseOptions(eveningCourses)} />
                                            <FormikControl required={true} control='select' label='Admission Session' name='session' options={getEveningCourseSessionOptions()} />
                                            <FormikControl required={true} control='input' type='text' label='Admission Year' placeholder='2023' name='admission_year' />
                                            <FormikControl required={true} control='input' type='text' label='Batch' placeholder="Enter Batch" name='batch' />
                                            <div className={styles.neighbor_buttons}>
                                                <Link to='/apply-second-step'><Button colorScheme="blue" type="button">Prev</Button></Link>
                                                <Button colorScheme="green" type="submit">Next</Button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
