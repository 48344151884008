import React, { useEffect, useState } from 'react';
import SidebarWithHeader from '../../components/sidebar/sidebar';
import './departments.css';
import {
  Flex,
  ButtonGroup,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Box,
  Button,
  Input,
  Text,
  Select,
} from '@chakra-ui/react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { AddIcon, CheckIcon, ViewIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { ceil } from 'lodash';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import Space from '../../components/utility/Space';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../../components/common/CommonModal';

export default function Departments() {
    return <DepartmentTableDemo />;
};

function DepartmentTableDemo() {
    const header = ['Department', 'Shortname', 'Faculty', 'Action'];
    const token = localStorage.getItem('my_token');
    const [departments, setDepartments] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const [filterDepartment, setFilterDepartment] = useState('');
    const [filterShortname, setFilterShortname] = useState('');
    const [filterFaculty, setFilterFaculty] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const getDepartments = async () => {
        const params = {
            limit,
            page: currentPage,
            department: filterDepartment,
            shortname: filterShortname,
            faculty_id: filterFaculty
        };

        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/department`, {
                params: params
                })
            ).data;

            const results = apiResponse.results;

            if (results[0] && results[0].length > 0) {
                setDepartments(results[0]);
                if (results[1].count) {
                setTotalPages(ceil(results[1].count / limit));
                }
            }else{
                setDepartments([]);
            }

        } catch (err) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    };

    const onClickDepartmentDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    useEffect(() => {
        getDepartments();
    }, [currentPage]);

    useEffect(() => {
        getFaculties();
    }, []);

    const getFaculties = async () => {
        try {
        var apiResponse = (
            await axios.get(`${process.env.REACT_APP_API_URL}/api/faculty`)
        ).data;
        const results = apiResponse.results;
        if (results[0] && results[0].length > 0) {
            setFaculties(results[0]);
        }
        } catch (err) {
            console.log({ err });
        }
    };

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/department/${id}`,{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                getDepartments();
                alert("Deleted Successfully");
            })
            .catch((error) => {
                const {status} = error?.response;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }
                ToastMessage('error',message);
            });
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                    {header.map((x) => (
                        <Th key={x} textAlign={'center'}>
                        {x}
                        </Th>
                    ))}
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {departments.length > 0 &&
                        departments.map((department, idx) => (
                            <Tr
                            key={idx}
                            display={{
                                base: 'grid',
                                md: 'table-row',
                            }}
                            sx={{
                                '@media print': {
                                display: 'table-row',
                                },
                                gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                gridGap: '10px',
                            }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {department.department}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {department.shortname}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {department.faculty}
                                </Td>
                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEdit />}
                                            onClick={() => navigate(`/department/${department.id}/edit`)}
                                        />
                                        <IconButton
                                            colorScheme="red"
                                            variant="outline"
                                            aria-label="delete"
                                            icon={<BsFillTrashFill />}
                                            onClick={() => onClickDepartmentDelete(department.id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        )
                        )
                    }
                </Tbody>
            </Table>
        )
    };

    const handleSearch = () => {
        setCurrentPage(1);
        getDepartments();
    }

    const handleClear = () => {
        setFilterDepartment('');
        setFilterShortname('');
        setFilterFaculty('');
    }

    const getFacultyOptions = (faculty_data) => {
        let return_data = [];

        if(faculty_data.length > 0){
            for(let i=0;i<faculty_data.length;i++){
                return_data.push(
                    <option key={i} value={faculty_data[i].id}>{faculty_data[i].faculty}</option>
                )
            }
        }
        return return_data;
    }

  return (
    <>
        <Flex justifyContent={'center'} className="admin_title">
            <Text fontSize="xl">DEPARTMENTS</Text>
        </Flex>

        <Flex justifyContent={'center'} w={{ base: '100%', md: '100%', sm: 'full' }} className="admin_add_button">
            <Box>
                <Button
                    colorScheme="teal"
                    leftIcon={<AddIcon />}
                    variant={'solid'}
                    size="sm"
                    onClick={() => navigate('/department/add')}
                >
                    Add New
                </Button>
            </Box>
        </Flex>

        { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={handleDelete}
                    targetDeleteId={targetDeleteId}
                />
            }

        {/* Filter Box */}
        <Flex
            w={{ base: '95%', md: '95%', sm: 'full' }}
            m={'auto'}
            p={3}
            alignItems="center"
            justifyContent="center"
            border={'1px'}
            borderColor="gray.300"
            backgroundColor="#ffffff"
            borderRadius={'lg'}
            className="admin_table"
            direction="column"
        >

            <Flex className='search_items' gap="4">
                <Input placeholder='Department' size='md' value={filterDepartment} onChange={(e) => setFilterDepartment(e.target.value)} />
                <Input placeholder='Shortname' size='md' value={filterShortname} onChange={(e) => setFilterShortname(e.target.value)} />
                <Select placeholder='Select Faculty' size='md' value={filterFaculty} onChange={(e) => setFilterFaculty(e.target.value)}>
                    {getFacultyOptions(faculties)}
                </Select>
            </Flex>
            <Flex mt={'10px'} direction='row' gap='4'>
                <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                    Search
                </Button>
                <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                    Clear
                </Button>
            </Flex>
        </Flex>

        <Space />

        <Flex
            w={{ base: '95%', md: '95%', sm: 'full' }}
            m={'auto'}
            p={3}
            alignItems="center"
            justifyContent="center"
            border={'1px'}
            borderColor="gray.300"
            borderRadius={'lg'}
        >
            {isLoading
                ? <CustomSpinner />
                : <DisplayData />
            }
        </Flex>
        <EvergreenPagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
        />
    </>
  );
}
