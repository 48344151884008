import React, { useEffect, useState } from 'react';
import StudentStyle from '../../assets/css/modular/student-id.module.css';
import Logo from '../../assets/images/assets/Logo.jpg';
import Banner from '../../assets/images/assets/banner.jpg';
import SampleImage from '../../assets/images/assets/sample_image.png';
import RegistrarSign from '../../assets/images/assets/registrar_sign.jpg';
import Barcode from 'react-barcode';
import { getNameFontSize } from '../common/CommonFunctions';
import axios from 'axios';
import ToastMessage from '../utility/ToastMessage';

export default function HonsMastersStudentIdCardWithValue({hall, thumb, api_thumb, fullname, registration_no, department, blood_group, batch, validity, showHeader}) {
    const [finalThumb, setFinalThumb] = useState('');
    const [registrarSign, setRegistrarSign] = useState('');

    useEffect(() => {
        if(thumb) {
            setFinalThumb(thumb);
        } else {
            setFinalThumb(api_thumb);
        }
    }, [thumb]);
    
    useEffect(() => {
        getRegistrarSign();
    }, []);

    const token = localStorage.getItem('my_token');
    const getRegistrarSign = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob",
            params: {
                filename: 'images/signatures/registrar_sign.jpeg'
            }
        };

        try {
            var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-image-from-path`, config));
            if (response.status == 200) {
                const data = response.data;
                const blob = new Blob([data], { type: data.type });
                const url = URL.createObjectURL(blob);
                setRegistrarSign(url);
            }
        } catch ({response}) {
            if(response?.status == 404) {
                ToastMessage('warning', 'Image not found');
            }else{
                ToastMessage('error','Something went wrong');
            }
        }
    }

    const getDecidedValue = (item) => {
        let value = StudentStyle.value;
        if(item && item.length >= 41) {
            value = StudentStyle.valueSmall;
        }
        return value;
    }

    return (
        <div className={StudentStyle.idCardBody}>
            <div className={StudentStyle.leftPart}>
                <p>{hall}</p>
            </div>
            <div className={StudentStyle.rightPart}>
                <div className={StudentStyle.bannerPart}>
                    {showHeader &&
                        <>
                            <img src={Logo} className={StudentStyle.logo} />
                            <img src={Banner} className={StudentStyle.banner} />
                        </>
                    }
                </div>

                <div className={StudentStyle.studentImg}>
                    <img src={finalThumb ? finalThumb : SampleImage} className={StudentStyle.image} alt='studentImg' />
                </div>
                <div className={StudentStyle.naming}>
                    <p className={StudentStyle.fullname} style={{fontSize: getNameFontSize(fullname)}}>{fullname}</p>
                </div>

                <div className={StudentStyle.info}>
                    <div className={StudentStyle.left}>
                        <div className={StudentStyle.items}>
                            <p>Student ID Number: </p>
                            <b className={StudentStyle.value}>{registration_no}</b>
                        </div>
                        <div className={StudentStyle.items}>
                            <p>Department: </p>
                            {department &&
                                <b className={getDecidedValue(department)}>{department}</b>
                            }
                        </div>
                        <div className={StudentStyle.items}>
                            <p>Blood Group: </p>
                            <b className={StudentStyle.value}>{blood_group}</b>
                        </div>
                    </div>
                    <div className={StudentStyle.right}>
                        <div className={StudentStyle.rightInfoPart}>
                            <div className={StudentStyle.items}>
                                <p>Batch: </p>
                                <b className={StudentStyle.value}>
                                    { batch }
                                </b>
                            </div>
                            <div className={StudentStyle.items}>
                                <p>Validity: </p>
                                <b className={StudentStyle.value}>
                                    { validity }
                                </b>
                            </div>
                        </div>
                        <div className={StudentStyle.img_part}>
                            <div id="footerDivContent">
                                <div className={StudentStyle.footerContent}>
                                    {/* <img src={RegistrarSign} alt='registrar' className={StudentStyle.signature} /> */}
                                    <img src={registrarSign} alt='registrar' className={StudentStyle.signature} />
                                </div>
                                <p className={StudentStyle.registrar}>Registrar</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={StudentStyle.barcode}>
                    <Barcode value={registration_no} displayValue={false} />
                </div>
            </div>
        </div>
    )
}
