import NotFound from './pages/NotFound';
import { Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/app.css';
import "./assets/css/custom.css";
import "./assets/css/admin_search.css";

import LandingPage from './pages/home/LandingPage';
import Procedure from './pages/home/Procedure';
import About from './pages/home/About';
import Contact from './pages/home/Contact';
import Faq from './pages/home/Faq';

import Login from './pages/auth/Login';
import Registration from './pages/auth/Registration';

import Apply from './pages/apply/Apply';
import ApplyFirstStep from './pages/apply/ApplyFirstStep';
import ApplySecondStep from './pages/apply/ApplySecondStep';
import RegisterStudentData from './pages/apply/RegisterStudentData';
import RegisterMphilPhdStudentData from './pages/apply/RegisterMphilPhdStudentData';
import RegisterTeacherData from './pages/apply/RegisterTeacherData';
import RegisterOfficerData from './pages/apply/RegisterOfficerData';
import RegisterStaffData from './pages/apply/RegisterStaffData';
import ImageUpload from './pages/apply/ImageUpload';

import { AdminLayout } from './pages/dashboard/AdminLayout';
import AdminDashboard from './pages/dashboard/AdminDashboard';

import { Halls } from './pages/halls/halls';
import AddHall from './pages/halls/AddHall';
import EditHall from './pages/halls/EditHall';

import { Faculties } from './pages/faculties/faculties';
import AddFaculty from './pages/faculties/AddFaculty';
import EditFaculty from './pages/faculties/EditFaculty';

import Departments from './pages/departments/departments';
import AddDepartment from './pages/departments/AddDepartment';
import EditDepartment from './pages/departments/EditDepartment';

import Admins from './pages/admins/Admins';
import AddAdmin from './pages/admins/AddAdmin';
import EditAdmin from './pages/admins/EditAdmin';
import AdminProfile from './pages/admins/AdminProfile';

import { Designations } from './pages/designations/designations';
import AddDesignation from './pages/designations/AddDesignation';
import EditDesignation from './pages/designations/EditDesignation';

import { Offices } from './pages/offices/offices';
import AddOffice from './pages/offices/AddOffice';
import EditOffice from './pages/offices/EditOffice';

import { Batches } from './pages/batches/batches';
import AddBatch from './pages/batches/AddBatch';
import EditBatch from './pages/batches/EditBatch';

import { Institutes } from './pages/institutes/institutes';
import AddInstitute from './pages/institutes/AddInstitute';
import EditInstitute from './pages/institutes/EditInstitute';

import Notices from './pages/notices/Notices';
import AddNotice from './pages/notices/AddNotice';
import EditNotice from './pages/notices/EditNotice';

import AddStudentData from './pages/studentData/AddStudentData';
import StudentData from './pages/studentData/StudentData';

import HonsMastersStudents from './pages/students/HonsMastersStudents';

import Applications from './pages/applications/Application';
import MphilPhdStudents from './pages/students/MphilPhdStudents';
import Employees from './pages/employees/Employees';
import { UserLayout } from './components/userEnd/common/UserLayout';
import UserDashboard from './pages/userEnd/UserDashboard';
import MyApplies from './pages/userEnd/MyApplies';
import ApplyDetails from './pages/userEnd/ApplyDetails';
import UserPasswordChange from './pages/userEnd/UserPasswordChange';
import UserDetails from './pages/users/UserDetails';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import AdminPasswordChange from './pages/dashboard/AdminPasswordChange';
import EveningCourses from './pages/eveningCourses/EveningCourses';
import AddEveningCourse from './pages/eveningCourses/AddEveningCourse';
import EditEveningCourse from './pages/eveningCourses/EditEveningCourse';
import RegisterEveningCourseStudentData from './pages/apply/RegisterEveningCourseStudentData';
import UserApplyDetails from './pages/users/UserApplyDetails';
import EveningCourseStudents from './pages/students/EveningCourseStudents';
import Profile from './pages/userEnd/Profile';
import { Registrations } from './pages/users/Registrations';

function Main() {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            
            {/* Common Routes for guests */}
            <Route path="/procedure" element={<Procedure/>} />
            <Route path="/about_us" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/faq" element={<Faq/>} />

            {/* Auth Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/reset_password" element={<ResetPassword />} />

            {/* Hybrid Routes */}
            <Route path="/apply-for-id" element={<Apply />} />
            <Route path="/apply-first-step" element={<ApplyFirstStep />} />
            <Route path="/apply-second-step" element={<ApplySecondStep />} />
            <Route path="/register-student-data" element={<RegisterStudentData />} />
            <Route path="/register-mphil-phd-student-data" element={<RegisterMphilPhdStudentData />} />
            <Route path="/register-teacher-data" element={<RegisterTeacherData />} />
            <Route path="/register-officer-data" element={<RegisterOfficerData />} />
            <Route path="/register-staff-data" element={<RegisterStaffData />} />
            <Route path="/register-evening-course-student-data" element={<RegisterEveningCourseStudentData />} />
            <Route path="/image-upload" element={<ImageUpload />} />

            {/* Admin Routes */}
            <Route element={<AdminLayout />}>
                <Route path="/dashboard" element={<AdminDashboard />} />

                <Route path="/halls" element={<Halls />} />
                <Route path="/halls/add" element={<AddHall />} />
                <Route path="/halls/edit/:id" element={<EditHall />} />

                <Route path="/faculties" element={<Faculties />} />
                <Route path='/faculty/add' element={<AddFaculty />} />
                <Route path="/faculty/:id/edit" element={<EditFaculty />} />

                <Route path="/departments" element={<Departments />} />
                <Route path='/department/add' element={<AddDepartment />} />
                <Route path="/department/:id/edit" element={<EditDepartment />} />

                <Route path="/admins" element={<Admins />} />
                <Route path="/add-admin" element={<AddAdmin />} />
                <Route path="/edit-admin/:user_id" element={<EditAdmin />} />
                <Route path="/admin-profile" element={<AdminProfile />} />

                <Route path="/designations" element={<Designations />} />
                <Route path='/designation/add' element={<AddDesignation />} />
                <Route path="/designation/:id/edit" element={<EditDesignation />} />

                <Route path="/offices" element={<Offices />} />
                <Route path='/office/add' element={<AddOffice />} />
                <Route path="/office/:id/edit" element={<EditOffice />} />

                <Route path="/batches" element={<Batches />} />
                <Route path="/batch/add" element={<AddBatch />} />
                <Route path="/batch/:id/edit" element={<EditBatch />} />

                <Route path="/institutes" element={<Institutes />} />
                <Route path="/institute/add" element={<AddInstitute />} />
                <Route path="/institute/:id/edit" element={<EditInstitute />} />

                <Route path="/notices" element={<Notices />} />
                <Route path="/add-notice" element={<AddNotice />} />
                <Route path="/edit-notice/:id" element={<EditNotice />} />

                <Route path="/evening-courses" element={<EveningCourses />} />
                <Route path="/add-evening-course" element={<AddEveningCourse />} />
                <Route path="/edit-evening-course/:id" element={<EditEveningCourse />} />

                {/* Add Student Data  */}
                <Route path="/csv-student-data" element={<StudentData />} />
                <Route path="/add-csv-student-data" element={<AddStudentData />} />

                {/* User Management */}
                <Route path='/hons-masters-students' element={<HonsMastersStudents />} />
                <Route path='/mphil-phd-students' element={<MphilPhdStudents />} />
                <Route path='/evening-course-students' element={<EveningCourseStudents />} />
                <Route path='/employees/:user_type' element={<Employees />} />
                {/* <Route path='/employee_details/:user_id' element={<EmployeeDetails />} /> */}
                <Route path='/user_details/:user_id' element={<UserDetails />} />
                <Route path='/user_details/:user_id/apply/:apply_id' element={<UserApplyDetails />} />

                {/* Application */}
                <Route path="/applications/:user_type" element={<Applications />} />
                <Route path='/password_change_admin' element={<AdminPasswordChange />} />

                {/* Registered but not applied students */}
                <Route path="/registered/only" element={<Registrations />} />                
            </Route>

            {/* Logged In User Routes */}
            <Route element={<UserLayout />}>
                <Route path='/user_dashboard' element={<UserDashboard />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/my-applies' element={<MyApplies />} />
                <Route path='/my-applies/:apply_id' element={<ApplyDetails />} />
                <Route path='/password_change' element={<UserPasswordChange />} />
            </Route>

            <Route path='/test' element={<>Hello Test</>} />
            {/* Catch All */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default Main;