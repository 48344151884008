import { Center } from "@chakra-ui/react";
import React from "react";
import banner from "../assets/images/output-onlinepngtools.png";


export default function NotFound(){
    return(
        <Center p={10}>
            <img src={banner} alt="logo" />
            <br />
            404!!!
            <br />
            This link does not exist
        </Center>
    )
}
