import { Box, Button, ButtonGroup, Flex, IconButton, Input, Select, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import Space from '../../components/utility/Space';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import { ceil } from 'lodash';
import CommonModal from '../../components/common/CommonModal';

export default function Employees({user_type, deleteApplyData}){
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [designations, setDesignations] = useState([]);
    const [offices, setOffices] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const token = localStorage.getItem('my_token');

    const [filterEmployeeId, setFilterEmployeeId] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterOffice, setFilterOffice] = useState('');
    const [filterDesignation, setFilterDesignation] = useState('');

    const navigate = useNavigate();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);
    const [targetDeleteApplyId, setTargetDeleteApplyId] = useState(null);

    const STATUS = [
        'Applied',
        'Payment Completed',
        'Application Confirmed',
        'Printed',
        'Delivered'
    ];

    useEffect(() => {
        fetchUsers();
    }, [user_type, currentPage]);

    useEffect(() => {
        fetchDesignations();
        fetchOffices();       
    },[]);

    const fetchUsers = async () => {
        setIsLoading(true);
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params:{
                'user_type': user_type,
                'employee_id': filterEmployeeId,
                'name': filterName,
                'office': filterOffice,
                'designation': filterDesignation,
                'limit': limit,
                'page': currentPage
            }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-employees-applies`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setUsers(apiResponse.results.users);
                setTotal(apiResponse.results.count);
                if (apiResponse.results.count) {
                    setTotalPages(ceil(apiResponse.results.count / limit));
                }
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    };

    const fetchDesignations = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params:{
                'user_type': user_type
            }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/designation`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setDesignations(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const fetchOffices = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params:{
                'types': [1,2]
            }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/office`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setOffices(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const getOfficeOptions = (offices_data) => {
        let return_data = [];

        if(offices_data.length > 0){
            for(let i=0;i<offices_data.length;i++){
                return_data.push(
                    <option key={i} value={offices_data[i].id}>{offices_data[i].office}</option>
                )
            }
        }
        return return_data;
    }

    const getDesignationOptions = (designations_data) => {
        let return_data = [];

        if(designations_data.length > 0){
            for(let i=0;i<designations_data.length;i++){
                return_data.push(
                    <option key={i} value={designations_data[i].id}>{designations_data[i].designation}</option>
                )
            }
        }
        return return_data;
    }

    const handleSearch = () => {
        fetchUsers();
    }

    const handleClear = () => {
        setFilterEmployeeId('');
        setFilterName('');
        setFilterOffice('');
        setFilterDesignation('');
    }

    const handleEdit = (id) => {
        console.log(id);
    }

    const resetUsers = (id) => {
        const newUsers = users.filter(item => item.id !== id);
        setUsers(newUsers);
    }

    const handleDelete = (id, apply_id) => {
        setTargetDeleteId(id);
        setTargetDeleteApplyId(apply_id)
        setShowDeleteModal(true);
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>Employee Id</Th>
                        <Th textAlign={'center'}>Fullname</Th>
                        <Th textAlign={'center'}>Office</Th>
                        <Th textAlign={'center'}>Designation</Th>
                        <Th textAlign={'center'}>STATUS</Th>
                        <Th textAlign={'center'}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {users.length > 0 &&
                        users.map((user, idx) => (
                            <Tr
                            key={idx}
                            display={{
                                base: 'grid',
                                md: 'table-row',
                            }}
                            sx={{
                                '@media print': {
                                display: 'table-row',
                                },
                                gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                gridGap: '10px',
                            }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    <Link className='link_details' to={'/user_details/'+user.id+'/apply/'+user.apply_id}>{user.employee_id}</Link>
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.fullname}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.office}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.designation}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {STATUS[user.apply_status]}
                                </Td>
                                <Td textAlign={'center'}>
                                <ButtonGroup variant="solid" size="sm" spacing={3}>
                                    {/* <IconButton
                                        colorScheme="green"
                                        aria-label="edit"
                                        icon={<AiFillEdit />}
                                        //onClick={() => handleEdit(user.id)}
                                    /> */}
                                    <IconButton
                                        colorScheme="red"
                                        variant="outline"
                                        aria-label="delete"
                                        icon={<BsFillTrashFill />}
                                        onClick={() => handleDelete(user.id, user.apply_id)}
                                    />
                                </ButtonGroup>
                            </Td>
                            </Tr>
                        )
                        )
                    }
                </Tbody>
            </Table>
        )
    }

    return (
        <>
            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Employee Id' size='md' value={filterEmployeeId} onChange={(e) => setFilterEmployeeId(e.target.value)} />
                    <Input placeholder='Name' size='md' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                    <Select placeholder='Select Office' size='md' value={filterOffice} onChange={(e) => setFilterOffice(e.target.value)}>
                        {getOfficeOptions(offices)}
                    </Select>
                    <Select placeholder='Select Designation' size='md' value={filterDesignation} onChange={(e) => setFilterDesignation(e.target.value)}>
                        {getDesignationOptions(designations)}
                    </Select>
                </Flex>

                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            { showDeleteModal &&
                <CommonModal
                    reset={setShowDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    setTargetDeleteApplyId={setTargetDeleteApplyId}
                    deleteAction={deleteApplyData}
                    targetDeleteId={targetDeleteId}
                    targetDeleteApplyId={targetDeleteApplyId}
                    resetUsers={resetUsers}
                />
            }

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
                className="admin_table"
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>

            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}
