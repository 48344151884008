import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import styles from '../../assets/css/modular/admin.module.css';
import customStyle from '../../assets/css/modular/custom-form.module.css';
import ToastMessage from '../../components/utility/ToastMessage';

export default function AddStudentData(){
    return <AddDataForm />;
}

const AddDataForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const [csv_file, setCsvFile] = useState(null);
    const [csv_file_error, setCsvFileError] = useState('');

    const fetchData = () => {
        let init = {
            session: ''
        };
        return init;
    }

    const validataionSchema = Yup.object({
        session: Yup.string().matches(/^\d{4}-\d{2}$/, 'Invalid pattern').required("Required")
    });

    const onSubmit = (values) => {
        if(!csv_file || !csv_file.name.endsWith('.xlsx')) {
            setCsvFileError('File is required');
            return;
        }
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
        };

        let form_data = new FormData();
        form_data.append('csv_file',csv_file);
        form_data.append('session',values.session);

        axios
        .post(`${process.env.REACT_APP_API_URL}/api/add-data`, form_data, config)
        .then((response) => {
            if(response?.status == '201'){
                ToastMessage('success',response?.data?.message);
                navigate('/csv-student-data');
            }
        })
        .catch((error) => {
            ToastMessage('error','Something went wrong');
        });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD DATA FROM EXCEL</Text>
            </Flex>
            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validataionSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='text' label='Session' name='session' placeholder='2022-23' />
                                <div className={`form-control ${customStyle.custom_form}`}>
                                    <label className={customStyle.custom_label}>Add excel file</label>
                                    <input id="file" name="csv_file" type="file" onChange={(event) => {setCsvFileError(''); setCsvFile(event.currentTarget.files[0])}} placeholder='Add csv file' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                    {csv_file_error &&
                                        <span style={{color: 'red'}}>{csv_file_error}</span>
                                    }
                                </div>
                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}
