import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SidebarWithHeader from '../../components/sidebar/sidebar';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function EditNotice(){
    return<EditNoticeForm />;
}

const EditNoticeForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');
    const id = useParams().id;
    const [notice, setNotice] = useState({});

    let config = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
    };

    useEffect(() => {
        fetchNotice();
    },[]);

    const fetchNotice = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/notice/${id}`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setNotice(apiResponse.results);
            }
        } catch (error) {
            ToastMessage("error","Something went wrong");
        }
    }

    const validataionSchema = Yup.object({
        title: Yup.string().required("Required"),
        description: Yup.string().required("Required")
    });

    const onSubmit = (values) => {
        axios
        .patch(`${process.env.REACT_APP_API_URL}/api/notice/${id}`, values, config)
        .then((response) => {
            navigate('/notices');
        })
        .catch((error) => {
            ToastMessage("error","Something went wrong");
        });
    };


    function checkEmpty(param){
        for(let i in param){
            return false;
        }
        return true;
    }

    return (
        <>
            <Flex justifyContent={'center'} className="notice_title">
                <Text fontSize="xl">EDIT NOTICE</Text>
            </Flex>
            {checkEmpty(notice) === false &&
                <div className={styles.apply_form}>
                    <Formik
                        initialValues={notice}
                        validationSchema={validataionSchema}
                        onSubmit={onSubmit}
                    >
                        {
                            formik => (
                                <Form>
                                    <FormikControl required={true} control='input' type='text' label='Title' name='title' />
                                    <FormikControl required={true} control='textarea' type='text' label='Description' name='description' />
                                    <FormikControl control='date' type='date' label='Start From' name='notice_from' />
                                    <FormikControl control='date' type='date' label='Notice Display until' name='notice_until' />

                                    <div>
                                        <Button colorScheme="green" type="submit">Update</Button>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            }
        </>
    )
}
