import React, { useEffect, useRef, useState } from "react";
import { Button, Center, Flex, Grid, GridItem, Heading, Image, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { changeStatusToAccepted, getType, isEmployee } from "../../components/common/CommonFunctions";
import { useParams } from "react-router-dom";
import axios from "axios";
import ToastMessage from "../../components/utility/ToastMessage";
import CustomSpinner from "../../components/utility/CustomSpinner";
import UserInfo from "../../components/adminEnd/UserInfo";
import EmployeeInfo from "../../components/adminEnd/EmployeeInfo";
import StudentInfo from "../../components/adminEnd/StudentInfo";
import UserInfoStyle from '../../assets/css/modular/user-info.module.css';
import EmployeeIdCardWithValue from "../../components/id-card/EmployeeIdCardWithValue";
import HonsMastersStudentIdCardWithValue from "../../components/id-card/HonsMastersStudentIdCardWithValue";
import MphilPhdStudentIdCardWithValue from "../../components/id-card/MphilPhdStudentIdCardWithValue";
import { useReactToPrint } from "react-to-print";
import ConfirmModal from "../../components/common/ConfirmModal";
import UpdateValidityModal from "../../components/common/UpdateValidityModal";
import EveningCourseStudentIdCardWithValue from "../../components/id-card/EveningCourseStudentIdCardWithValue";

export default function UserDetails(){
    const token = localStorage.getItem('my_token');
    const {user_id} = useParams();
    const [userInfo, setUserInfo] = useState({});
    const [employeeInfo, setEmployeeInfo] = useState({});
    const [studentInfo, setStudentInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    //for printing id card
    const [showModal, setShowModal] = useState(false);
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            ToastMessage('success','Id card Printed');
        }
    });

    const handleBeforePrint = () => {
        setShowModal(true);
    }

    useEffect(() => {
        fetchUser();
    }, [user_id]);

    const fetchUser = async() => {
        setIsLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/user_detailed_info/${user_id}`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                const { user_info, employee_info, student_info } = apiResponse?.results;
                setUserInfo(user_info);
                setEmployeeInfo(employee_info);
                setStudentInfo(student_info);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    return (
        isLoading
            ? <CustomSpinner />
            :
                <Details
                    userInfo={userInfo}
                    employeeInfo={employeeInfo}
                    studentInfo={studentInfo}
                />
    );
}

const Details = ({userInfo, employeeInfo, studentInfo}) => {
    const IdCard = () => {
        const token = localStorage.getItem('my_token');
        const [thumb, setThumb] = useState('');
        const [showModal, setShowModal] = useState(false);
        const [showHeader, setShowHeader] = useState(true);
        const [showUpdateValidityModal, setShowUpdateValidityModal] = useState(false);
        const [tempValidity, setTempValidity] = useState(() => {
            if(userInfo?.user_type == '5'){
                return studentInfo?.mphil_phd_details?.validity;
            }else if(userInfo?.user_type == '8'){
                return studentInfo?.evening_course_student_details?.validity;
            }else if(userInfo?.user_type == '4'){
                return studentInfo?.hons_masters_details?.validity;
            }else {
                return '';
            }
        });


        const componentRef = useRef();

        useEffect(() => {
            if(userInfo?.thumb){
                getThumb(userInfo?.thumb)
            } else if(userInfo?.api_thumb) {
                setThumb(userInfo?.api_thumb);
            }
        },[userInfo?.thumb]);

        const handlePrint = useReactToPrint({
            content: () => componentRef.current,
            onAfterPrint: () => {
                ToastMessage('success', 'ID card has been printed successfully')
            }
        });

        const handleBeforePrint = () => {
            setShowModal(true);
        }

        const handlePreview = useReactToPrint({
            content: () => componentRef.current
        });

        const getThumb = async (param) => {
            const config = {
                headers: { Authorization: `Bearer ${token}` },
                responseType: "blob",
                params: {
                    filename: param
                }
            };
    
            try {
                var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-image-from-path`, config));
                if (response.status == 200) {
                    const data = response.data;
                    const blob = new Blob([data], { type: data.type });
                    const url = URL.createObjectURL(blob);
                    setThumb(url);
                }
            } catch ({response}) {
                if(response?.status == 404) {
                    ToastMessage('warning', 'Image not found');
                }else{
                    ToastMessage('error','Something went wrong');
                }
            }
        }

        const updateValidity = () => {
            setShowUpdateValidityModal(true);
        }

        const performValidityChange = (validity) => {
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                  }
            };

            const params = {
                validity: validity,
                user_type: userInfo?.user_type
            }
    
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/update-validity/${userInfo?.id}`, params, config)
                .then((response) => {
                    setTempValidity(validity);
                    ToastMessage('success','Successfully Updated');
                })
                .catch((error) => {
                    const {status} = error?.response;
    
                    let message = 'Something went wrong';
                    if(status == 422) {
                        message = 'Invalid Input';
                    }
    
                    if(status == 400) {
                        message = 'Data already exists';
                    }
    
                    ToastMessage('error',message);
                });
        }

        return (
            <Flex direction={'column'} gap={'10px'}>
                <Tooltip label='This application is already accepted and the ID card is printed. Do you want to print it again?'>
                    <Button colorScheme='teal' size='sm' onClick={handleBeforePrint}>
                        <span>PRINT AGAIN</span>
                    </Button>
                </Tooltip>

                {showModal &&
                    <ConfirmModal 
                        reset={setShowModal}
                        title={'PRINT?'}
                        desc={'If you click the [YES] button ID card will be printed. Are you sure?'}
                        action={handlePrint}
                    />
                }

                {showUpdateValidityModal &&
                    <UpdateValidityModal 
                        reset={setShowUpdateValidityModal}
                        action={performValidityChange}
                        validity={userInfo?.user_type == '5' ? studentInfo?.mphil_phd_details?.validity : userInfo?.user_type == '8' ? studentInfo?.evening_course_student_details?.validity : '' }
                    />
                }
                
                <div ref={componentRef}>
                    { isEmployee(userInfo?.user_type) &&
                        <EmployeeIdCardWithValue
                            office={employeeInfo?.employee_details?.office}
                            thumb={thumb}
                            fullname={userInfo?.fullname}
                            employee_id={employeeInfo?.employee_details?.employee_id}
                            nid={userInfo?.nid}
                            blood_group={userInfo?.blood_group}
                            designation={employeeInfo?.employee_details?.designation}
                            dob={userInfo?.dob}
                            showHeader={showHeader}
                        /> 
                    }
                    {userInfo?.user_type == '4' &&
                        <HonsMastersStudentIdCardWithValue
                            hall={studentInfo?.hons_masters_details?.hall}
                            thumb={thumb}
                            api_thumb={userInfo?.api_thumb}
                            fullname={userInfo?.fullname}
                            registration_no={studentInfo?.hons_masters_details?.registration_no}
                            department={studentInfo?.hons_masters_details?.dept_or_inst == '0' ? studentInfo?.hons_masters_details?.department : studentInfo?.hons_masters_details?.institute }
                            blood_group={userInfo?.blood_group}
                            batch={studentInfo?.hons_masters_details?.batch}
                            validity={tempValidity}
                            showHeader={showHeader}
                        />
                    }
                    {userInfo?.user_type == '5' &&
                        <MphilPhdStudentIdCardWithValue
                            hall={studentInfo?.mphil_phd_details?.hall}
                            thumb={thumb}
                            fullname={userInfo?.fullname}
                            registration_no={studentInfo?.mphil_phd_details?.registration_no}
                            department={studentInfo?.mphil_phd_details?.dept_or_inst == '0' ? studentInfo?.mphil_phd_details?.department : studentInfo?.mphil_phd_details?.institute }
                            blood_group={userInfo?.blood_group}
                            session={studentInfo?.mphil_phd_details?.session}
                            regularity={studentInfo?.mphil_phd_details?.type == '0' ? 'Regular' : 'Irregular'}
                            apply_for={studentInfo?.mphil_phd_details?.mphill_or_phd == '0' ? 'Mphil' : studentInfo?.mphil_phd_details?.mphill_or_phd == '1' ? 'Phd' : '' }
                            validity={tempValidity}
                            showHeader={showHeader}
                        />
                    }
                    {userInfo?.user_type == '8' &&
                        <EveningCourseStudentIdCardWithValue
                            hall={studentInfo?.evening_course_student_details?.hall}
                            thumb={thumb}
                            fullname={userInfo?.fullname}
                            registration_no={studentInfo?.evening_course_student_details?.registration_no}
                            blood_group={userInfo?.blood_group}
                            session={studentInfo?.evening_course_student_details?.session}
                            batch={studentInfo?.evening_course_student_details?.batch}
                            admission_year={studentInfo?.evening_course_student_details?.admission_year}
                            validity={tempValidity}
                            course_title={studentInfo?.evening_course_student_details?.course_title}
                            course_id={studentInfo?.evening_course_student_details?.course_id}
                            showHeader={showHeader}
                        />
                    }
                </div>

                <>
                    <Tooltip label='Please do not print from here. Use it only to see the preview'>
                        <Button colorScheme='facebook' size='sm' onClick={handlePreview}>
                            <span>Preview Id Card</span>
                        </Button>
                    </Tooltip>
                    <Tooltip label='Header Hide/Show'>
                        <Button colorScheme='linkedin' size='sm' onClick={() => setShowHeader(!showHeader)}>
                            <span>Header {showHeader ? 'Hide' : 'Show'}</span>
                        </Button>
                    </Tooltip>
                    {(userInfo?.user_type === '5' || userInfo?.user_type === '8' || userInfo?.user_type === '4') &&
                        <Tooltip label='Validity update of this student. A popup will appear to change the validity.'>
                            <Button colorScheme='orange' size='sm' onClick={updateValidity}>
                                <span>Change Validity</span>
                            </Button>
                        </Tooltip>
                    }
                </>
            </Flex>
        )
    }

    return(
        <>
            <Center>
                <Heading as='h2' size='xl' color={"teal"}>
                    User Details ({getType(userInfo?.user_type)})
                </Heading>
            </Center>

            <UserInfo userInfo={userInfo} />

            <Center>
                <Heading as='h2' size='xl' color={"teal"}>
                    {isEmployee(userInfo?.user_type) ? 'Employee Info' : 'Student Info'}
                </Heading>
            </Center>
            <div className={UserInfoStyle.details_div}>
                <div className={UserInfoStyle.others}>
                    {/* Id Card */}
                    <Center>
                        <IdCard />
                    </Center>
                </div>
                <div className={UserInfoStyle.info}>
                    {isEmployee(userInfo?.user_type)
                        ?  <EmployeeInfo employeeInfo={employeeInfo} />
                        :  <StudentInfo studentInfo={studentInfo} />
                    }
                </div>
            </div>

        </>
    )
}
