import React, { useEffect, useState } from 'react';
import './designations.css';
import {
  Flex,
  ButtonGroup,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Box,
  Button,
  Input,
  Text,
  Select,
} from '@chakra-ui/react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillPeopleFill, BsFillTrashFill } from 'react-icons/bs';
import { AddIcon } from '@chakra-ui/icons';
import * as Yup from 'yup';
import axios from 'axios';
import { ceil } from 'lodash';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import Space from '../../components/utility/Space';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../../components/common/CommonModal';

export const Designations = () => {
  return <DesignationTableDemo />;
};

const validationSchema = Yup.object({
  designation: Yup.string().required('Designation is required'),
  type: Yup.string().required('Type is required'),
});

function DesignationTableDemo() {
    const header = ['Type', 'Designation','Action'];
    const navigate = useNavigate();
    const [designations, setDesignations] = useState([]);
    const limit = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const [filterDesignation, setFilterDesignation] = useState('');
    const [filterType, setFilterType] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const types = {
        '3' : 'Teacher',
        '6' : 'Officer',
        '7' : 'Staff'
    };

    const token = localStorage.getItem('my_token');

    let config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const getDesignations = async () => {
        let params = {
            designation: filterDesignation,
            type: filterType,
            limit,
            page: currentPage
        };

        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/designation`, {
                    params: params,
                    headers: config
                })
            ).data;
            const results = apiResponse.results;
            if (results[0] && results[0].length > 0) {
                setDesignations(results[0]);
                if (results[1].count) {
                    setTotalPages(ceil(results[1].count / limit));
                }
            }else {
                setDesignations([]);
            }
        } catch (err) {
            ToastMessage('error','Something went wrong');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getDesignations();
    }, [currentPage]);

    const handleSearch = () => {
        getDesignations();
    }

    const handleClear = () => {
        setFilterDesignation('');
        setFilterType('');
    }

    const onClickDesignationDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
                >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                    {header.map((x) => (
                        <Th key={x} textAlign={'center'}>
                        {x}
                        </Th>
                    ))}
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {designations.map((designation, idx) => (
                        <Tr
                        key={idx}
                        display={{
                            base: 'grid',
                            md: 'table-row',
                        }}
                        sx={{
                            '@media print': {
                            display: 'table-row',
                            },
                            gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                            gridGap: '10px',
                        }}
                        >
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {types[designation.type]}
                            </Td>
                            <Td
                                fontSize="md"
                                fontWeight="hairline"
                                textAlign={'center'}
                            >
                                {designation.designation}
                            </Td>
                            <Td textAlign={'center'}>
                                <ButtonGroup variant="solid" size="sm" spacing={3}>
                                <IconButton
                                    colorScheme="green"
                                    aria-label="edit"
                                    icon={<AiFillEdit />}
                                    onClick={() => navigate(`/designation/${designation.id}/edit`)}
                                />
                                <IconButton
                                    colorScheme="red"
                                    variant="outline"
                                    aria-label="delete"
                                    icon={<BsFillTrashFill />}
                                    onClick={() => onClickDesignationDelete(designation.id)}
                                />
                                </ButtonGroup>
                            </Td>
                        </Tr>
                    )
                    )}
                </Tbody>
            </Table>
        )
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/designation/${id}`,{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                getDesignations();
                alert("Deleted Successfully");
            })
            .catch((error) => {
                const {status} = error?.response;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }
                ToastMessage('error',message);
            });
    }

    return (
        <>
            <Flex justifyContent={'center'}>
                <Text fontSize="xl">DESIGNATIONS </Text>
            </Flex>
            <Flex justifyContent={'center'} my={'1rem'} w={{ base: '95%', md: '97.5%', sm: 'full' }}>
                <Box>
                    <Button
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        variant={'solid'}
                        size="sm"
                        onClick={() => navigate('/designation/add')}
                    >
                        Add
                    </Button>
                </Box>
            </Flex>

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={handleDelete}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
                <Flex
                    w={{ base: '95%', md: '95%', sm: 'full' }}
                    m={'auto'}
                    p={3}
                    alignItems="center"
                    justifyContent="center"
                    border={'1px'}
                    borderColor="gray.300"
                    backgroundColor="#ffffff"
                    borderRadius={'lg'}
                    className="admin_table"
                    direction="column"
                >

                    <Flex className='search_items' gap="4">
                        <Input placeholder='Designation' size='md' value={filterDesignation} onChange={(e) => setFilterDesignation(e.target.value)} />
                        <Select placeholder='Select Type' size='md' value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                            <option value='3'>Teacher</option>
                            <option value='6'>Officer</option>
                            <option value='7'>Staff</option>
                        </Select>
                    </Flex>
                    <Flex mt={'10px'} direction='row' gap='4'>
                        <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                            Search
                        </Button>
                        <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                            Clear
                        </Button>
                    </Flex>
                </Flex>

                <Space />
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
}
