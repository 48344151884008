// @ts-nocheck
import React,{useEffect, useState} from 'react';
import { ChakraProvider, extendTheme, Heading, Checkbox, Button } from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import {Formik, Form} from 'formik';
// @ts-ignore
import styles from "../../assets/css/modular/apply-form.module.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import ToastMessage from '../../components/utility/ToastMessage';
import axios from 'axios';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
});

export default function RegisterStudentData(){
    const [batches, setBatches] = useState([]);
    const [halls, setHalls] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [institutes, setInstitutes] = useState([]);

    const navigate = useNavigate();
    let application_data = localStorage.getItem('application_data');
    let is_checked = true;
    let is_first_step = true;
    let is_second_step = true;

    if(!application_data){
        is_checked = false;
    }else{
        application_data = JSON.parse(application_data);
        if(!application_data.declaration || application_data.declaration !== true){
            is_checked = false;
        }
        if(!application_data.declaration || application_data.first_step !== true){
            is_first_step = false;
        }
        if(!application_data.declaration || application_data.second_step !== true){
            is_second_step = false;
        }
    }

    useEffect(() => {
        fetchOptionData();
        if(is_checked == false){
            ToastMessage("warning","Please check the declaration first");
            navigate('/apply-for-id');
        }

        if(is_first_step == false){
            ToastMessage("warning","Please complete first step first");
            navigate('/apply-first-step');
        }

        if(is_second_step == false){
            ToastMessage("warning","Please complete previous step first");
            navigate('/apply-second-step');
        }



    }, [is_checked, is_first_step, is_second_step]);

    const fetchData = () => {
        let initialValues = {
            batch: '',
            hall_id: '',
            registration_no: '',
            apply_for: '', //hons or masters
            hons_class_roll: '',
            hons_exam_roll: '',
            masters_class_roll: '',
            masters_exam_roll: '',
            dept_or_institute: '',
            department_id: '',
            institute_id: '',
            validity: '',
            is_fetched: false,
        };

        initialValues.batch = application_data?.batch;
        initialValues.hall_id = application_data?.hall_id;
        initialValues.registration_no = application_data?.user_id;
        initialValues.apply_for = application_data?.apply_for;
        initialValues.hons_class_roll = application_data?.hons_class_roll;
        initialValues.hons_exam_roll = application_data?.hons_exam_roll;
        initialValues.masters_class_roll = application_data?.masters_class_roll;
        initialValues.masters_exam_roll = application_data?.masters_exam_roll;
        initialValues.dept_or_institute = application_data?.dept_or_institute;
        initialValues.department_id = application_data?.department_id;
        initialValues.institute_id = application_data?.institute_id;
        initialValues.validity = application_data?.validity;
        initialValues.is_fetched = application_data?.is_fetched;

        return initialValues;
    }

    const fetchOptionData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-options`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setBatches(apiResponse.results.batches);
                setDepartments(apiResponse.results.departments);
                setHalls(apiResponse.results.halls);
                setInstitutes(apiResponse.results.institutes);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }
    }

    const validataionSchema = Yup.object({
        batch: Yup.string().required("Required"),
        hall_id: Yup.string().required("Required"),
        registration_no: Yup.string().required("Required"),
        dept_or_institute: Yup.string().required("Required"),
        apply_for: Yup.string().required("Required"),
        department_id: Yup.string().when("dept_or_institute",{
            is: '0',
            then: Yup.string().required("Required")
        }),
        institute_id: Yup.string().when("dept_or_institute",{
            is: '1',
            then: Yup.string().required("Required")
        }),
        hons_class_roll: Yup.string().when("apply_for",{
            is: '0',
            then: Yup.string().required("Required")
        }),
        hons_exam_roll: Yup.string().when("apply_for",{
            is: '0',
            then: Yup.string().required("Required")
        }),
        masters_class_roll: Yup.string().when("apply_for",{
            is: '1',
            then: Yup.string().required("Required")
        }),
        masters_exam_roll: Yup.string().when("apply_for",{
            is: '1',
            then: Yup.string().required("Required")
        }),
    });

    const onSubmit = values => {
        application_data.batch = values.batch;
        application_data.hall_id = values.hall_id;
        application_data.registration_no = values.registration_no;
        application_data.dept_or_institute = values.dept_or_institute;
        application_data.department_id = values.department_id;
        application_data.institute_id = values.institute_id;
        application_data.validity = getValidityByBatch(values.batch);
        application_data.apply_for = values.apply_for;
        application_data.hons_class_roll = values.hons_class_roll;
        application_data.hons_exam_roll = values.hons_exam_roll;
        application_data.masters_class_roll = values.masters_class_roll;
        application_data.masters_exam_roll = values.masters_exam_roll;
        application_data.third_step = true;
        localStorage.setItem('application_data',JSON.stringify(application_data));
        localStorage.setItem('halls',JSON.stringify(halls));
        localStorage.setItem('departments',JSON.stringify(departments));
        localStorage.setItem('institutes',JSON.stringify(institutes));
        localStorage.setItem('batches',JSON.stringify(batches));

        navigate('/image-upload');
    };

    const getValidityByBatch = (batchParam) => {
        let filterArr = batches.filter((item) => {
            if(item.batch === batchParam) {
                return item;
            }
        });

        return filterArr[0]['validity'];
    } 

    const getBatchOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].batch,
                    value: options_data[i].batch
                });
            }
        }
        return return_data;
    }

    const getHallOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].hall
                });
            }
        }
        return return_data;
    }

    const dept_or_institute_options = [
        {key: 0, value: "Department"},
        {key: 1, value: "Institute"},
    ];

    const apply_for_options = [
        {key: 0, value: "Hons Id Card"},
        {key: 1, value: "Masters Id Card"},
    ];

    const getDepartmentOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].department
                });
            }
        }
        return return_data;
    }

    const getInsituteOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].institute
                });
            }
        }
        return return_data;
    }

    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            Apply for Id card : 3rd Step (Academic Information)
                        </Heading>
                        <br />

                        <div className={styles.apply_form}>
                            <Formik
                                initialValues={fetchData()}
                                validationSchema={validataionSchema}
                                onSubmit={onSubmit}
                            >
                                {
                                    formik => (
                                        <Form>
                                            <FormikControl required={true} control='select' label='Batch' name='batch' options={getBatchOptions(batches)} disabled={formik.values.is_fetched} />
                                            <FormikControl required={true} control='select' label='Hall' name='hall_id' options={getHallOptions(halls)} disabled={formik.values.is_fetched} />
                                            <FormikControl required={true} style={{ backgroundColor: '#c3c9c3' }} control='input' type='text' label='Registration No (Unchangable)' name='registration_no' disabled />
                                            <FormikControl required={true} control='radio' label='Department / Institute' name='dept_or_institute' options={dept_or_institute_options} disabled={formik.values.is_fetched} />
                                            {formik.values.dept_or_institute &&
                                                formik.values.dept_or_institute == 0
                                                    ? <FormikControl required={true} control='select' label='Department' name='department_id' options={getDepartmentOptions(departments)} disabled={formik.values.is_fetched} />
                                                    : formik.values.dept_or_institute == 1
                                                        ? <FormikControl required={true} control='select' label='Institute' name='institute_id' options={getInsituteOptions(institutes)} disabled={formik.values.is_fetched} />
                                                        : <></>
                                            }
                                            <FormikControl required={true} control='radio' label='Apply For' name='apply_for' options={apply_for_options} />
                                            {formik.values.apply_for &&
                                                formik.values.apply_for == 0
                                                    ? <>
                                                        <FormikControl required={true} control='input' type='text' label='Honors Class Roll' name='hons_class_roll' disabled={formik.values.is_fetched} />
                                                        <FormikControl required={true} control='input' type='text' label='Honors Exam Roll' name='hons_exam_roll' disabled={formik.values.is_fetched} />
                                                      </>
                                                    : formik.values.apply_for == 1
                                                        ? <>
                                                            <FormikControl required={true} control='input' type='text' label='Masters Class Roll' name='masters_class_roll' />
                                                            <FormikControl control='input' type='text' label='Masters Exam Roll' name='masters_exam_roll' />
                                                          </>
                                                        : <></>
                                            }
                                            <div className={styles.neighbor_buttons}>
                                                <Link to='/apply-second-step'><Button colorScheme="blue" type="button">Prev</Button></Link>
                                                <Button colorScheme="green" type="submit">Next</Button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
