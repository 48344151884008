import { FormControl, FormHelperText, FormLabel, Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";

export const InputField = (props) => {
    const { label, leftAddon, ...restOfProps } = props;
    const [field, meta] = useField(props);
    return(
        <FormControl id={props.name} isInvalid={!!meta.error && !!meta.touched}>
            {label && (
                <FormLabel mb="1" htmlFor={props.name}>
                    {label}
                </FormLabel>
            )}
            <InputGroup>
                { leftAddon && <InputLeftAddon bg="blue.50" children={leftAddon} />}
                <Input focusBorderColor="blue.500" {...field} {...restOfProps} />
            </InputGroup>
            {meta.error && meta.touched && (
                <FormHelperText><span className="error">{meta.error}</span></FormHelperText>
            )}
        </FormControl>
    );
}
