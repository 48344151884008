import { Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'

export default function LabelValue({index= 'key', value = 'Value'}) {
    return (
        <>
            <Grid templateColumns='repeat(6, 1fr)' py={'10px'} borderBottom={'1px dotted teal'}>
                <GridItem colSpan={2} justifySelf={'right'} px={'10px'}>
                    <Text color={'teal'} fontSize='md'>
                        {index}
                    </Text>
                </GridItem>
                <GridItem colSpan={4} justifySelf={'left'} px={'10px'}>
                    <Text fontSize='md'>
                        {value}
                    </Text>
                </GridItem>
            </Grid>
        </>
    )
}
