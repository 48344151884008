import { AtSignIcon, LockIcon, PhoneIcon } from "@chakra-ui/icons";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Center, ChakraProvider, Checkbox, color, Heading, Image, Input, Stack, Text, theme } from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputField } from "../../components/auth/InputField";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import { RadioButtons } from "../../components/auth/RadioButtons";
import * as Yup from "yup";
// @ts-ignore
import banner from "../../assets/images/output-onlinepngtools.png";

export default function Registration(){
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("info");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const initialValues = {
        user_type: "",
        email: "",
        mobile: "",
        password: "",
        password_confirmation: "",
      };

      const onSubmit = (values, onSubmitProps) => {
        setIsSubmitting(true);
        setTimeout(() => {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/api/user-register`,
                data: values
            })
            .then(function (response) {
                setIsSubmitting(false);
                onSubmitProps.resetForm();
                setStatus("success");
                setMessage("Successfully Registered! Please check your email to confirm the registration")
            })
            .catch(function (error) {
                if(error.response.status == 401){
                    setStatus("error");
                    setMessage(error.response.data.message);
                }
                else if(error.response.status == 500){
                    setStatus("error");
                    setMessage("The server is not responding");
                }
                setIsSubmitting(false);
            });
            setIsSubmitting(false);
        }, 1000);
      };

      const validationSchema = Yup.object({
        user_type: Yup.string().required("User type is required"),
        email: Yup.string().email("Invalid Email Address").required("Email Address is required"),
        mobile: Yup.string().required("Fullname is required"),
        password: Yup.string().required("Password is required"),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
      });

    const key_options = [
        {key: 3, value: "Teacher"},
        {key: 4, value: "Honors or Masters Student"},
        {key: 5, value: "MPhil or Phd Student"},
        {key: 6, value: "Officer"},
        {key: 7, value: "Staff"},
    ]

    return(
        <ChakraProvider theme={theme}>
            <Header />
            <Center bg="blue.200">
                <Stack boxShadow="md" bg="whiteAlpha.900" px="20" py="10" rounded="md" my={10} >
                    <Image src={banner} alt="ju logo" maxW="100px" mx="auto" />
                    <Heading as="h1" textAlign={"center"}>REGISTRATION</Heading>
                    <Text fontSize="lg" color="gray.600">
                        Please provide necessary informations
                    </Text>

                    { message != "" &&
                        <Alert
// @ts-ignore
                        status={status}>
                            <AlertIcon />
                            <AlertDescription>{message}</AlertDescription>
                        </Alert>
                    }

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        <Form>
                            <Stack my="4" spacing="6">
                                <RadioButtons
                                    name="user_type"
                                    type="radio"
                                    label="Register As"
                                    options={key_options}
                                />
                                <InputField
                                    name="email"
                                    type="email"
                                    label="Email"
                                    placeholder="Enter your email"
                                    leftAddon={<AtSignIcon color="blue.500" />}
                                />
                                <InputField
                                    name="mobile"
                                    type="text"
                                    label="Mobile No"
                                    placeholder="Enter your mobile no"
                                    leftAddon={<PhoneIcon color="blue.500" />}
                                />
                                <InputField
                                    name="password"
                                    type="password"
                                    label="Password"
                                    placeholder="Enter your password"
                                    leftAddon={<LockIcon color="blue.500" />}
                                />
                                <InputField
                                    name="password_confirmation"
                                    type="password"
                                    label="Confirm Password"
                                    placeholder="Confirm your password"
                                    leftAddon={<LockIcon color="blue.500" />}
                                />
                                <Button
                                    isLoading={isSubmitting}
                                    loadingText="Connecting to server"
                                    size="lg"
                                    colorScheme="blue"
                                    type="submit"
                                >
                                    Register
                                </Button>
                            </Stack>
                        </Form>
                    </Formik>

                    <Stack justify="center" color="gray.600" spacing="3">
                        <Text as="div" textAlign="center">
                            <span>Already have an account? </span>
                            <Button colorScheme="blue" variant="link" >
                                <Link to={'/login'}>Login Here!!</Link>
                            </Button>
                        </Text>
                    </Stack>
                </Stack>
            </Center>
            <Footer />
        </ChakraProvider>
    )
}
