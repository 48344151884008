import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function AddDepartment(){
    return <AddDepartmentForm />;
}

const AddDepartmentForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const [faculties, setFaculties] = useState([]);

    const initialValues = {
        faculty_id: '',
        department: '',
        shortname: ''
    };

    const validationSchema = Yup.object({
        faculty_id: Yup.string().required('Faculty is required'),
        department: Yup.string().max(256, 'Please enter within 256 characters').required('Department is required'),
        shortname: Yup.string().max(256, 'Please enter within 256 characters').required('Shortname is required'),
    });

    useEffect(() => {
        getFaculties();
    }, []);

    const getFaculties = async () => {
        try {
        var apiResponse = (
            await axios.get(`${process.env.REACT_APP_API_URL}/api/faculty`)
        ).data;
        const results = apiResponse.results;
        if (results[0] && results[0].length > 0) {
            setFaculties(results[0]);
        }
        } catch (err) {
            console.log({ err });
        }
    };

    const facultyOptions = () => {
        let rtn = [
            {key: '', value: "Select an option"}
        ];

        faculties.map((faculty, index) => {
            return (
                rtn.push(
                    {
                        key: faculty.id,
                        value: faculty.faculty
                    }
                )
            )
        });

        return rtn;
    }

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/department`, values, config)
            .then((response) => {
                ToastMessage('success','Successfully added');
                navigate('/departments');
            })
            .catch((error) => {
                const {data, status} = error?.response;
                const { errors } = data;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }

                if(status == 400) {
                    message = 'Data already exists';
                }

                ToastMessage('error',message);
            });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD DEPARTMENT</Text>
            </Flex>

            <div className={styles.apply_form}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='select' label='Faculty' name='faculty_id' options={facultyOptions()} />
                                <FormikControl required={true} control='input' type='text' placeholder='Enter Department' label='Department' name='department' />
                                <FormikControl required={true} control='input' type='text' placeholder='Enter shortname' label='Shortname' name='shortname' />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}
