import React, { useEffect, useState } from 'react';
import StudentStyle from '../../assets/css/modular/student-id.module.css';
import Logo from '../../assets/images/assets/Logo.jpg';
import Banner from '../../assets/images/assets/banner.jpg';
import SampleImage from '../../assets/images/assets/sample_image.png';
import RegistrarSign from '../../assets/images/assets/registrar_sign.jpg';
import Barcode from 'react-barcode';
import { getNameFontSize } from '../common/CommonFunctions';
import axios from 'axios';
import ToastMessage from '../utility/ToastMessage';

export default function MphilPhdStudentIdCardWithValue({hall, thumb, fullname, registration_no, department, blood_group, session, regularity, apply_for, validity, showHeader}) {
    const [finalThumb, setFinalThumb] = useState('');
    const [registrarSign, setRegistrarSign] = useState('');

    useEffect(() => {
        setFinalThumb(thumb);
    }, [thumb]);

    useEffect(() => {

    }, [showHeader]);

    useEffect(() => {
        getRegistrarSign();
    }, []);

    const token = localStorage.getItem('my_token');
    const getRegistrarSign = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob",
            params: {
                filename: 'images/signatures/registrar_sign.jpeg'
            }
        };

        try {
            var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-image-from-path`, config));
            if (response.status == 200) {
                const data = response.data;
                const blob = new Blob([data], { type: data.type });
                const url = URL.createObjectURL(blob);
                setRegistrarSign(url);
            }
        } catch ({response}) {
            if(response?.status == 404) {
                ToastMessage('warning', 'Image not found');
            }else{
                ToastMessage('error','Something went wrong');
            }
        }
    }

    return (
        <div className={StudentStyle.idCardBody}>
            <div className={StudentStyle.leftPart}>
                <p>{hall}</p>
            </div>
            <div className={StudentStyle.rightPart}>
                <div className={StudentStyle.bannerPart}>
                    {showHeader &&
                        <>
                            <img src={Logo} alt={'logo'} className={StudentStyle.logo} />
                            <img src={Banner} alt={'banner'} className={StudentStyle.banner} />
                        </>
                    }
                </div>

                <div className={StudentStyle.studentImg}>
                    <img src={ finalThumb ? finalThumb : SampleImage } alt='final' className={StudentStyle.image} />
                </div>
                <div className={StudentStyle.naming}>
                    <p className={StudentStyle.fullname} style={{fontSize: getNameFontSize(fullname)}}>{fullname}</p>
                </div>

                <div className={StudentStyle.info}>
                    <div className={StudentStyle.left}>
                        <div className={StudentStyle.items}>
                            <p>Student ID Number: </p>
                            <b className={StudentStyle.value}>{registration_no}</b>
                        </div>
                        <div className={StudentStyle.items}>
                            <p>Department: </p>
                            <b className={StudentStyle.value}>{department}</b>
                        </div>
                        <div className={StudentStyle.items}>
                            <p>Blood Group: </p>
                            <b className={StudentStyle.value}>{blood_group}</b>
                        </div>
                        <div className={StudentStyle.barcode}>
                            <Barcode value={registration_no} displayValue={false} width={40} height={1100}/>
                        </div>
                    </div>
                    <div className={StudentStyle.right}>
                        <div className={StudentStyle.rightInfoPart}>
                            <div className={StudentStyle.items}>
                                <p>Class: </p>
                                <b className={StudentStyle.value}>
                                    {apply_for}({regularity})
                                </b>
                            </div>
                            <div className={StudentStyle.items}>
                                <p>Session: </p>
                                <b className={StudentStyle.value}>
                                    {session}
                                </b>
                            </div>
                            <div className={StudentStyle.items}>
                                <p>Validity: </p>
                                <b className={StudentStyle.value}>
                                    {validity}
                                </b>
                            </div>
                        </div>
                        <div className={StudentStyle.img_part} style={{marginTop: '16px'}}>
                            <div id="footerDivContent">
                                <div className={StudentStyle.footerContent}>
                                    <img src={registrarSign} className={StudentStyle.signature} />
                                </div>
                                <p className={StudentStyle.registrar}>Registrar</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
