import React, { useContext, useEffect, useState } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    DrawerHeader,
    DrawerBody,
    Stack,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiStar,
    FiLock,
    FiMenu,
    FiBell,
    FiChevronDown,
    FiLogOut
} from 'react-icons/fi';
import { UserContext } from './UserLayout';
import { getType, handleLogout } from '../../common/CommonFunctions';
import axios from 'axios';
import ToastMessage from '../../utility/ToastMessage';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function UserSidebar() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const userDetails = useContext(UserContext);

    const components = {
        Drawer: {
            variants: {
                alwaysOpen: {
                    parts: ['dialog, dialogContainer'],
                    dialog: {
                        pointerEvents: 'auto',
                    },
                    dialogContainer: {
                        pointerEvents: 'none',
                    },
                },
            },
        },
    };

    const OpenDrawer = ({ ...rest }) => {
        return (
            <Drawer variant='alwaysOpen' {...rest}>
                <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px'>{userDetails?.fullname}</DrawerHeader>
                    <DrawerBody><SidebarContent onClose={onClose} /></DrawerBody>
                </DrawerContent>
            </Drawer>
        );
    };

    return (
        <>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
                name={userDetails?.fullname}
                password_init={userDetails?.password_initialized}
            />
                <OpenDrawer
                    autoFocus={false}
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full"
                />
            <MobileNav onOpen={onOpen} userDetails={userDetails} />
        </>
    );
}

const SidebarContent = ({ onClose, name, password_init, ...rest }) => {
    const {pathname} = useLocation();

    const navigate = useNavigate();

    const isActive = (uri) => {
        return pathname.includes(uri);
    }

    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between" flexDirection={'column'}>
                <Text fontSize="xl" fontFamily="monospace" fontWeight="bold" my={4}>
                    {name}
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            <Stack mt={4}>
                <NavItem icon={FiHome} to={'/user_dashboard'} className={isActive('/user_dashboard') ? 'active' : ''}>
                    Dashboard
                </NavItem>
                <NavItem icon={FiStar} to={'/my-applies'} className={isActive('/my-applies') ? 'active' : ''}>
                    Applies
                </NavItem>
                <NavItem icon={FiLock} to={'/password_change'} className={isActive('/password_change') ? 'active' : ''}>
                    {password_init
                        ? <Text color={'red'} fontWeight={'bold'}>Password Change</Text>
                        : <Text>Password Change</Text>
                    }
                </NavItem>
                <NavItem icon={FiLogOut} className={isActive('/sign') ? 'active' : ''} onClick={() => handleLogout(navigate)}>
                    Sign Out
                </NavItem>
            </Stack>
        </Box>
    );
};

const NavItem = ({ icon, children, to='#', ...rest }) => {
    return (
        <Link to={to} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                bg: 'teal',
                color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                        color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

const MobileNav = ({ onOpen, userDetails, ...rest }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');
    const [thumb, setThumb] = useState('https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9');

    useEffect(() => {
        if(userDetails?.thumb) {
            getThumb();
        } else if(userDetails?.api_thumb) {
            setThumb(userDetails?.api_thumb);
        }
    }, [userDetails]);

    const getThumb = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob",
            params: {
                filename: userDetails?.thumb
            }
        };

        try {
            var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-image-from-path`, config));
            if (response.status == 200) {
                const data = response.data;
                const blob = new Blob([data], { type: data.type });
                const url = URL.createObjectURL(blob);
                setThumb(url);
            }
        } catch ({response}) {
            if(response?.status == 404) {
                ToastMessage('warning', 'Image not found');
            }else{
                ToastMessage('error','Something went wrong');
            }
        }
    }

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}
        >
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                JUPIS
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                <IconButton
                    size="lg"
                    variant="ghost"
                    aria-label="open menu"
                    icon={<FiBell />}
                />
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}
                        >
                            <HStack>
                                <Avatar
                                size={'sm'}
                                src={thumb}
                                />
                                <VStack
                                display={{ base: 'none', md: 'flex' }}
                                alignItems="flex-start"
                                spacing="1px"
                                ml="2">
                                <Text fontSize="sm">{userDetails?.fullname}</Text>
                                <Text fontSize="xs" color="gray.600">
                                    {getType(userDetails?.user_type)}
                                </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}
                        >
                            <MenuItem onClick={() => navigate('/user_dashboard')}>Profile</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => handleLogout(navigate)}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};
