import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarWithHeader from '../../components/sidebar/sidebar';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';

export default function AddNotice(){
    return <AddNoticeForm />;
}

const AddNoticeForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const fetchData = () => {
        let init = {
            title: '',
            description: '',
            notice_from: '',
            notice_to: ''
        };

        return init;
    }

    const validataionSchema = Yup.object({
        title: Yup.string().required("Required"),
        description: Yup.string().required("Required")
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        axios
        .post(`${process.env.REACT_APP_API_URL}/api/notice`, values, config)
        .then((response) => {
            navigate('/notices');
        })
        .catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD NOTICE</Text>
            </Flex>
            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validataionSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='input' type='text' label='Title' name='title' />
                                <FormikControl required={true} control='textarea' type='text' label='Description' name='description' />
                                <FormikControl control='date' type='date' label='Start From' name='notice_from' />
                                <FormikControl control='date' type='date' label='Notice Display until' name='notice_until' />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>

        </>
    )
}
