import { Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'

export default function EmployeeResponsibilities({responsbilities}) {
    return (
        <TableContainer>
            <Table variant='striped'>
                <Thead>
                    <Tr>
                        <Th>Office</Th>
                        <Th>Designation</Th>
                        <Th>Start Date</Th>
                        <Th>End End</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {responsbilities.map((responsbility, index) => (
                        <Tr key={index}>
                            <Td>{responsbility.office}</Td>
                            <Td>{responsbility.designation}</Td>
                            <Td>{responsbility.start_date}</Td>
                            <Td>{responsbility.end_date ? responsbility.end_date : 'Continuing'}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}
