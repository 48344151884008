const { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } = require("@chakra-ui/react")

export default function ConfirmModal({reset, title=null, desc=null, action}) {
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const handleClose = () => {
        reset(false);
        onClose();
    }

    const handleAction = () => {
        action();
        handleClose();
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {title
                            ? title
                            : 'Confirm Delete'
                        }
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        {desc
                            ? desc
                            : 'Are you sure to delete this?'
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='gray' mr={3} onClick={handleClose}>
                            Close
                        </Button>
                        <Button colorScheme='red' onClick={handleAction}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
