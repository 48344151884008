import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function EditInstitute() {
    const [institute, setInstitute] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');
    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const {id: instituteId} = useParams();

    useEffect(() => {
        fetchInstitute();
    }, [instituteId]);

    const fetchInstitute = async () => {
        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/institute/${instituteId}`, {
                    headers: config
                })
            ).data;
            const result = apiResponse.results;
            setInstitute(result);
        } catch (err) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    const initialValues = () => {
        if(institute.id){
            return {
                institute: institute.institute,
                shortname: institute.shortname
            }
        }
    };

    const validationSchema = Yup.object({
        institute: Yup.string().max(256, 'Please enter within 256 characters').required('Institute is required'),
        shortname: Yup.string().max(256, 'Please enter within 256 characters').required('Shortname is required'),
    });

    const onSubmit = (values) => {
        axios
            .patch(`${process.env.REACT_APP_API_URL}/api/institute/${institute.id}`, values, {
                headers: config
            })
            .then((response) => {
                ToastMessage('success', 'Successfully Updated');
                navigate('/institutes');
            })
            .catch((error) => {
                ToastMessage('error','Something went wrong');
            });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">Edit INSTITUTE</Text>
            </Flex>
            {institute.id &&
                <div className={styles.apply_form}>
                    <Formik
                        initialValues={initialValues()}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {
                            formik => (
                                <Form>
                                    <FormikControl required={true} control='input' type='text' placeholder='Enter institute' label='Institute' name='institute' />
                                    <FormikControl required={true} control='input' type='text' placeholder='Enter shortname' label='Shortname' name='shortname' />

                                    <div>
                                        <Button disabled={isLoading} colorScheme="green" type="submit">Submit</Button>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            }
        </>
    )
}
