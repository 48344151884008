import { Formik, Form, ErrorMessage, FastField } from "formik";
import React from "react";
import * as Yup from "yup";
import TextError from "../common/TextError";
// @ts-ignore
import styles from "../../assets/css/contact-form.css";
import ToastMessage from "../utility/ToastMessage";
import axios from "axios";

const initialValues = {
  email: "",
  fullname: "",
  subject: "",
  body: "",
};

const onSubmit = (values, { resetForm }) => {
    let config = {
        headers: {
            'Accept': 'application/json'
        }
    }
    axios.post(`${process.env.REACT_APP_API_URL}/api/send_enquiry_mail`, values, config)
        .then((response) => {
            ToastMessage('success','Your Enquiry submitted successfully. Thank you.');
            resetForm();
        })
        .catch((error) => {
            ToastMessage('error','Something went wrong');
        });
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid Email Address").required("Email Address is required"),
  fullname: Yup.string().required("Fullname is required"),
  subject: Yup.string().required("Subject is required"),
  body: Yup.string().required("Body is required"),
});

function ContactForm() {
  return (
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
    >
      <Form className={"my-form"}>
        <div>
          <label htmlFor="email">Email</label>
          <FastField
            type="text"
            id="email"
            name="email"
          />
          <ErrorMessage name="email" component={TextError}/>
        </div>

        <div>
          <label htmlFor="fullname">FullName</label>
          <FastField
            type="text"
            id="fullname"
            name="fullname"
          />
          <ErrorMessage name="fullname" component={TextError} />
        </div>

        <div>
          <label htmlFor="subject">Subject</label>
          <FastField
            type="text"
            id="subject"
            name="subject"
          />
          <ErrorMessage name="subject" component={TextError}/>
        </div>

        <div>
          <label htmlFor="body">Body</label>
          <FastField
            as="textarea"
            id="body"
            name="body"
          />
          <ErrorMessage name="address" component={TextError}/>
        </div>

        <div>
          <button type="submit">Submit</button>
        </div>
      </Form>
    </Formik>
  );
}

export default ContactForm;
