import React from 'react';
import UserInfoStyle from '../../assets/css/modular/user-info.module.css';
import LabelValue from '../adminFormGrid/LabelValue';
import { Heading } from '@chakra-ui/react';
import Space from '../utility/Space';
import EmployeeEducations from '../employee/EmployeeEducations';
import EmployeeResponsibilities from '../employee/EmployeeResponsibilities';

const token = localStorage.getItem('my_token');

export default function EmployeeInfo({employeeInfo}) {
    const {employee_details, educations, other_responsibilites} = employeeInfo;
    return (
        <>
            <div className={UserInfoStyle.info}>
                <LabelValue index='Office' value={employee_details?.office}/>
                <LabelValue index='Employee Id' value={employee_details?.employee_id} />
                <LabelValue index='Designation' value={employee_details?.designation}/>
                <LabelValue index='Joining Date' value={employee_details?.joining_date}/>
            </div>

            {(educations && educations.length > 0) &&
                <div>
                    <Space />
                    <Heading as='h3' size='md' color={"teal"}>
                        Employee Educations
                    </Heading>
                    <div className={UserInfoStyle.custom_table}>
                        <EmployeeEducations educations={educations} />
                    </div>
                </div>
            }
            {(other_responsibilites && other_responsibilites.length > 0) &&
                <div>
                    <Space />
                    <Heading as='h3' size='md' color={"teal"}>
                        Other Responsibilities
                    </Heading>
                    <div className={UserInfoStyle.custom_table}>
                        <EmployeeResponsibilities responsbilities={other_responsibilites} />
                    </div>
                </div>
            }
        </>
    )
}
