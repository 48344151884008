// @ts-nocheck
import React, { useEffect, useState } from 'react';
import StudentStyle from '../../assets/css/modular/evening-course-student-id.module.css';
import Logo from '../../assets/images/assets/Logo.jpg';
import Banner from '../../assets/images/assets/banner.jpg';
import SampleImage from '../../assets/images/assets/sample_image.png';
import RegistrarSign from '../../assets/images/assets/registrar_sign.jpg';
import Barcode from 'react-barcode';
import axios from 'axios';
import { getNameFontSize } from '../common/CommonFunctions';
import ToastMessage from '../utility/ToastMessage';

export default function EveningCourseStudentIdCard({halls,eveningCourses,fullname,registration_no,blood_group,hall_id,evening_course_id,session,admission_year,batch,validity,finalThumb}){
    const [hall_list, setHallList] = useState(halls);
    const [signature, setSignature] = useState('');
    const [eveningCourselist, seteveningCourseList] = useState(eveningCourses);

    useEffect(() => {
        if(hall_list.length === 0){
            fetchOptionData();
        }
        if(eveningCourselist.length === 0) {
            fetchEveningCourses();
        }
        getSignature(evening_course_id);
    },[]);

    const fetchOptionData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-options`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setHallList(apiResponse.results.halls);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }
    }

    const fetchEveningCourses = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/evening_course`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                seteveningCourseList(apiResponse.results.evening_courses);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }
    }

    const getHall = (halls,hall_id) => {
        let hall = halls.filter((item) => {
            if(item.id == hall_id){
                return item;
            }
        })
        if(hall){
            return hall[0]['hall'];
        }
        return '';
    }

    const getEveningCourse = (evening_courses,evening_course_id) => {
        let evening_course = evening_courses.filter((item) => {
            if(item.id == evening_course_id){
                return item;
            }
        });
        return evening_course[0];
    }

    const getSignature = async (course_id) => {
        const config = {
            responseType: "blob",
            params: {
                course_id: course_id
            }
        };

        try {
            var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-signature-by-course`, config));
            if (response.status == 200) {
                const data = response.data;
                const blob = new Blob([data], { type: data.type });
                const url = URL.createObjectURL(blob);
                setSignature(url);
            }
        } catch ({response}) {
            if(response?.status == 404) {
                ToastMessage('warning', 'Signature not found');
            }else{
                ToastMessage('error','Something went wrong');
            }
        }
    }

    return(
        <>
            {eveningCourselist.length>0 && hall_list.length>0 &&
                <div className={StudentStyle.idCardBody}>
                    <div className={StudentStyle.leftPart}>
                        <p>{getEveningCourse(eveningCourselist,evening_course_id)?.course_title}</p>
                    </div>
                    <div className={StudentStyle.rightPart}>
                        <div className={StudentStyle.bannerPart}>
                            <img src={Logo} className={StudentStyle.logo} />
                            <img src={Banner} className={StudentStyle.banner} />
                        </div>

                        <div className={StudentStyle.studentImg}>
                            <img src={ finalThumb ? finalThumb : SampleImage } className={StudentStyle.image} />
                        </div>
                        <div className={StudentStyle.naming}>
                            <p className={StudentStyle.fullname} style={{fontSize: getNameFontSize(fullname)}}>{fullname}</p>
                        </div>

                        <div className={StudentStyle.info}>
                            <div className={StudentStyle.left}>
                                <div className={StudentStyle.items}>
                                    <p>Student ID Number: </p>
                                    <b className={StudentStyle.value}>{registration_no}</b>
                                </div>
                                <div className={StudentStyle.items}>
                                    <p>Blood Group: </p>
                                    <b className={StudentStyle.value}>{blood_group}</b>
                                </div>
                            </div>
                            <div className={StudentStyle.right}>
                                <div className={StudentStyle.rightInfoPart}>
                                    <div className={StudentStyle.items}>
                                        <p>Session: </p>
                                        <b className={StudentStyle.value}>
                                            {session} {admission_year}
                                        </b>
                                    </div>
                                    <div className={StudentStyle.items}>
                                        <p>Validity: </p>
                                        <b className={StudentStyle.value}>
                                            {validity}
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={StudentStyle.courseName}>
                            <div className={StudentStyle.items}>
                                <p>Hall: </p>
                                <b className={StudentStyle.value}>{getHall(hall_list,hall_id)}</b>
                            </div>
                        </div>

                        <div className={StudentStyle.barcodeSign} style={{display: 'flex'}}>
                            <div className={StudentStyle.barcode}>
                                <Barcode value={registration_no} displayValue={false} width={40} height={1100}/>
                            </div>
                            <div className={StudentStyle.img_part} style={{marginTop: '10px'}}>
                                    <div id="footerDivContent">
                                        <div className={StudentStyle.footerContent}>
                                            <img src={signature} className={StudentStyle.signature} />
                                        </div>
                                        <p className={StudentStyle.registrar}>Director</p>
                                    </div>
                                </div>
                        </div>
                        {/* <div className={StudentStyle.barcode}>
                            <Barcode value={registration_no} displayValue={false} />
                        </div> */}
                    </div>
                </div>
            }
        </>
    )
}
