import { ChakraProvider, extendTheme, Heading } from '@chakra-ui/react';
import React from 'react';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import ContactForm from '../../components/landing/ContactForm';
import "../../assets/css/contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
})

export default function Contact(){
    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            CONTACT US
                        </Heading>

                        <div className="row contact">
                            <div className="column">
                                <div className="card">
                                    <FontAwesomeIcon icon={faEnvelope} size="4x" />
                                    <div className='desc'>
                                        <Heading className='header-text' size='md'>
                                            Email Us
                                        </Heading>
                                        <p className='contact-desc'>Email Us in the following mail address. We will try to respond to your email as soon as possible</p>
                                        <Heading className='header-text' size='sm'>
                                            Mail to : <Link to={"#"}>query@juniv.edu</Link>
                                        </Heading>
                                    </div>
                                </div>
                            </div>

                            <div className="column">
                                <div className="card">
                                    <FontAwesomeIcon icon={faInfo} size="4x" />
                                    <div className='desc'>
                                        <Heading className='header-text' size='md'>
                                            Information Desk
                                        </Heading>
                                        <p className='contact-desc'>
                                            Mostly you will find all of your required information in the website. If you want to know anything more you can visit the library office on office time.
                                        </p>
                                        <Heading className='header-text' size='sm'>
                                            Browse : <Link to={"/"}>JUPIS</Link>
                                        </Heading>
                                    </div>
                                </div>
                            </div>

                            <div className="column">
                                <div className="card">
                                    <FontAwesomeIcon icon={faQuestion} size="4x" />
                                    <div className='desc'>
                                        <Heading className='header-text' size='md'>
                                            FAQ
                                        </Heading>
                                        <p className='contact-desc'>We already answered some frequently asked questions from the users. You may have a look there and check whether you get your answer already</p>
                                        <Heading className='header-text' size='sm'>
                                            FAQ : <Link to={"#"}>query@juniv.edu</Link>
                                        </Heading>
                                    </div>
                                </div>
                            </div>

                            <div className="column">
                                <div className="card">
                                    <FontAwesomeIcon icon={faEnvelope} size="4x" />
                                    <div className='desc'>
                                        <Heading className='header-text' size='md'>
                                            Send your query
                                        </Heading>
                                        <p className='contact-desc'>You can also send your query directly to us through the form bellow. The query will be sent to the mail address of the administration and we will reply you as soon as possible</p>
                                        <Heading className='header-text' size='sm'>
                                            Mail to : <Link to={"#"}>query@juniv.edu</Link>
                                        </Heading>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='white-contents'>
                        <div className='contact-form'>
                            <Heading className='header-text' size='md'>
                                SEND US YOUR QUERY
                            </Heading>
                            <div className='form-body'>
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
