import React, { useContext } from 'react';
import './sidebar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  FiHome,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiBook
} from 'react-icons/fi';
import { MdDashboard, MdAdminPanelSettings, MdBatchPrediction } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { SiAdobeindesign } from "react-icons/si";
import { FcDepartment } from "react-icons/fc";
import { FaNewspaper, FaUserGraduate, FaFileCsv } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { toLower } from 'lodash';
import { RiHomeSmileLine, RiAdminFill } from "react-icons/ri";
import axios from 'axios';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ToastContainer } from 'react-toastify';
import { getType, handleLogout } from '../common/CommonFunctions';
import { AdminContext } from '../../pages/dashboard/AdminLayout';

const LinkItems = [
  { name: 'Home', icon: FiHome, pathname: '/dashboard' },
  { name: 'Registered Users', icon: FiHome, pathname: '/registered/only' },
  { name: 'Import Data', icon: 'FaFileCsv', pathname: '#', nested:[
    { name: 'View Data', pathname: '/csv-student-data' },
    { name: 'Add Data', pathname: '/add-csv-student-data' },
  ] },
  { name: 'Applications', icon: 'FaUserGraduate', pathname: '#', nested:[
    { name: 'Teachers', pathname: '/applications/teachers' },
    { name: 'Hons Masters Students', pathname: '/applications/hons-masters-students' },
    { name: 'Mphil Phd Students', pathname: '/applications/mphil-phd-students' },
    { name: 'Evening Course Students', pathname: '/applications/evening-course-students' },
    { name: 'Officers', pathname: '/applications/officers' },
    { name: 'Staffs', pathname: '/applications/staffs' },
  ] },
  { name: 'Students', icon: 'FaUserGraduate', pathname: '#', nested:[
    { name: 'Hons Masters', pathname: '/hons-masters-students' },
    { name: 'Mphil Phd', pathname: '/mphil-phd-students' },
    { name: 'Evening Course', pathname: '/evening-course-students' },
  ] },
  { name: 'Employees', icon: 'RiAdminFill', pathname: '#', nested:[
    { name: 'Teachers', pathname: '/employees/teachers' },
    { name: 'Officers', pathname: '/employees/officers' },
    { name: 'Staffs', pathname: '/employees/staffs' },
  ] },
  { name: 'Halls', icon: IoHome, pathname: '/halls' },
  { name: 'Faculties', icon: RiHomeSmileLine, pathname: '/faculties' },
  { name: 'Institutes', icon: RiHomeSmileLine, pathname: '/institutes' },
  { name: 'Batches', icon: MdBatchPrediction, pathname: '/batches' },
  { name: 'Departments', icon: FcDepartment, pathname: '/departments' },
  { name: 'Admins', icon: MdAdminPanelSettings, pathname: '/admins' },
  { name: 'Designations', icon: SiAdobeindesign, pathname: '/designations' },
  { name: 'Offices', icon: ImOffice, pathname: '/offices' },
  { name: 'Notices', icon: FaNewspaper, pathname: '/notices' },
  { name: 'Evening Courses', icon: FiBook, pathname: '/evening-courses' },
];

export default function SidebarWithHeader() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userDetails = useContext(AdminContext);

  return (
    <>
        <SidebarContent
            onClose={() => onClose}
            display={{ base: 'none', md: 'block' }}
        />

        <ToastContainer />

        <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full">
            <DrawerContent>
                <SidebarContent onClose={onClose} />
            </DrawerContent>
        </Drawer>

        <MobileNav onOpen={onOpen} userDetails={userDetails} />
    </>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  var { pathname } = useLocation();
  pathname = pathname.replace(/\//g, '');
  const isActive = (linkitem) => {
    if (linkitem && pathname) {
      return (toLower(linkitem) === pathname);
    }
    return false;
  }
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('#3C4B64', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: "16.5rem" }}
      pos="fixed"
      h="full"
      style={{ top: 0, bottom: 0, overflowY: "scroll" }}
      {...rest}>
      <Flex h="20" alignItems="center" px="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold" color={'#DCDCDC'}>
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={MdDashboard}
          />
          Dashboard
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        !('nested' in link) ?
            <NavItem key={link.name} icon={link.icon} color={'#DCDCDC'} fontSize={'lg'} linkName={toLower(link.pathname)} active={isActive(link.name)}>
                {link.name}
            </NavItem>
            :
            <div key={link.name} className='sidebar_link'>
                {link.icon == 'FaFileCsv'
                    ? <FaFileCsv />
                    : link.icon == 'RiAdminFill'
                        ?  <RiAdminFill />
                        :  <FaUserGraduate />
                }
                <NestedItem name={link.name} nested={link.nested}/>
            </div>
       ))}
    </Box>

  );
};

const NestedItem = ({name, nested}) => {
    return (
        <>
            {name}
            {nested &&
                <Menu>
                    <MenuButton righticon={<ChevronDownIcon />}>
                        <ChevronDownIcon />
                    </MenuButton>

                    <MenuList>
                        {nested.map((link, index) => (
                            <MenuItem key={index} color={"#000"}><Link to={link.pathname}>{link.name}</Link></MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            }
        </>
    )
}

const NavItem = ({ icon, children, linkName, active, ...rest }) => {
  return (
    <Link to={`${linkName}`} style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: '#46546C',
          color: 'white',
        }}
        bg={active ? '#46546C' : ''}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, userDetails, ...rest }) => {
    const navigate = useNavigate();

    const handleAdminProfile = () => {
        navigate('/admin-profile');
    }

    const handlePasswordChange = () => {
        navigate('/password_change_admin');
    }

    return (
        <Flex
            ml={{ base: 0, md: "16.5rem" }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />
            }
        />

        <Text
            display={{ base: 'flex', md: 'none' }}
            fontSize="2xl"
            fontFamily="monospace"
            fontWeight="bold"
        >
            JUPIS
        </Text>

        <HStack spacing={{ base: '0', md: '6' }}>
            <IconButton
                size="lg"
                variant="ghost"
                aria-label="open menu"
                icon={<FiBell />}
            />
            <Flex alignItems={'center'}>
                <Menu>
                    <MenuButton
                        py={2}
                        transition="all 0.3s"
                        _focus={{ boxShadow: 'none' }}>
                        <HStack>
                            <Avatar
                            size={'sm'}
                            src={
                                ''
                            }
                            />
                            <VStack
                                display={{ base: 'none', md: 'flex' }}
                                alignItems="flex-start"
                                spacing="1px"
                                ml="2"
                            >
                                <Text fontSize="sm">{userDetails?.fullname}</Text>
                                <Text fontSize="xs" color="gray.600">
                                    {getType(userDetails?.user_type)}
                                </Text>
                            </VStack>
                            <Box display={{ base: 'none', md: 'flex' }}>
                                <FiChevronDown />
                            </Box>
                        </HStack>
                    </MenuButton>

                    <MenuList
                        bg={useColorModeValue('white', 'gray.900')}
                        borderColor={useColorModeValue('gray.200', 'gray.700')}
                    >
                        <MenuItem onClick={handleAdminProfile}>Profile</MenuItem>
                        <MenuItem onClick={handlePasswordChange}>Password Change</MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={() => handleLogout(navigate)}>Logout</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </HStack>
        </Flex>
    );
};
