// @ts-nocheck
import React,{useEffect, useState} from 'react';
import { ChakraProvider, extendTheme, Heading, Button } from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import {Formik, Form} from 'formik';
// @ts-ignore
import styles from "../../assets/css/modular/apply-form.module.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import ToastMessage from '../../components/utility/ToastMessage';
import axios from 'axios';
import { getValidityBySession } from '../../components/common/CommonFunctions';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
});

export default function RegisterMphilPhdStudentData(){
    const [halls, setHalls] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [institutes, setInstitutes] = useState([]);

    const navigate = useNavigate();
    let application_data = localStorage.getItem('application_data');
    let is_checked = true;
    let is_first_step = true;
    let is_second_step = true;

    if(!application_data){
        is_checked = false;
    }else{
        application_data = JSON.parse(application_data);
        if(!application_data.declaration || application_data.declaration !== true){
            is_checked = false;
        }
        if(!application_data.declaration || application_data.first_step !== true){
            is_first_step = false;
        }
        if(!application_data.declaration || application_data.second_step !== true){
            is_second_step = false;
        }
    }

    useEffect(() => {
        fetchOptionData();
        if(is_checked == false){
            ToastMessage("warning","Please check the declaration first");
            navigate('/apply-for-id');
        } else if(is_first_step == false){
            ToastMessage("warning","Please complete first step first");
            navigate('/apply-first-step');
        } else if(is_second_step == false){
            ToastMessage("warning","Please complete previous step first");
            navigate('/apply-second-step');
        }
    }, [is_checked, is_first_step, is_second_step]);

    const fetchData = () => {
        let initialValues = {
            hall_id: '',
            registration_no: '',
            apply_for: '', //mphil_or_masters
            dept_or_institute: '',
            dept_id: '',
            institute_id: '',
            session: '',
            type: ''
        };

        initialValues.hall_id = application_data?.hall_id;
        initialValues.registration_no = application_data?.user_id;
        initialValues.apply_for = application_data?.apply_for;
        initialValues.dept_or_institute = application_data?.dept_or_institute;
        initialValues.department_id = application_data?.department_id;
        initialValues.institute_id = application_data?.institute_id;
        initialValues.session = application_data?.session;
        initialValues.type = application_data?.type;

        return initialValues;
    }

    const fetchOptionData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-options`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setDepartments(apiResponse.results.departments);
                setHalls(apiResponse.results.halls);
                setInstitutes(apiResponse.results.institutes);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }
    }

    const validataionSchema = Yup.object({
        hall_id: Yup.string().required("Required"),
        registration_no: Yup.string().required("Required"),
        dept_or_institute: Yup.string().required("Required"),
        apply_for: Yup.string().required("Required"),
        department_id: Yup.string().when("dept_or_institute",{
            is: '0',
            then: Yup.string().required("Required")
        }),
        institute_id: Yup.string().when("dept_or_institute",{
            is: '1',
            then: Yup.string().required("Required")
        }),
        session: Yup.string().matches(/^\d{4}-\d{2}$/, 'Invalid pattern').required("Required"),
        type: Yup.string().required("Required")
    });

    const onSubmit = values => {
        application_data.hall_id = values.hall_id;
        application_data.registration_no = values.registration_no;
        application_data.dept_or_institute = values.dept_or_institute;
        application_data.department_id = values.department_id;
        application_data.institute_id = values.institute_id;
        application_data.apply_for = values.apply_for;
        application_data.session = values.session;
        application_data.type = values.type;
        application_data.validity = getValidityBySession(values.session, values.apply_for)
        application_data.third_step = true;
        localStorage.setItem('application_data',JSON.stringify(application_data));

        navigate('/image-upload');
    };

    const getHallOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].hall
                });
            }
        }
        return return_data;
    }

    const dept_or_institute_options = [
        {key: 0, value: "Department"},
        {key: 1, value: "Institute"},
    ];

    const apply_for_options = [
        {key: 0, value: "MPhill Id Card"},
        {key: 1, value: "Phd Id Card"},
    ];

    const type_options = [
        {key: 0, value: "Regular"},
        {key: 1, value: "Irregular"},
    ];

    const getDepartmentOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].department
                });
            }
        }
        return return_data;
    }

    const getInsituteOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].institute
                });
            }
        }
        return return_data;
    }

    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            Apply for Id card : 3rd Step (Academic Information)
                        </Heading>
                        <br />

                        <div className={styles.apply_form}>
                            <Formik
                                initialValues={fetchData()}
                                validationSchema={validataionSchema}
                                onSubmit={onSubmit}
                            >
                                {
                                    formik => (
                                        <Form>
                                            <FormikControl required={true} control='select' label='Hall' name='hall_id' options={getHallOptions(halls)} />
                                            <FormikControl required={true} control='input' type='text' label='Registration No' name='registration_no' disabled />
                                            <FormikControl required={true} control='radio' label='Department / Institute' name='dept_or_institute' options={dept_or_institute_options} />
                                            {formik.values.dept_or_institute &&
                                                formik.values.dept_or_institute == 0
                                                    ? <FormikControl required={true} control='select' label='Department' name='department_id' options={getDepartmentOptions(departments)} />
                                                    : formik.values.dept_or_institute == 1
                                                        ? <FormikControl required={true} control='select' label='Institute' name='institute_id' options={getInsituteOptions(institutes)} />
                                                        : <></>
                                            }
                                            <FormikControl required={true} control='radio' label='Apply For' name='apply_for' options={apply_for_options} />
                                            <FormikControl required={true} control='input' type='text' label='Session' name='session' />
                                            <FormikControl required={true} control='radio' label='Type' name='type' options={type_options} />
                                            <div className={styles.neighbor_buttons}>
                                                <Link to='/apply-second-step'><Button colorScheme="blue" type="button">Prev</Button></Link>
                                                <Button colorScheme="green" type="submit">Next</Button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
