import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


export default function AdminProfile(){
    return <AdminProfileData />;
}

const AdminProfileData = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const [admin, setAdmin] = useState({});

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADMIN PROFILE</Text>
            </Flex>
            Admin Profile Under Construction
        </>
    )
}
