import { Button, Flex, Image, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import { getDeptVal, getInstVal } from '../../components/common/CommonFunctions';
import TextError from '../../components/common/TextError';
import customStyle from '../../assets/css/modular/custom-form.module.css';

export default function EditEveningCourse(){
    return<EditEveningCourseForm />;
}

const EditEveningCourseForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');
    const id = useParams().id;
    const [eveningCourse, setEveningCourse] = useState({});
    const [offices, setOffices] = useState([]);
    const [doChange, setDoChange] = useState(false);
    const [signatureFile, setSignatureFile] = useState('');

    let config = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
    };

    useEffect(() => {
        fetchEveningCourse();
    },[]);

    const fetchEveningCourse = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/evening_course/${id}`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                const {id, course_title, course_title_short, course_duration, department_id, institute_id, signature} = apiResponse.results;

                let office_id = null;
                if(department_id) {
                    office_id = department_id+'#department';
                }else {
                    office_id = institute_id+'#institute';
                }
                let init = {
                    id: id,
                    course_title: course_title,
                    course_title_short: course_title_short,
                    office_id: office_id,
                    course_duration: course_duration,
                    signature: signature
                };
                setEveningCourse(init);
            }
        } catch (error) {
            ToastMessage("error","Something went wrong");
        }
    }

    useEffect(() => {
        fetchOfficeData();
    }, []);

    const fetchOfficeData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-dept-institutes`, config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setOffices(apiResponse.results);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const getOfficeOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id+'#'+options_data[i].type,
                    value: options_data[i].name
                });
            }
        }
        return return_data;
    }

    const validataionSchema = Yup.object({
        course_title: Yup.string().required("Required").max(256, 'must be at most 256 characters'),
        course_title_short: Yup.string().required("Required").max(10, 'must be at most 10 characters'),
        office_id: Yup.string().required("Required"),
        course_duration: Yup.string().required("Required").max(2)
    });

    const onSubmit = (values) => {
        values.department_id = getDeptVal(values.office_id);
        values.institute_id = getInstVal(values.office_id);

        if(doChange && !signatureFile) {
            alert('Please select signature');
            return; 
        }

        let form_data = new FormData();
        form_data.append('_method', 'patch');
        
        for(let key in values){
            form_data.append(key,values[key]);
        }

        if(doChange) {
            form_data.append('signatureFile', signatureFile);
        }

        let configMultipart = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        }

        axios
        .post(`${process.env.REACT_APP_API_URL}/api/evening_course/${id}`, form_data, configMultipart)
        .then((response) => {
            ToastMessage("success","Evening Course has been updated successfully");
            navigate('/evening-courses');
        })
        .catch(({response}) => {
            let message = "Something went wrong";
            const {status} = response;
            if(status === 422) {
                message = 'Validation Error';
            }
            ToastMessage("error",message);
        });
    };


    function checkEmpty(param){
        for(let i in param){
            return false;
        }
        return true;
    }

    return (
        <>
            <Flex justifyContent={'center'} className="notice_title">
                <Text fontSize="xl">EDIT EVENING COURSE</Text>
            </Flex>
            {checkEmpty(eveningCourse) === false &&
                <div className={styles.apply_form}>
                    <Formik
                        initialValues={eveningCourse}
                        validationSchema={validataionSchema}
                        onSubmit={onSubmit}
                    >
                        {
                            formik => (
                                <Form>
                                    <FormikControl required={true} placeholder={'i.e. Professional Masters in IT'} control='input' type='text' label='Course Title' name='course_title' />
                                    <FormikControl required={true} placeholder={'i.e. PMIT'} control='input' type='text' label='Short Title' name='course_title_short' />
                                    <FormikControl required={true} placeholder={'Please select office'} control='select' label='Department / Institute' name='office_id' options={getOfficeOptions(offices)} />
                                    <FormikControl required={true} placeholder={'i.e. 12'} control='input' type='number' label='Course Duration (months)' name='course_duration' />
                                    <div className={`form-control ${customStyle.custom_form}`}>
                                        <Flex gap={2}>
                                            <label className={customStyle.custom_label} htmlFor={'file'}>{'Signature'} <span className='required'>*</span> </label>
                                            <Button size={'xs'} colorScheme="orange" type="button" onClick={() => setDoChange((doChange) => !doChange)}>{doChange ? 'Do not Change' : 'Change'}</Button>
                                        </Flex>
                                        {doChange 
                                            ?  
                                                <>
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        onChange={(e) => {
                                                            setSignatureFile(e.target.files[0]);
                                                        }}
                                                    />
                                                    <ErrorMessage name={'file'} component={TextError} />
                                                </>
                                            : 
                                                <Image
                                                    width={210}
                                                    height={75}
                                                    src={eveningCourse.signature}
                                                    alt={eveningCourse.course_title}
                                                />
                                        }
                                    </div>
                                    <div>
                                        <Button colorScheme="green" type="submit">Update</Button>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            }
        </>
    )
}
