import React, { useEffect, useState } from 'react';
import EmployeeStyle from '../../assets/css/modular/employee-id.module.css';
import Logo from '../../assets/images/assets/Logo.jpg';
import Banner from '../../assets/images/assets/banner.jpg';
import SampleImage from '../../assets/images/assets/sample_image.png';
import RegistrarSign from '../../assets/images/assets/registrar_sign.jpg';
import QRCode from 'react-qr-code';
import { getNameFontSize } from '../common/CommonFunctions';
import axios from 'axios';
import ToastMessage from '../utility/ToastMessage';

export default function EmployeeIdCardWithValue({office, thumb, fullname, employee_id, nid, blood_group, designation, dob, showHeader}) {
    const [finalThumb, setFinalThumb] = useState('');
    const [registrarSign, setRegistrarSign] = useState('');

    useEffect(() => {
        setFinalThumb(thumb);
    }, [thumb]); 

    useEffect(() => {
        getRegistrarSign();
    }, []);

    const token = localStorage.getItem('my_token');
    const getRegistrarSign = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob",
            params: {
                filename: 'images/signatures/registrar_sign.jpeg'
            }
        };

        try {
            var response = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-image-from-path`, config));
            if (response.status == 200) {
                const data = response.data;
                const blob = new Blob([data], { type: data.type });
                const url = URL.createObjectURL(blob);
                setRegistrarSign(url);
            }
        } catch ({response}) {
            if(response?.status == 404) {
                ToastMessage('warning', 'Image not found');
            }else{
                ToastMessage('error','Something went wrong');
            }
        }
    }

    return (
        <div className={EmployeeStyle.idCardBody}>
            <div className={EmployeeStyle.leftPart}>
                <p>{office}</p>
            </div>
            <div className={EmployeeStyle.rightPart}>
                <div className={EmployeeStyle.bannerPart}>
                    {showHeader &&
                        <>
                            <img src={Logo} alt='logo' className={EmployeeStyle.logo} />
                            <img src={Banner} alt='banner' className={EmployeeStyle.banner} />
                        </>
                    }
                </div>

                <div className={EmployeeStyle.teacherImg}>
                    <img src={finalThumb ? finalThumb : SampleImage} alt='final' className={EmployeeStyle.image} />
                </div>
                <div className={EmployeeStyle.naming}>
                    <p className={EmployeeStyle.fullname} style={{fontSize: getNameFontSize(fullname)}}>{fullname}</p>
                </div>

                <div className={EmployeeStyle.info}>
                    <div className={EmployeeStyle.left}>
                        <div className={EmployeeStyle.items}>
                            <p>ID Number: </p>
                            <b className={EmployeeStyle.value}>{employee_id}</b>
                        </div>
                        <div className={EmployeeStyle.items}>
                            <p>NID Number: </p>
                            <b className={EmployeeStyle.value}>{nid}</b>
                        </div>
                        <div className={EmployeeStyle.items}>
                            <p>Blood Group: </p>
                            <b className={EmployeeStyle.value}>{blood_group}</b>
                        </div>
                        <div id="footerDivContent">
                            <div className={EmployeeStyle.footerContent}>
                                <img src={registrarSign} alt='registrar' className={EmployeeStyle.signature} />
                            </div>
                            <p className={EmployeeStyle.registrar}>Registrar</p>
                        </div>
                    </div>
                    <div className={EmployeeStyle.right}>
                        <div className={EmployeeStyle.rightInfoPart}>
                            <div className={EmployeeStyle.items}>
                                <p>Designation: </p>
                                <b className={EmployeeStyle.value}>
                                    {designation}
                                </b>
                            </div>
                            <div className={EmployeeStyle.items}>
                                <p>Date of Birth: </p>
                                <b className={EmployeeStyle.value}>
                                    {dob}
                                </b>
                            </div>
                        </div>
                        <div className={EmployeeStyle.img_part}>
                            {/* <img src="images/qr.png" height="75px" width="90px" class="code" /> */}
                            <div style={{ height: "75px", margin: "0 auto", maxWidth: "65px", width: "100%" }}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={employee_id}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
