import React, { createContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import SidebarWithHeader from '../../components/sidebar/sidebar'
import { Box, useColorModeValue } from '@chakra-ui/react'
import CustomSpinner from '../../components/utility/CustomSpinner';
import axios from 'axios';

const AdminContext = createContext(null);

const AdminLayout = () => {
    const [userData, setUserData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const token = localStorage.getItem('my_token');

    const navigate = useNavigate();

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    useEffect(() => {
        fetchUserDetails();
    }, [token]);

    const fetchUserDetails = async () => {
        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/user_details`, {
                    headers: config
                })
            ).data;

            if (apiResponse.error === false && apiResponse.results) {
                const { user_type } = apiResponse.results?.userDetails;
                if(['1','2'].includes(user_type) == false) {
                    navigate('/login');
                }
                setUserData(apiResponse?.results?.userDetails);
            }
        } catch (error) {
            setUserData({});
        }

        setIsLoaded(true);
    }

    return (
        <>
            <Box
                minH="100vh"
                bg={useColorModeValue('gray.100', 'gray.900')}
            >
                {isLoaded
                    ?
                        <>
                            {userData?.id
                                ?
                                    <>
                                        <AdminContext.Provider value={userData}>
                                            <SidebarWithHeader />
                                            <Box ml={{ base: 0, md: "16.5rem" }} p="4">
                                                <Outlet />
                                            </Box>
                                        </AdminContext.Provider>
                                    </>
                                :
                                    <Navigate to={'/login'} />
                            }
                        </>
                    :
                        <CustomSpinner />
                }
            </Box>
        </>
    )
}

export {AdminContext, AdminLayout}
