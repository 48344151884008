import { Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'

export default function EmployeeEducations({educations}) {
    return (
        <TableContainer>
            <Table variant='striped'>
                <Thead>
                    <Tr>
                        <Th>Major</Th>
                        <Th>Institutte</Th>
                        <Th>Start Year</Th>
                        <Th>End Year</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {educations.map((education, index) => (
                        <Tr key={index}>
                            <Td>{education.major}</Td>
                            <Td>{education.institute}</Td>
                            <Td>{education.start_year}</Td>
                            <Td>{education.is_current == '2' ? education.end_year : 'Continuing'}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}
