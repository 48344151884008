// @ts-nocheck
import { Box, Button, Center, Grid, GridItem, Image } from "@chakra-ui/react";
import React from "react";
import banner from "../../assets/demo-card.jpg";
import "../../assets/css/intro.css";
import { Link } from "react-router-dom";

export default function Intro(){
    return(
        <div className="intro-container">
            <div className="message-div">
            <div id="text-container">
                <div id="flip">
                    <div><div>REGISTER FOR </div></div>
                    <div><div>REISSUE</div></div>
                    <div><div>TRACK</div></div>
                </div>
                YOUR ID CARD!
                </div>
            </div>
            <div className="text-div">
                <p>Get your Id card ready today. It is really simple</p>
                <p>Let's EXPLORE!!</p>
            </div>
            <div className="button-div">
                <Link to={'/apply-for-id'}>
                    <Button
                        bg="#262162"
                        color="white"
                        variant="outline"
                        _hover={{ bg: "white.700", borderColor: "rgb(166, 253, 4)" }}
                        >
                        Apply Now
                    </Button>
                </Link>
            </div>
            <div className="image-div">
                <img className="responsive" src={banner} alt="example id card"></img>
            </div>
        </div>
    )
}
