import React, { useEffect, useState } from 'react'
import SidebarWithHeader from '../../components/sidebar/sidebar';
import { Box, Button, Flex, Text, Input, Table, Thead, Tr, Th, Tbody, ButtonGroup, IconButton, Td } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import { ceil } from 'lodash';

export default function StudentData() {
    return <DataList />;
}

const DataList = () => {
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [filterName, setFilterName] = useState('');
    const [filterBatch, setFilterBatch] = useState('');
    const [filterRegistrationNo, setFilterRegistrationNo] = useState('');
    const [filterHall, setFilterHall] = useState('');
    const [filterDepartment, setFilterDepartment] = useState('');
    const [filterHonsClassRoll, setFilterHonsClassRoll] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState(1);
    const per_page = 20;

    useEffect(() => {
        fetchStudents();
    },[currentPage]);

    const token = localStorage.getItem('my_token');

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const fetchStudents = async () => {
        if(isLoading === false){
            setIsLoading(true);
        }

        try {
            let params = {
                batch: filterBatch,
                registration_no: filterRegistrationNo,
                hons_class_roll: filterHonsClassRoll,
                hall: filterHall,
                department: filterDepartment,
                fullname: filterName,
                per_page,
                page: currentPage
            };

            let apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-csv-data`, {
                params: params,
                headers: config
            })).data;

            if (apiResponse.error === false && apiResponse.results) {
                setStudents(apiResponse.results?.students);
                setTotal(apiResponse.results?.count);
                let total_pages = ceil(apiResponse.results?.count / per_page);
                setTotalPages(total_pages);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    const navigate = useNavigate();

    const handleSearch = () => {
        setCurrentPage(1);
        fetchStudents();
    }

    const handleClear = () => {
        setFilterName('');
        setFilterBatch('');
        setFilterRegistrationNo('');
        setFilterHall('');
        setFilterDepartment('');
        setFilterHonsClassRoll('');
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>Batch</Th>
                        <Th textAlign={'center'}>Registration No</Th>
                        <Th textAlign={'center'}>Fullname</Th>
                        <Th textAlign={'center'}>Class Roll</Th>
                        <Th textAlign={'center'}>Department</Th>
                        <Th textAlign={'center'}>Hall</Th>
                        <Th textAlign={'center'}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {students.length > 0 &&
                        students.map((student, idx) => (
                            <Tr
                                key={idx}
                                display={{
                                    base: 'grid',
                                    md: 'table-row',
                                }}
                                sx={{
                                    '@media print': {
                                    display: 'table-row',
                                    },
                                    gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                    gridGap: '10px',
                                }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {student.batch}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {student.registration_no}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {student.fullname}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {student.hons_class_roll}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {student.department}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {student.hall}
                                </Td>
                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEdit />}
                                            //onClick={() => handleEdit(notice.id)}
                                        />
                                        <IconButton
                                            colorScheme="red"
                                            variant="outline"
                                            aria-label="delete"
                                            icon={<BsFillTrashFill />}
                                            //onClick={() => handleDelete(notice.id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        )
                        )
                    }
                </Tbody>
            </Table>
    )
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">Student CSV data</Text>
            </Flex>

            <Flex justifyContent={'center'} w={{ base: '100%', md: '100%', sm: 'full' }} className="admin_add_button">
                <Box>
                    <Button
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        variant={'solid'}
                        size="sm"
                        onClick={() => navigate('/add-csv-student-data')}
                    >
                        Add Data
                    </Button>
                </Box>
            </Flex>

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Name' size='md' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                    <Input placeholder='Batch' size='md' value={filterBatch} onChange={(e) => setFilterBatch(e.target.value)} />
                    <Input placeholder='Registration No' size='md' value={filterRegistrationNo} onChange={(e) => setFilterRegistrationNo(e.target.value)} />
                    <Input placeholder='Hall' size='md' value={filterHall} onChange={(e) => setFilterHall(e.target.value)} />
                    <Input placeholder='Department' size='md' value={filterDepartment} onChange={(e) => setFilterDepartment(e.target.value)} />
                    <Input placeholder='Class Roll' size='md' value={filterHonsClassRoll} onChange={(e) => setFilterHonsClassRoll(e.target.value)} />

                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
                className="admin_table"
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>

            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}
