import React from 'react';
import UserInfoStyle from '../../assets/css/modular/user-info.module.css';
import LabelValue from '../adminFormGrid/LabelValue';

const token = localStorage.getItem('my_token');

export default function StudentInfo({studentInfo}) {
    const {hons_masters_details, mphil_phd_details, evening_course_student_details} = studentInfo;
    return (
        <>
            {hons_masters_details &&
                <div className={UserInfoStyle.info}>
                    <LabelValue index='Registration No' value={hons_masters_details?.registration_no}/>
                    <LabelValue index='Batch' value={hons_masters_details?.batch}/>
                    <LabelValue index='Validity' value={hons_masters_details?.validity}/>
                    <LabelValue index='Hall' value={hons_masters_details?.hall}/>
                    {hons_masters_details?.dept_or_inst == '0'
                        ? <LabelValue index='Department' value={hons_masters_details?.department}/>
                        : <LabelValue index='Institute' value={hons_masters_details?.institute}/>
                    }
                    {hons_masters_details?.hons_or_masters == '0'
                        ? <LabelValue index='Class' value={'Honors'}/>
                        : <LabelValue index='Class' value={'Masters'}/>
                    }
                    <LabelValue index='Hons Class Roll' value={hons_masters_details?.hons_class_roll}/>
                    <LabelValue index='Hons Exam Roll' value={hons_masters_details?.hons_exam_roll}/>
                    <LabelValue index='Masters Class Roll' value={hons_masters_details?.masters_class_roll}/>
                    <LabelValue index='Masters Exam Roll' value={hons_masters_details?.masters_exam_roll}/>
                </div>
            }

            {mphil_phd_details && 
                <div className={UserInfoStyle.info}>
                    <LabelValue index='Registration No' value={mphil_phd_details?.registration_no}/>
                    <LabelValue index='Session' value={mphil_phd_details?.session}/>
                    <LabelValue index='Hall' value={mphil_phd_details?.hall}/>
                    {mphil_phd_details?.dept_or_inst == '0'
                        ? <LabelValue index='Department' value={mphil_phd_details?.department}/>
                        : <LabelValue index='Institute' value={mphil_phd_details?.institute}/>
                    }
                    {mphil_phd_details?.mphill_or_phd == '0'
                        ? <LabelValue index='Class' value={'Mphil'}/>
                        : <LabelValue index='Class' value={'Phd'}/>
                    }
                    <LabelValue index='Type' value={mphil_phd_details?.type == '0' ? 'Regular' : 'Irregular'}/>
                    <LabelValue index='Validity' value={mphil_phd_details?.validity}/>
                </div>
            }

            {evening_course_student_details && 
                <div className={UserInfoStyle.info}>
                    <LabelValue index='Registration No' value={evening_course_student_details?.registration_no}/>
                    <LabelValue index='Session' value={evening_course_student_details?.session}/>
                    <LabelValue index='Admission Year' value={evening_course_student_details?.admission_year}/>
                    <LabelValue index='Batch' value={evening_course_student_details?.batch}/>
                    <LabelValue index='Hall' value={evening_course_student_details?.hall}/>
                    <LabelValue index='Validity' value={evening_course_student_details?.validity}/>
                    <LabelValue index='Course' value={evening_course_student_details?.course_title}/>
                </div>
            }
        </>
    )
}
