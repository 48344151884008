import { CalendarIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import "../../assets/css/notice.css";
import axios from 'axios';
import ToastMessage from '../utility/ToastMessage';
import CustomSpinner from '../utility/CustomSpinner';

export default function Notice(){
    const [notices, setNotices] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchNotices();
    },[]);

    const fetchNotices = async () => {
        setLoading(true);

        const today = new Date().toJSON().slice(0, 10);

        const params = {
            notice_from: today,
            notice_until: today
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/notices`,{
                params : params
            })).data;
            if (apiResponse.error === false && apiResponse.results) {
                setNotices(apiResponse.results.notices);
            }
        } catch (error) {
            ToastMessage('error', 'Something went wrong while fetching notices');
        }

        setLoading(false);
    };

    return(
        <div className='notice-container'>
            <h3>Important Notices</h3>

            {loading === true &&
                <div className='spinner_div'>
                    <CustomSpinner />
                </div>
            }

            {loading === false && notices.length > 0 &&
                notices.map((notice, index) => (
                    <div className='notice-div' key={index}>
                        <div className='date-div'>
                            <CalendarIcon />
                            <span> {notice.notice_from}</span>
                        </div>
                        <div className='details-div'>
                            <h4>{notice.title}</h4>
                            <p> {notice.description} </p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
