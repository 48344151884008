import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import { getDeptVal, getInstVal } from '../../components/common/CommonFunctions';
import {  } from 'formik';
import TextError from '../../components/common/TextError';
import customStyle from '../../assets/css/modular/custom-form.module.css';

export default function AddEveningCourse(){
    return <AddEveningCourseForm />;
}

const AddEveningCourseForm = () => {
    const [offices, setOffices] = useState([]);
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');
    const [signatureFile, setSignatureFile] = useState('');

    useEffect(() => {
        fetchOfficeData();
    }, []);

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const fetchOfficeData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-dept-institutes`, {
                headers: config
            })).data;
            if (apiResponse.error === false && apiResponse.results) {
                setOffices(apiResponse.results);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const fetchData = () => {
        let init = {
            course_title: '',
            course_title_short: '',
            office_id: '',
            course_duration: '',
            file: ''
        };

        return init;
    }

    const validataionSchema = Yup.object({
        course_title: Yup.string().required("Required").max(256, 'must be at most 256 characters'),
        course_title_short: Yup.string().required("Required").max(10, 'must be at most 10 characters'),
        office_id: Yup.string().required("Required"),
        course_duration: Yup.string().required("Required").max(2)
    });

    const getOfficeOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id+'#'+options_data[i].type,
                    value: options_data[i].name
                });
            }
        }
        return return_data;
    }

    const onSubmit = (values) => {
        values.department_id = getDeptVal(values.office_id);
        values.institute_id = getInstVal(values.office_id);
        if(!signatureFile) {
            alert('Please select signature');
            return; 
        }

        let form_data = new FormData();
        form_data.append('signature', signatureFile);

        for(let key in values){
            form_data.append(key,values[key]);
        }

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }

        axios
        .post(`${process.env.REACT_APP_API_URL}/api/evening_course`, form_data, config)
        .then((response) => {
            navigate('/evening-courses');
        })
        .catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD EVENING COURSE</Text>
            </Flex>
            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validataionSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} placeholder={'i.e. Professional Masters in IT'} control='input' type='text' label='Course Title' name='course_title' />
                                <FormikControl required={true} placeholder={'i.e. PMIT'} control='input' type='text' label='Short Title' name='course_title_short' />
                                <FormikControl required={true} placeholder={'Please select office'} control='select' label='Department / Institute' name='office_id' options={getOfficeOptions(offices)} />
                                <FormikControl required={true} placeholder={'i.e. 12'} control='input' type='number' label='Course Duration (months)' name='course_duration' />
                                
                                <div className={`form-control ${customStyle.custom_form}`}>
                                    <label className={customStyle.custom_label} htmlFor={'file'}>{'Add Signature'} <span className='required'>*</span> </label>
                                    <input
                                        type="file"
                                        id="file"
                                        name="file"
                                        onChange={(e) => {
                                            setSignatureFile(e.target.files[0]);
                                            formik.handleChange('file', e.target.files[0]);
                                        }}
                                    />
                                    <ErrorMessage name={'file'} component={TextError} />
                                </div>

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>

        </>
    )
}
