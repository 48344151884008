// @ts-nocheck
import React,{useEffect, useState} from 'react';
import { ChakraProvider, extendTheme, Heading, Button } from '@chakra-ui/react';
import { Link, useNavigate } from "react-router-dom";
import {Formik, Form} from 'formik';
// @ts-ignore
import styles from "../../assets/css/modular/apply-form.module.css";
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
});

export default function RegisterTeacherData(){
    const [designations, setDesignations] = useState([]);
    const [offices, setOffices] = useState([]);
    const [education_institutes, setEducationInsitutes] = useState([]);
    const navigate = useNavigate();
    let application_data = localStorage.getItem('application_data');
    let is_checked = true;
    let is_first_step = true;
    let is_second_step = true;

    if(!application_data){
        is_checked = false;
    }else{
        application_data = JSON.parse(application_data);
        if(!application_data.declaration || application_data.declaration !== true){
            is_checked = false;
        }
        if(!application_data.declaration || application_data.first_step !== true){
            is_first_step = false;
        }
        if(!application_data.declaration || application_data.second_step !== true){
            is_second_step = false;
        }
    }

    useEffect(() => {
        fetchOfficeData();
        fetchDesignationData();
        fetchEducationInsitutesData();
        if(is_checked == false){
            ToastMessage("warning","Please check the declaration first");
            navigate('/apply-for-id');
        }

        if(is_first_step == false){
            ToastMessage("warning","Please complete first step first");
            navigate('/apply-first-step');
        }

        if(is_second_step == false){
            ToastMessage("warning","Please complete previous step first");
            navigate('/apply-second-step');
        }
    }, [is_checked, is_first_step, is_second_step]);

    const sample_responsibility = {
        office_id: '',
        designation_id: '',
        start_date: ''
    };

    const sample_education = {
        institute_id: '',
        major: '',
        start_year: '',
        end_year: '',
        is_current: '',
        institute_other: ''
    };

    const fetchData = () => {
        let initialValues = {
            employee_id: '',
            designation_id: '',
            office_id: '',
            joining_date: '',
            other_responsibilities: [sample_responsibility],
            educations: [sample_education, sample_education]
        };

        initialValues.employee_id = application_data?.user_id;
        initialValues.designation_id = application_data?.designation_id;
        initialValues.office_id = application_data?.office_id;
        initialValues.joining_date = application_data?.joining_date;
        if(Array.isArray(application_data?.other_responsibilities)){
            initialValues.other_responsibilities = application_data?.other_responsibilities;
        }
        if(Array.isArray(application_data?.educations)){
            initialValues.educations = application_data?.educations;
        }

        return initialValues;
    }

    const fetchOfficeData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/office`,{
                params: {
                    types: ['1','2']
                }
            })).data;
            if (apiResponse.error === false && apiResponse.results) {
                setOffices(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const fetchDesignationData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/designation`,{
                params: {
                    type: '3'
                }
            })).data;
            if (apiResponse.error === false && apiResponse.results) {
                setDesignations(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const fetchEducationInsitutesData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/education-institutes`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setEducationInsitutes(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const validataionSchema = Yup.object({
        employee_id: Yup.string().required("Required"),
        office_id: Yup.string().required("Required"),
        designation_id: Yup.string().required("Required"),
        joining_date: Yup.string().required("Required"),
    });

    const onSubmit = values => {
        application_data.employee_id = values.employee_id;
        application_data.office_id = values.office_id;
        application_data.designation_id = values.designation_id;
        application_data.joining_date = values.joining_date;
        application_data.third_step = true;
        application_data.other_responsibilities = values.other_responsibilities;
        application_data.educations = values.educations;
        localStorage.setItem('application_data',JSON.stringify(application_data));

        navigate('/image-upload');
    };

    const getOfficeOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].office
                });
            }
        }
        return return_data;
    }

    const getDesignationOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].designation
                });
            }
        }
        return return_data;
    }

    const getEducationInstitutesOptions = (options_data) => {
        let return_data = [{
            key: '',
            value: 'Please select one'
        }];

        if(options_data.length > 0){
            for(let i=0;i<options_data.length;i++){
                return_data.push({
                    key: options_data[i].id,
                    value: options_data[i].institute
                });
            }
        }

        return_data.push({
            key: -1,
            value: 'Other'
        });

        return return_data;
    }

    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <div className='short-contents'>
                        <Heading className='header-text' size='lg'>
                            Apply for Id card : 3rd Step (Academic Information)
                        </Heading>
                        <br />

                        <div className={styles.apply_form}>
                            <Formik
                                initialValues={fetchData()}
                                validationSchema={validataionSchema}
                                onSubmit={onSubmit}
                            >
                                {
                                    formik => (
                                        <Form>
                                            <FormikControl required={true} control='input' type='text' label='Employee Id' name='employee_id' disabled />
                                            <FormikControl required={true} control='select' label='Department / Institute' name='office_id' options={getOfficeOptions(offices)} />
                                            <FormikControl required={true} control='select' label='Designation' name='designation_id' options={getDesignationOptions(designations)} />
                                            <FormikControl required={true} control='date' type='date' label='Joining Date' name='joining_date' />
                                            <div className={styles.other_resp}>
                                                <div className={styles.label_sec}>
                                                    <h2>Other Responsibilities</h2>
                                                    <span className={styles.skip}><i>(Mention if any otherwise skip it)</i></span>
                                                </div>
                                                <FormikControl control='fieldArrResp' name='other_responsibilities' values={formik.values.other_responsibilities} office_options={getOfficeOptions(offices)} designation_options={getDesignationOptions(designations)} sample={sample_responsibility} />
                                            </div>
                                            <div className={styles.other_resp}>
                                                <div className={styles.label_sec}>
                                                    <h2>Degree Obtained</h2>
                                                    <span className={styles.skip}><i>(Mention at least two degree obtained and maintain the highest order first)</i></span>
                                                </div>
                                                <FormikControl control='fieldArrEdu' name='educations' values={formik.values.educations} education_institutes_options={getEducationInstitutesOptions(education_institutes)} sample={sample_education} />
                                            </div>
                                            <div className={styles.neighbor_buttons}>
                                                <Link to='/apply-second-step'><Button colorScheme="blue" type="button">Prev</Button></Link>
                                                <Button colorScheme="green" type="submit">Next</Button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            <Footer />
        </ChakraProvider>
    )
}
