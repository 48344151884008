import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../components/userEnd/common/UserLayout';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';
import { Box, Button, ButtonGroup, Center, Flex, Heading, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import Space from '../../components/utility/Space';
import { APPLY_FOR, STATUS } from '../../components/constants/Constants';

export default function MyApplies() {
    const token = localStorage.getItem('my_token');
    const userDetails = useContext(UserContext);
    const {id} = userDetails;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = React.useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [applies, setApplies] = useState([]);

    const [ historyId, setHistoryId ] = useState('');
    const [innerLoading, setInnerLoading] = useState(false);
    const [historyData, setHistoryData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchApplies();
    }, [id]);

    useEffect(() => {
        if(historyId !== '') {
            fetchHistory();
        }
    }, [historyId]);

    const fetchApplies = async() => {
        setIsLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-applies-by-user-id/${id}`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setApplies(apiResponse.results?.applies);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    const fetchHistory = async() => {
        setInnerLoading(true);

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/my-apply-details/${historyId}`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setHistoryData(apiResponse.results?.applies);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setInnerLoading(false);
    }

    const handleView = (param) => {
        setHistoryId(param);
        onOpen();
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>
                            Apply Id
                        </Th>
                        <Th textAlign={'center'}>
                            Sequence
                        </Th>
                        <Th textAlign={'center'}>
                            Apply For
                        </Th>
                        <Th textAlign={'center'}>
                            Apply Status
                        </Th>
                        <Th textAlign={'center'}>
                            Apply Date
                        </Th>
                        <Th textAlign={'center'}>
                            Action
                        </Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {applies.map((apply, idx) => {
                        return (
                            <Tr
                                key={idx}
                                display={{
                                    base: 'grid',
                                    md: 'table-row',
                                }}
                                sx={{
                                    '@media print': {
                                    display: 'table-row',
                                    },
                                    gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                    gridGap: '10px',
                                }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {apply.id}
                                </Td>

                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {apply.apply_seq}
                                </Td>

                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {APPLY_FOR[apply.apply_for]}
                                </Td>

                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {STATUS[apply.apply_status]}
                                </Td>

                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {apply.apply_date}
                                </Td>

                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEye />}
                                            onClick={() => handleView(apply.id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        )
    }

    const DisplayInnerData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>
                            Action
                        </Th>
                        <Th textAlign={'center'}>
                            Date
                        </Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {historyData.map((data, idx) => {
                        return (
                            <Tr
                                key={idx}
                                display={{
                                    base: 'grid',
                                    md: 'table-row',
                                }}
                                sx={{
                                    '@media print': {
                                    display: 'table-row',
                                    },
                                    gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                    gridGap: '10px',
                                }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {STATUS[data.action]}
                                </Td>

                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {data.action_date}
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        )
    }

    const DetailsModal = () => {
        return (
            <>
                <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                    <ModalHeader>Log History</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        {innerLoading
                            ? <CustomSpinner />
                            : <DisplayInnerData />
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }

    return (
        <>
            <Center>
                <Heading as='h2' size='xl'>
                    My Applies
                </Heading>
            </Center>

            <Space />

            <DetailsModal />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
                className="admin_table"
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
        </>
    )
}
