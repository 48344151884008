import { AtSignIcon, LockIcon } from "@chakra-ui/icons";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Center, ChakraProvider, Checkbox, color, Heading, Image, Input, Stack, Text, theme } from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { InputField } from "../../components/auth/InputField";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
// @ts-ignore
import banner from "../../assets/images/output-onlinepngtools.png";

export default function Login(){
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const schema = object().shape({
        email: string()
            .required("Please enter your email address"),
        password: string()
            .required("Please enter your password")
    })

    return(
        <ChakraProvider theme={theme}>
            <Header />
            <Center bg="blue.200" style={{minHeight: '800px'}}>
                <Stack boxShadow="md" bg="whiteAlpha.900" px="20" py="10" rounded="md" my={10} >
                    <Image src={banner} alt="ju logo" maxW="100px" mx="auto" />
                    <Heading as="h1" textAlign={"center"}>LOG IN</Heading>
                    <Text fontSize="lg" color="gray.600">
                        Please login with your credentials
                    </Text>

                    { message != "" &&
                        <Alert status='error'>
                            <AlertIcon />
                            <AlertTitle>Login Failed!</AlertTitle>
                            <AlertDescription>{message}</AlertDescription>
                        </Alert>
                    }

                    <Formik
                        validationSchema={schema}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                axios.get(`${process.env.REACT_APP_API_URL}/sanctum/csrf-cookie`).then(response => {
                                    axios({
                                        method: 'post',
                                        url: `${process.env.REACT_APP_API_URL}/api/user-login`,
                                        data: values
                                    })
                                    .then(function (response) {
                                        const {token, user} = response?.data?.results;
                                        localStorage.setItem('my_token',token);

                                        if(user?.user_type === '1') {
                                            navigate('/dashboard');
                                        }else {
                                            navigate('/user_dashboard');
                                        }
                                    })
                                    .catch(function (error) {
                                        if(error.response.status == 401){
                                            setMessage(error.response.data.message);
                                        }
                                    });
                                    setSubmitting(false);
                                })
                            }, 1000);
                        }}
                        initialValues={{ email: "", password: "" }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Stack my="4" spacing="6">
                                    <InputField
                                        name="email"
                                        type="text"
                                        label="Email / Phone"
                                        placeholder="Enter your email / phone no"
                                        leftAddon={<AtSignIcon color="blue.500" />}
                                    />
                                    <InputField
                                        name="password"
                                        type="password"
                                        label="Password"
                                        placeholder="Enter password"
                                        leftAddon={<LockIcon color="blue.500" />}
                                    />
                                    <Checkbox colorScheme="blue">Keep me logged in</Checkbox>
                                    <Button
                                        isLoading={isSubmitting}
                                        loadingText="Connecting to server"
                                        size="lg"
                                        colorScheme="blue"
                                        type="submit"
                                    >
                                        Login
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>

                    <Stack justify="center" color="gray.600" spacing="3">
                        <Text as="div" textAlign="center">
                            <span>Don&lsquo;t have an account? </span>
                            <Button colorScheme="blue" variant="link" >
                                <Link to='/register'>Register Here!!</Link>
                            </Button>
                        </Text>
                        <Button colorScheme="blue" variant="link">
                            <Link to='/forgot_password'>Forgot Password?</Link>
                        </Button>
                    </Stack>
                </Stack>
            </Center>
            <Footer />
        </ChakraProvider>
    )
}
