import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import Intro from '../../components/landing/Intro';
import Ju from '../../components/landing/Ju';
import Notice from '../../components/landing/Notice';

const theme = extendTheme({
    fonts : {
        heading : "Raleway",
        body : "Raleway",
    },
})

export default function LandingPage(){
    return(
        <ChakraProvider theme={theme}>
            <Header />
                <div className='contents'>
                    <Intro/>
                    <Notice />
                    <Ju />
                </div>
            <Footer />
        </ChakraProvider>
    )
}
