import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Flex, IconButton, Input, Select, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
import { AiFillEdit } from 'react-icons/ai';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import ToastMessage from '../../components/utility/ToastMessage';
import { getDeptVal, getInstVal } from '../../components/common/CommonFunctions';
import { ceil } from 'lodash';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import CommonModal from '../../components/common/CommonModal';

export default function EveningCourseStudents({getHallOptions, user_type, deleteApplyData}){
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const token = localStorage.getItem('my_token');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [eveningCourses, setEveningCourses] = useState([]);

    const [filterRegistrationNo, setFilterRegistrationNo] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterHall, setFilterHall] = useState('');
    const [filterEveningCourse, setFilterEveningCourse] = useState('');

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);
    const [targetDeleteApplyId, setTargetDeleteApplyId] = useState(null);

    const STATUS = [
        'Applied',
        'Payment Completed',
        'Application Confirmed',
        'Printed',
        'Delivered'
    ];

    useEffect(() => {
        fetchUsers();
        fetchEveningCourseStudents();
    }, [currentPage]);

    const fetchUsers = async () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            params:{
                'user_type': user_type,
                'fullname': filterName,
                'registration_no': filterRegistrationNo,
                'hall' : filterHall,
                'evening_course': filterEveningCourse,
                'limit': limit,
                'page': currentPage
            }
        };

        setIsLoading(true);

        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-evening-course-students-applies`,config)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setUsers(apiResponse.results.users);
                setTotal(apiResponse.results.count);
                if (apiResponse.results.count) {
                    setTotalPages(ceil(apiResponse.results.count / limit));
                }
            }
        } catch (error) {
            ToastMessage('error', 'Something went wrong');
        }
        setIsLoading(false);
    };

    const fetchEveningCourseStudents = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/evening_course`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setEveningCourses(apiResponse.results.evening_courses);
            }
        } catch (error) {
            ToastMessage('error', 'Something went wrong');
        }
    }

    const getEveningCourseOptions = (halls) => {
        let return_data = [];

        if(eveningCourses.length > 0){
            for(let i=0;i<eveningCourses.length;i++){
                return_data.push(
                    <option key={i} value={eveningCourses[i].id}>{eveningCourses[i].course_title}</option>
                )
            }
        }
        return return_data;
    }

    const handleEdit = (id) => {
        console.log(id);
    }

    const reFilterUsers = (id) => {
        const newUsers = users.filter(item => item.id !== id);
        setUsers(newUsers);
    }

    const handleDelete = (id, apply_id) => {
        setTargetDeleteId(id);
        setTargetDeleteApplyId(apply_id);
        setShowDeleteModal(true);
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>Registration No</Th>
                        <Th textAlign={'center'}>Fullname</Th>
                        <Th textAlign={'center'}>Course</Th>
                        <Th textAlign={'center'}>Session</Th>
                        <Th textAlign={'center'}>Hall</Th>
                        <Th textAlign={'center'}>Batch</Th>
                        <Th textAlign={'center'}>STATUS</Th>
                        <Th textAlign={'center'}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {users.length > 0 &&
                        users.map((user, idx) => (
                            <Tr
                            key={idx}
                            display={{
                                base: 'grid',
                                md: 'table-row',
                            }}
                            sx={{
                                '@media print': {
                                display: 'table-row',
                                },
                                gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                gridGap: '10px',
                            }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    <Link className='link_details' to={'/user_details/'+user.id+'/apply/'+user.apply_id}>{user.registration_no}</Link>
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.fullname}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.course_title}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.session}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.hall}
                                </Td>
                            
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {user.batch}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {STATUS[user.apply_status]}
                                </Td>
                                <Td textAlign={'center'}>
                                <ButtonGroup variant="solid" size="sm" spacing={3}>
                                    {/* <IconButton
                                        colorScheme="green"
                                        aria-label="edit"
                                        icon={<AiFillEdit />}
                                        onClick={() => handleEdit(user.id)}
                                    /> */}
                                    <IconButton
                                        colorScheme="red"
                                        variant="outline"
                                        aria-label="delete"
                                        icon={<BsFillTrashFill />}
                                        onClick={() => handleDelete(user.id, user.apply_id)}
                                    />
                                </ButtonGroup>
                            </Td>
                            </Tr>
                        )
                        )
                    }
                </Tbody>
            </Table>
        )
    }

    const handleSearch = () => {
        fetchUsers();
    }

    const handleClear = () => {
        setFilterName('');
        setFilterRegistrationNo('');
        setFilterHall('');
        setFilterEveningCourse('');
    }

    return (
        <>
            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Registration No' size='md' value={filterRegistrationNo} onChange={(e) => setFilterRegistrationNo(e.target.value)} />
                    <Input placeholder='Name' size='md' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                    <Select placeholder='Select Hall' size='md' value={filterHall} onChange={(e) => setFilterHall(e.target.value)}>
                        {getHallOptions}
                    </Select>
                    <Select placeholder='Select Evening Course' size='md' value={filterEveningCourse} onChange={(e) => setFilterEveningCourse(e.target.value)}>
                        {getEveningCourseOptions(eveningCourses)}
                    </Select>
                </Flex>

                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            { showDeleteModal &&
                <CommonModal
                    reset={setShowDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    setTargetDeleteApplyId={setTargetDeleteApplyId}
                    deleteAction={deleteApplyData}
                    targetDeleteId={targetDeleteId}
                    targetDeleteApplyId={targetDeleteApplyId}
                    reFilterUsers={reFilterUsers}
                />
            }

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>

            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}
