// @ts-nocheck
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../common/TextError';
import customStyle from '../../assets/css/modular/custom-form.module.css';

export default function Select(props) {
    const { label, name, options, required, ...rest } = props;

    return (
        <div className={`form-control ${customStyle.custom_form}`}>
            <label className={customStyle.custom_label} htmlFor={name}>{label} {required == true ? <span className='required'>*</span> : '' }</label>
            <Field as="select" id={name} name={name} {...rest}>
                { options &&
                    options.map(option => {
                        return (
                            <option key={option.key} value={option.key}>
                                {option.value}
                            </option>
                        )
                    })
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}
