import { Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ToastMessage from '../../../components/utility/ToastMessage';
import axios from 'axios';
import CustomSpinner from '../../../components/utility/CustomSpinner';
import Style from '../../../assets/css/modular/admin.module.css';

export default function MonthTable() {
    const [summaryData, setSummaryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        fetchSummaryData();
    }, []);

    const token = localStorage.getItem('my_token');

    let config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const fetchSummaryData = async () => {
        const params = {
            // limit,
            // page: currentPage,
            // department: filterDepartment,
            // shortname: filterShortname,
            // faculty_id: filterFaculty
        };

        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard_data`, {
                    params: params,
                    headers: config
                })
            ).data;

            const results = apiResponse.results;

            if (results && results.length > 0) {
                setSummaryData(results);
            }else{
                setSummaryData([]);
            }

        } catch (err) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    };

    const DisplayData = () => {
        return(
            <Table className={Style.stat_table} variant='striped' colorScheme='teal' border='2px solid white' padding='10px' backgroundColor={'whiteAlpha.500'}>
                <Thead>
                    <Tr>
                        <Th>Parameter</Th>
                        <Th>Hons</Th>
                        <Th>Masters</Th>
                        <Th>Mphil</Th>
                        <Th>Phd</Th>
                        <Th>Teachers</Th>
                        <Th>Officers</Th>
                        <Th>Staffs</Th>
                        <Th>Evening</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {summaryData.length > 0 &&
                        summaryData.map((data, id) => (
                            <Tr key={id}>
                                <Td>{data.y_m}</Td>
                                <Td>{data.applyfor0}</Td>
                                <Td>{data.applyfor2}</Td>
                                <Td>{data.applyfor4}</Td>
                                <Td>{data.applyfor6}</Td>
                                <Td>{data.applyfor8}</Td>
                                <Td>{data.applyfor10}</Td>
                                <Td>{data.applyfor12}</Td>
                                <Td>{data.applyfor14}</Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
        )
    }

    return (
        <TableContainer>
            {isLoading
                ? <CustomSpinner />
                : <DisplayData />
            }
        </TableContainer>
    )
}
