import React, { useEffect, useState } from 'react';
import './halls.css';
import {
  Flex,
  ButtonGroup,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Box,
  Button,
  Input,
  Text,
} from '@chakra-ui/react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { AddIcon } from '@chakra-ui/icons';

import axios from 'axios';
import { ceil } from 'lodash';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import ToastMessage from '../../components/utility/ToastMessage';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../../components/common/CommonModal';

export const Halls = () => {
    return <HallTableDemo />;
};

function HallTableDemo() {
    const header = ['Hall', 'Shortname', 'Action'];
    const [halls, setHalls] = useState([]);
    const limit = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const [filterHall, setFilterHall] = useState('');
    const [filterShortname, setFilterShortname] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const token = localStorage.getItem('my_token');
    let config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const getHalls = async () => {
        let params = {
            hall: filterHall,
            shortname: filterShortname,
            limit,
            page: currentPage
        };

        try {
            setIsLoading(true);
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/hall`, {
                    params: params,
                    headers: config
                })
            ).data;
            const results = apiResponse.results;
            if (results[0] && results[0].length > 0) {
                setHalls(results[0]);
                if (results[1].count) {
                    setTotalPages(ceil(results[1].count / limit));
                }
            }else{
                setHalls([]);
            }
        } catch (err) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getHalls();
    }, [currentPage]);

    const handleSearch = () => {
        setCurrentPage(1);
        getHalls();
    }

    const handleClear = () => {
        setFilterHall('');
        setFilterShortname('');
    }

    const onClickHallDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                    {header.map((x) => (
                        <Th key={x} textAlign={'center'}>
                            {x}
                        </Th>
                    ))}
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {halls.map((hall, idx) => {
                        return (
                            <Tr
                                key={idx}
                                display={{
                                    base: 'grid',
                                    md: 'table-row',
                                }}
                                sx={{
                                    '@media print': {
                                    display: 'table-row',
                                    },
                                    gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                    gridGap: '10px',
                                }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {hall.hall}
                                </Td>

                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {hall.shortname}
                                </Td>

                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEdit />}
                                            onClick={() => navigate(`/halls/edit/${hall.id}`)}
                                        />
                                        <IconButton
                                            colorScheme="red"
                                            variant="outline"
                                            aria-label="delete"
                                            icon={<BsFillTrashFill />}
                                            onClick={() => onClickHallDelete(hall?.id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        )
    }

    const deleteData = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/hall/${id}`,{
                headers: config
            })
            .then((response) => {
                //getHalls();
                ToastMessage("success","Deleted Successfully");
            })
            .catch((error) => {
                ToastMessage('error', 'Something went wrong');
            });
    }

    return (
        <>
            <Flex justifyContent={'center'}>
                <Text fontSize="xl">HALLS</Text>
            </Flex>

            <Flex justifyContent={'center'} w={{ base: '100%', md: '100%', sm: 'full' }} className="admin_add_button">
                <Box>
                    <Button
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        variant={'solid'}
                        size="sm"
                        onClick={() => navigate('/halls/add')}
                    >
                        Add New
                    </Button>
                </Box>
            </Flex>

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={deleteData}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Hall' size='md' value={filterHall} onChange={(e) => setFilterHall(e.target.value)} />
                    <Input placeholder='Shortname' size='md' value={filterShortname} onChange={(e) => setFilterShortname(e.target.value)} />
                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
}
