import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';
import CustomSpinner from '../../components/utility/CustomSpinner';

const token = localStorage.getItem('my_token');

export default function EditDepartment(){
    const [isLoading, setIsLoading] = useState(false);
    const [ departmentInfo, setDepartmentInfo ] = useState({});
    const { id } = useParams();

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    useEffect(() => {
        fetchDepartment(id);
    }, [id]);

    const fetchDepartment = async (id) => {
        setIsLoading(true);

        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/department/${id}`, {
                    headers: config
                })
            ).data;

            const results = apiResponse.results;

            if (results?.department) {
                setDepartmentInfo(results);
            }else{
                setDepartmentInfo({});
            }
        } catch (err) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    }

    const DisplayData = () => {
        return (
            <>
                { isLoading
                    ? <CustomSpinner />
                    : departmentInfo?.id
                        ? <EditDepartmentForm departmentInfo={departmentInfo} id={id} />
                        : <p>Nothing Found with this</p>
                }
            </>
        )
    }

    return <DisplayData />;
}

const EditDepartmentForm = ({departmentInfo, id}) =>{
    // @ts-ignore
    const navigate = useNavigate();
    const token = localStorage.getItem('my_token');

    const [faculties, setFaculties] = useState([]);

    useEffect(() => {
        getFaculties();
    }, []);

    const getFaculties = async () => {
        try {
        var apiResponse = (
            await axios.get(`${process.env.REACT_APP_API_URL}/api/faculty`)
        ).data;
        const results = apiResponse.results;
        if (results[0] && results[0].length > 0) {
            setFaculties(results[0]);
        }
        } catch (err) {
            console.log({ err });
        }
    };

    const fetchData = () => {
        let init = {
            department: departmentInfo?.department,
            shortname: departmentInfo?.shortname,
            faculty_id: departmentInfo?.faculty_id
        };

        return init;
    }

    const validationSchema = Yup.object({
        faculty_id: Yup.string().required('Faculty is required'),
        department: Yup.string().max(256, 'Please enter within 256 characters').required('Department is required'),
        shortname: Yup.string().max(256, 'Please enter within 256 characters').required('Shortname is required'),
    });

    const facultyOptions = () => {
        let rtn = [
            {key: '', value: "Select an option"}
        ];

        faculties.map((faculty, index) => {
            return (
                rtn.push(
                    {
                        key: faculty.id,
                        value: faculty.faculty
                    }
                )
            )
        });

        return rtn;
    }

    const onSubmit = (values) => {
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
        };

        axios
            .patch(`${process.env.REACT_APP_API_URL}/api/department/${id}`, values, config)
            .then((response) => {
                ToastMessage('success','Successfully added');
                navigate('/departments');
            })
            .catch((error) => {
                const {data, status} = error?.response;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }

                const { errors } = data;

                ToastMessage('error',message);
            });
    };

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">EDIT DEPARTMENT</Text>
            </Flex>

            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl required={true} control='select' label='Faculty' name='faculty_id' options={facultyOptions()} />
                                <FormikControl required={true} control='input' type='text' placeholder='Enter Department' label='Department' name='department' />
                                <FormikControl required={true} control='input' type='text' placeholder='Enter shortname' label='Shortname' name='shortname' />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </>
    )
}
