import { AtSignIcon, LockIcon } from "@chakra-ui/icons";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Center, ChakraProvider, Checkbox, color, Heading, Image, Input, Stack, Text, theme } from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { InputField } from "../../components/auth/InputField";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
// @ts-ignore
import banner from "../../assets/images/output-onlinepngtools.png";
import { MdToken } from "react-icons/md";
import * as Yup from "yup";

export default function ResetPassword(){
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const navigate = useNavigate();

    const schema = object().shape({
        token: string().required("Token is required"),
        email: string().email().required("Please enter your email address"),
        new_password: Yup.string().required("Password is required"),
        new_confirm_password: Yup.string().required("Password Confirmation is required").oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    })

    return(
        <ChakraProvider theme={theme}>
            <Header />
            <Center bg="blue.200" style={{minHeight: '800px'}}>
                <Stack boxShadow="md" bg="whiteAlpha.900" px="20" py="10" rounded="md" my={10} >
                    <Image src={banner} alt="ju logo" maxW="100px" mx="auto" />
                    <Heading as="h1" textAlign={"center"}>Reset New Password</Heading>
                    <Text fontSize="lg" color="gray.600">
                        <Center>
                            Enter the followings
                        </Center>
                    </Text>

                    { message != "" &&
                        <>
                            {
                                isSuccess === false 
                                ?
                                    <Alert status='error'>
                                        <AlertIcon />
                                        <AlertTitle>Something went wrong!</AlertTitle>
                                        <AlertDescription>{message}</AlertDescription>
                                    </Alert>
                                :   
                                    <Alert status='success'>
                                        <AlertIcon />
                                        <AlertTitle>Succcess!</AlertTitle>
                                        <AlertDescription>{message}</AlertDescription>
                                    </Alert>
                            }
                        </>
                    }

                    <Formik
                        validationSchema={schema}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                axios({
                                    method: 'post',
                                    url: `${process.env.REACT_APP_API_URL}/api/reset-password`,
                                    data: values
                                })
                                .then(function ({data, status}) {
                                    if(status === 200) {
                                        setIsSuccess(true);
                                        setMessage(data.message);
                                        setTimeout(
                                            function() {
                                                navigate('/login');
                                            }, 3000);
                                    }
                                })
                                .catch(function (error) {
                                    if(error.response.status == 401){
                                        setMessage(error.response.data.message);
                                    }else {
                                        setMessage('Something went wrong');
                                    }
                                });
                                setSubmitting(false);
                            }, 1000);
                        }}
                        initialValues={{ email: "", token: "", new_password: "", new_confirm_password: "", }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Stack my="4" spacing="6">
                                    <InputField
                                        name="email"
                                        type="text"
                                        label="Email Address"
                                        placeholder="Enter your email"
                                        leftAddon={<AtSignIcon color="blue.500" />}
                                    />
                                    <InputField
                                        name="token"
                                        type="text"
                                        label="Token (sent via email)"
                                        placeholder="Enter your token"
                                        leftAddon={<MdToken color="blue.500" />}
                                    />
                                    <InputField
                                        name="new_password"
                                        type="password"
                                        label="Password"
                                        placeholder="Enter password"
                                        leftAddon={<LockIcon color="blue.500" />}
                                    />
                                    <InputField
                                        name="new_confirm_password"
                                        type="password"
                                        label="Password"
                                        placeholder="Confirm password"
                                        leftAddon={<LockIcon color="blue.500" />}
                                    />
                                    <Button
                                        isLoading={isSubmitting}
                                        loadingText="Connecting to server"
                                        size="lg"
                                        colorScheme="blue"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                    <Stack justify="center" color="gray.600" spacing="3">
                        <Text as="div" textAlign="center">
                            <span>Remembered? </span>
                            <Button colorScheme="blue" variant="link" >
                                <Link to='/login'>Back to Login!!</Link>
                            </Button>
                        </Text>
                    </Stack>
                </Stack>
            </Center>
            <Footer />
        </ChakraProvider>
    )
}
