// @ts-nocheck
import React, { useEffect, useState } from 'react';
import StudentStyle from '../../assets/css/modular/student-id.module.css';
import Logo from '../../assets/images/assets/Logo.jpg';
import Banner from '../../assets/images/assets/banner.jpg';
import SampleImage from '../../assets/images/assets/sample_image.png';
import RegistrarSign from '../../assets/images/assets/registrar_sign.jpg';
import Barcode from 'react-barcode';
import axios from 'axios';
import { getNameFontSize } from '../common/CommonFunctions';

export default function MphilPhdStudentIdCard({halls,departments,institutes,dept_or_institute,fullname,apply_for,registration_no,blood_group,hall_id,regularity,department_id,institute_id,session,validity,finalThumb}){
    const [hall_list, setHallList] = useState(halls);
    const [department_list, setDepartmentList] = useState(departments);
    const [institute_list, setInstituteList] = useState(institutes);

    useEffect(() => {
        if(hall_list.length == 0 || department_list.length == 0 || institute_list.length == 0){
            fetchOptionData();
        }
    },[]);

    const fetchOptionData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-options`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setDepartmentList(apiResponse.results.departments);
                setHallList(apiResponse.results.halls);
                setInstituteList(apiResponse.results.institutes);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const getHall = (halls,hall_id) => {
        let hall = halls.filter((item) => {
            if(item.id == hall_id){
                return item;
            }
        })
        if(hall){
            return hall[0]['hall'];
        }
        return '';
    }

    const getDepartment = (dept_or_institute,departments,institutes,department_id,institute_id) => {
        if(dept_or_institute == 0){
            let department = departments.filter((item) => {
                if(item.id == department_id){
                    return item;
                }
            })
            if(department){
                return department[0]['department'];
            }
            return '';
        }else if(dept_or_institute == 1){
            let institute = institutes.filter((item) => {
                if(item.id == institute_id){
                    return item;
                }
            })
            if(institute){
                return institute[0]['institute'];
            }
            return '';
        }
    }

    return(
        <>
            {department_list.length>0 && institute_list.length>0 && hall_list.length>0 &&
                <div className={StudentStyle.idCardBody}>
                    <div className={StudentStyle.leftPart}>
                    <p>{getHall(hall_list,hall_id)}</p>
                    </div>
                    <div className={StudentStyle.rightPart}>
                        <div className={StudentStyle.bannerPart}>
                            <img src={Logo} className={StudentStyle.logo} />
                            <img src={Banner} className={StudentStyle.banner} />
                        </div>

                        <div className={StudentStyle.studentImg}>
                            <img src={ finalThumb ? finalThumb : SampleImage } className={StudentStyle.image} />
                        </div>
                        <div className={StudentStyle.naming}>
                            <p className={StudentStyle.fullname} style={{fontSize: getNameFontSize(fullname)}}>{fullname}</p>
                        </div>

                        <div className={StudentStyle.info}>
                            <div className={StudentStyle.left}>
                                <div className={StudentStyle.items}>
                                    <p>Student ID Number: </p>
                                    <b className={StudentStyle.value}>{registration_no}</b>
                                </div>
                                <div className={StudentStyle.items}>
                                    <p>Department: </p>
                                    <b className={StudentStyle.value}>{getDepartment(dept_or_institute,department_list,institute_list,department_id,institute_id)}</b>
                                </div>
                                <div className={StudentStyle.items}>
                                    <p>Blood Group: </p>
                                    <b className={StudentStyle.value}>{blood_group}</b>
                                </div>
                                <div className={StudentStyle.barcode}>
                                    <Barcode value={registration_no} displayValue={false} width={40} height={1100}/>
                                </div>
                            </div>
                            <div className={StudentStyle.right}>
                                <div className={StudentStyle.rightInfoPart}>
                                    <div className={StudentStyle.items}>
                                        <p>Class: </p>
                                        <b className={StudentStyle.value}>
                                            {apply_for}({regularity})
                                        </b>
                                    </div>
                                    <div className={StudentStyle.items}>
                                        <p>Session: </p>
                                        <b className={StudentStyle.value}>
                                            {session}
                                        </b>
                                    </div>
                                    <div className={StudentStyle.items}>
                                        <p>Validity: </p>
                                        <b className={StudentStyle.value}>
                                            {validity}
                                        </b>
                                    </div>
                                </div>
                                <div className={StudentStyle.img_part} style={{marginTop: '16px'}}>
                                    <div id="footerDivContent">
                                        <div className={StudentStyle.footerContent}>
                                            <img src={RegistrarSign} className={StudentStyle.signature} />
                                        </div>
                                        <p className={StudentStyle.registrar}>Registrar</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className={StudentStyle.barcode}>
                            <Barcode value={registration_no} displayValue={false} />
                        </div> */}
                    </div>
                </div>
            }
        </>
    )
}
