import React from "react";

function TextError({children}){
    return(
        <span className="error">
            {children}
        </span>
    )
}

export default TextError;
