import { Button, Flex, Text } from '@chakra-ui/react';
// @ts-ignore
import React from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import styles from '../../assets/css/modular/admin.module.css';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/form-components/FormikControl';
import axios from 'axios';
import ToastMessage from '../../components/utility/ToastMessage';

export default function AddAdmin(){
    return <AddAdminForm />;
}

const AddAdminForm = () =>{
    // @ts-ignore
    const navigate = useNavigate();

    const token = localStorage.getItem('my_token');

    const fetchData = () => {
        let init = {
            email: '',
            mobile: '',
            user_type: '',
            fullname: '',
            dob: '',
        };

        return init;
    }

    const validataionSchema = Yup.object({
        email: Yup.string().email("Invalid Email Address").required("Required"),
        mobile: Yup.string().matches(/^\d+$/, 'Please enter digits only').max(11, 'Mobile no must be at least 11 characters').required("Required"),
        user_type: Yup.string().required("Required"),
        fullname: Yup.string().required("Required"),
        dob: Yup.string().required("Required"),
    });

    const onSubmit = (values) => {
        let config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
              }
        };

        axios
        .post(`${process.env.REACT_APP_API_URL}/api/admin`, values, config)
        .then((response) => {
            ToastMessage('success','Successfully Added');
            navigate('/admins');
        })
        .catch((error) => {
            ToastMessage('error','Something went wrong');
        });
    };

    const user_type_options = [
        {key: 1, value: "Super Admin"},
        {key: 2, value: "Admin"}
    ];

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADD ADMIN</Text>
            </Flex>
            <div className={styles.apply_form}>
                <Formik
                    initialValues={fetchData()}
                    validationSchema={validataionSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => (
                            <Form>
                                <FormikControl placeholder='Enter email' required={true} control='input' type='email' label='Email' name='email' />
                                <FormikControl placeholder='Enter mobile no' required={true} control='input' type='text' label='Mobile No' name='mobile' />
                                <FormikControl required={true} control='radio' label='Admin Type' name='user_type' options={user_type_options} />
                                <FormikControl placeholder='Enter fullname' required={true} control='input' type='text' label='Fullname' name='fullname' />
                                <FormikControl required={true} control='date' type='date' label='Date of Birth' name='dob' />

                                <div>
                                    <Button colorScheme="green" type="submit">Submit</Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>

        </>
    )
}
