import React from 'react';
import Input from './Input';
import Textarea from './Textarea';
import Select from './Select';
import RadioButtons from './RadioButtons';
import CustomDatePicker from './CustomDatePicker';
import File from './File';
import FieldArrResp from './FieldArrResp';
import FieldArrEdu from './FieldArrEdu';

export default function FormikControl(props){
    const {control, ...rest} = props;

    switch(control) {
        case 'input':
        case 'number':    
            return <Input {...rest} />
        case 'textarea':
            return <Textarea {...rest} />
        case 'select':
            return <Select {...rest} />
        case 'radio':
            return <RadioButtons {...rest} />
        case 'checkbox':
        case 'date':
            return <CustomDatePicker {...rest} />
        case 'file':
            return <File {...rest} />
        case 'fieldArrResp':
            return <FieldArrResp {...rest} />
        case 'fieldArrEdu':
            return <FieldArrEdu {...rest} />
        default: return null
    }
    return (
        <div>

        </div>
    )
}
