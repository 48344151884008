// @ts-nocheck
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../common/TextError';
import customStyle from '../../assets/css/modular/custom-form.module.css';

export default function Textarea(props) {
    const { label, name, required, ...rest } = props;

    return (
        <div className={`form-control ${customStyle.custom_form}`}>
            <label className={customStyle.custom_label} htmlFor={name}>{label} {required == true ? <span className='required'>*</span> : '' }</label>
            <Field as="textarea" id={name} name={name} {...rest} />
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}
