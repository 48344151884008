import { Box, useColorModeValue } from '@chakra-ui/react'
import React, { createContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import UserSidebar from './UserSidebar';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import CustomSpinner from '../../utility/CustomSpinner';

const UserContext = createContext(null);

const UserLayout = () => {
    const [userData, setUserData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const token = localStorage.getItem('my_token');

    const navigate = useNavigate();

    const config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    useEffect(() => {
        fetchUserDetails();
    }, [token]);

    const fetchUserDetails = async () => {
        try {
            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/user_details`, {
                    headers: config
                })
            ).data;

            if (apiResponse.error === false && apiResponse.results) {
                if(['3','4','5','6', '8'].includes(apiResponse?.results?.userDetails?.user_type) === false) {
                    navigate('/login');
                }
                setUserData(apiResponse?.results?.userDetails);
            }
        } catch (error) {
            setUserData({});
        }

        setIsLoaded(true);
    }

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            {isLoaded
                ?
                    <>
                        { userData?.id
                        ?
                            <>
                                <UserContext.Provider value={userData}>
                                    <UserSidebar />
                                    <Box ml={{ base: 0, md: 60 }} p="4">
                                        <ToastContainer />
                                        <Outlet />
                                    </Box>
                                </UserContext.Provider>
                            </>
                        :
                            <Navigate to={'/login'} />
                        }
                    </>
                :
                    <CustomSpinner />
            }
        </Box>
    )
}

export {UserContext, UserLayout};
