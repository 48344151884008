// @ts-nocheck
import React, { useEffect, useState } from 'react';
import EmployeeStyle from '../../assets/css/modular/employee-id.module.css';
import Logo from '../../assets/images/assets/Logo.jpg';
import Banner from '../../assets/images/assets/banner.jpg';
import SampleImage from '../../assets/images/assets/sample_image.png';
import RegistrarSign from '../../assets/images/assets/registrar_sign.jpg';
import QRCode from "react-qr-code";
import axios from 'axios';
import { getNameFontSize } from '../common/CommonFunctions';

export default function EmployeeIdCard({offices,designations,fullname,employee_id,designation_id,blood_group,office_id,nid,dob,finalThumb}){
    const [office_list, setOfficeList] = useState(offices);
    const [designation_list, setDesignationList] = useState(designations);

    useEffect(() => {
        if(office_list.length == 0){
            fetchOfficeData();
        }
        if(designation_list.length == 0){
            fetchDesignationData();
        }
    },[]);

    const fetchOfficeData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/office`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setOfficeList(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const fetchDesignationData = async () => {
        try {
            var apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/designation`)).data;
            if (apiResponse.error === false && apiResponse.results) {
                setDesignationList(apiResponse.results[0]);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const getOffice = (offices,office_id) => {
        let office = offices.filter((item) => {
            if(item.id == office_id){
                return item;
            }
        })
        if(office){
            return office[0]['office'];
        }
        return '';
    }

    const getDesignation = (designations,designation_id) => {
        let designation = designations.filter((item) => {
            if(item.id == designation_id){
                return item;
            }
        })
        if(designation){
            return designation[0]['designation'];
        }
        return '';
    }

    return(
        <>
            {office_list.length>0 && designation_list.length>0 &&
                <div className={EmployeeStyle.idCardBody}>
                    <div className={EmployeeStyle.leftPart}>
                        <p>{getOffice(office_list,office_id)}</p>
                    </div>
                    <div className={EmployeeStyle.rightPart}>
                        <div className={EmployeeStyle.bannerPart}>
                            <img src={Logo} className={EmployeeStyle.logo} />
                            <img src={Banner} className={EmployeeStyle.banner} />
                        </div>

                        <div className={EmployeeStyle.teacherImg}>
                            <img src={finalThumb ? finalThumb : SampleImage} className={EmployeeStyle.image} />
                        </div>
                        <div className={EmployeeStyle.naming}>
                            <p className={EmployeeStyle.fullname} style={{fontSize: getNameFontSize(fullname)}}>{fullname}</p>
                        </div>

                        <div className={EmployeeStyle.info}>
                            <div className={EmployeeStyle.left}>
                                <div className={EmployeeStyle.items}>
                                    <p>ID Number: </p>
                                    <b className={EmployeeStyle.value}>{employee_id}</b>
                                </div>
                                <div className={EmployeeStyle.items}>
                                    <p>NID Number: </p>
                                    <b className={EmployeeStyle.value}>{nid}</b>
                                </div>
                                <div className={EmployeeStyle.items}>
                                    <p>Blood Group: </p>
                                    <b className={EmployeeStyle.value}>{blood_group}</b>
                                </div>
                                <div id="footerDivContent">
                                    <div className={EmployeeStyle.footerContent}>
                                        <img src={RegistrarSign} className={EmployeeStyle.signature} />
                                    </div>
                                    <p className={EmployeeStyle.registrar}>Registrar</p>
                                </div>
                            </div>
                            <div className={EmployeeStyle.right}>
                                <div className={EmployeeStyle.rightInfoPart}>
                                    <div className={EmployeeStyle.items}>
                                        <p>Designation: </p>
                                        <b className={EmployeeStyle.value}>
                                            {getDesignation(designation_list,designation_id)}
                                        </b>
                                    </div>
                                    <div className={EmployeeStyle.items}>
                                        <p>Date of Birth: </p>
                                        <b className={EmployeeStyle.value}>
                                            {dob}
                                        </b>
                                    </div>
                                </div>
                                <div className={EmployeeStyle.img_part}>
                                    {/* <img src="images/qr.png" height="75px" width="90px" class="code" /> */}
                                    <div style={{ height: "75px", margin: "0 auto", maxWidth: "65px", width: "100%" }}>
                                        <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={employee_id}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
