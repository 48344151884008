// @ts-nocheck
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../common/TextError';
import customStyle from '../../assets/css/modular/custom-form.module.css';

export default function File(props) {
    const { label, name, required, handleChange, ...rest } = props;
    const handleChangeAction = (e) =>  {
        handleChange(e);
    };

    return (
        <div className={`form-control ${customStyle.custom_form}`}>
            <label className={customStyle.custom_label} htmlFor={name}>{label} {required == true ? <span className='required'>*</span> : '' }</label>
            <Field
              type="file"
              id="file"
              name="file"
              onChange={(e) => handleChangeAction(e)}
            />
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}
