import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import ToastMessage from "../utility/ToastMessage";
import { EMPLOYEE_USER_TYPES } from "../constants/Constants";

// Get user type string from user_type
export const getType = (type) => {
    let rtn = '';

    if(type == '1') {
        rtn = 'System Admin';
    } else if(type == '2') {
        rtn = 'Admin';
    } else if(type == '3') {
        rtn = 'Teacher';
    } else if (type == '4') {
        rtn = 'Hons & Masters Student'
    } else if (type == '5') {
        rtn = 'Mphil & Phd Student'
    } else if (type == '6') {
        rtn = 'Officer'
    } else if (type == '7') {
        rtn = 'Staff';
    } else if (type == '8') {
        rtn = 'Evening Course Student';
    }

    return rtn;
}

// common logout for all user
export const handleLogout = (navigate) => {
    let token = localStorage.getItem('my_token');
    let config = {
        headers: {
            'Authorization': `Bearer ${token}`
          }
    };

    axios
    .post(`${process.env.REACT_APP_API_URL}/api/user-logout`,{},config)
    .then((response) => {
        localStorage.removeItem('my_token');
        navigate('/login');
    })
    .catch((error) => {
        ToastMessage('error','Something went wrong');
    });

}

//get sex from value
export const getSex = (sexVal) => {
    const sex_options = [
        {key: 1, value: "Male"},
        {key: 2, value: "Female"},
        {key: 3, value: "Other"},
    ];

    const option = sex_options.find((item) => {
        return item.key == sexVal;
    });

    return !option ? '' : option.value;
}

//get Department from 1#val 
export const getDeptVal = (val) => {
    let rtn = '';
    if(val.length > 0) {
        let val_arr = val.split('#');
        if(val_arr[1] == 'department') {
            rtn = val_arr[0];
        }
    }
    return rtn;
}

//get Institute from 1#val 
export const getInstVal = (val) => {
    let rtn = '';
    if(val.length > 0) {
        let val_arr = val.split('#');
        if(val_arr[1] == 'institute') {
            rtn = val_arr[0];
        }
    }
    return rtn;
}

//student or employee
export const isEmployee = (val) => {
    return EMPLOYEE_USER_TYPES.includes(val);   
}

//getValidityBySession
export const getValidityBySession = (val, type) => {
    let addVal = 0;
    if(type == 0) {
        /**Mphil */
        addVal = 3;
    }
    if(type == 1) {
        /** Phd */
        addVal = 4;
    }

    let rtnVal = '';
    if(val) {
        let paramArr = val.split('-');
        let validityYear = parseInt(paramArr[0])+addVal;
        rtnVal = validityYear+'-12-31';
    }

    return rtnVal;
}

// get fontSize for name in ID card
export const getNameFontSize = (param) => {
    let fontSize = '18px';
    
    if(!param) {
        return fontSize;
    }

    if(param.length > 21) {
        fontSize = '15px';
    }

    if(param.length > 28) {
        fontSize = '13px';
    }

    return fontSize;
}

// get validity of evening course
export const getEveningCourseValidity = (admission_year, course_validity) => {
    let rtnVal = '';
    if(admission_year) {
        let validityYear = parseInt(admission_year);
        let validityDate = validityYear+'-01-01';
        validityDate = new Date(validityDate);

        // Add 18 months to the input date
        validityDate.setMonth(validityDate.getMonth() + parseInt(course_validity));

        // subtract one day
        validityDate.setDate(validityDate.getDate() - 1);

        // Format the result in yyyy-mm-dd format
        rtnVal = validityDate.toISOString().slice(0, 10);
    }

    return rtnVal;
}

// evening course session options
export const getEveningCourseSessionOptions = () => {
    let data = ['Spring', 'Fall', 'Summer'];
    let return_data = [{
        key: '',
        value: 'Please select one'
    }];

    for(let i=0;i<data.length;i++){
        return_data.push({
            key: data[i],
            value: data[i]
        });
    }
    return return_data;
}

export const changeStatusToAccepted = (token, user_id, apply_id, redirectTo) => {
    let config = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
    };

    const params = {};

    axios
        .post(`${process.env.REACT_APP_API_URL}/api/accept-user-apply/${user_id}/${apply_id}`, params, config)
        .then((response) => {
            ToastMessage('success','Application Accepted & Printed');
            redirectTo();
        })
        .catch((error) => {
            const {status} = error?.response;

            let message = 'Something went wrong';
            ToastMessage('error',message);
        });
}
