import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Flex, IconButton, Input, Select, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import ToastMessage from '../../components/utility/ToastMessage';
import CommonModal from '../../components/common/CommonModal';

export default function Admins(){
    return <AdminList />;
}

const AdminList = () =>{
    const [admins, setAdmins] = useState([]);
    const [total, setTotal] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const [filterName, setFilterName] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [filterMobile, setFilterMobile] = useState('');
    const [filterType, setFilterType] = useState('');

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        fetchAdmins();
    }, []);

    const token = localStorage.getItem('my_token');

    let config = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    };

    const fetchAdmins = async () => {
        if(isLoading === false){
            setIsLoading(true);
        }

        try {
            let params = {
                fullname: filterName,
                email: filterEmail,
                mobile: filterMobile,
                user_type: filterType
            };

            let apiResponse = (await axios.get(`${process.env.REACT_APP_API_URL}/api/get-admins`, {
                params: params,
                headers: config
            })).data;

            if (apiResponse.error === false && apiResponse.results) {
                setAdmins(apiResponse.results.admins);
                setTotal(apiResponse.results.count);
            }
        } catch (error) {
            ToastMessage('error','Something went wrong');
        }

        setIsLoading(false);
    };

    const handleEdit = (id) => {
        navigate('/edit-admin/'+id);
    }

    const deleteData = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/admin/${id}`,{
                headers: config
            })
            .then((response) => {
                fetchAdmins();
                ToastMessage("success","Deleted Successfully");
            })
            .catch((error) => {
                ToastMessage('error', 'Something went wrong');
            });
    }

    const onClickDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const handleSearch = () => {
        fetchAdmins();
    }

    const handleClear = () => {
        setFilterName('');
        setFilterEmail('');
        setFilterMobile('');
        setFilterType('');
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                        display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                        base: 'none',
                        md: 'table-header-group',
                        }}
                        sx={{
                        '@media print': {
                            display: 'table-header-group',
                        },
                    }}
                >
                    <Tr>
                        <Th textAlign={'center'}>Fullname</Th>
                        <Th textAlign={'center'}>Email</Th>
                        <Th textAlign={'center'}>Mobile</Th>
                        <Th textAlign={'center'}>Type</Th>
                        <Th textAlign={'center'}>Created At</Th>
                        <Th textAlign={'center'}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                        base: 'block',
                        lg: 'table-row-group',
                        }}
                        sx={{
                        '@media print': {
                            display: 'table-row-group',
                        },
                    }}
                >
                    {admins.length > 0 &&
                        admins.map((admin, idx) => (
                            <Tr
                                key={idx}
                                display={{
                                    base: 'grid',
                                    md: 'table-row',
                                }}
                                sx={{
                                    '@media print': {
                                    display: 'table-row',
                                    },
                                    gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                    gridGap: '10px',
                                }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {admin.fullname}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {admin.email}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {admin.mobile}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {admin.user_type == 1 ? 'Super Admin' : 'Admin'}
                                </Td>
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {admin.created_at.split('T')[0]}
                                </Td>
                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEdit />}
                                            onClick={() => handleEdit(admin.id)}
                                        />
                                        <IconButton
                                            colorScheme="red"
                                            variant="outline"
                                            aria-label="delete"
                                            icon={<BsFillTrashFill />}
                                            onClick={() => onClickDelete(admin.id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
        )
    }

    return (
        <>
            <Flex justifyContent={'center'} className="admin_title">
                <Text fontSize="xl">ADMINS</Text>
            </Flex>

            <Flex justifyContent={'center'} w={{ base: '100%', md: '100%', sm: 'full' }} className="admin_add_button">
                <Box>
                    <Button
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        variant={'solid'}
                        size="sm"
                        onClick={() => navigate('/add-admin')}
                    >
                        Add New
                    </Button>
                </Box>
            </Flex>

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={deleteData}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Name' size='md' value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                    <Input placeholder='Email' size='md' value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} />
                    <Input placeholder='Mobile' size='md' value={filterMobile} onChange={(e) => setFilterMobile(e.target.value)} />
                    <Select placeholder='Select Type' size='md' value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                        <option value='1'>Super Admin</option>
                        <option value='2'>Admin</option>
                    </Select>
                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
                className="admin_table"
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
        </>
    )
}
