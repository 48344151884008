import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

export default function ToastMessage(type='info', message) {
    if(type == 'success') {
        toast.success(message);
    } else if(type == 'warning') {
        toast.warning(message);
    } else if(type == 'error') {
        toast.error(message);
    } else {
        toast.info(message);
    }
}
