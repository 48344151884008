// @ts-nocheck
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../common/TextError';
// @ts-ignore
import customStyle from '../../assets/css/modular/custom-form.module.css';

export default function RadioButtons(props) {
    const { label, name, options, required, disabled = false, ...rest } = props;

    return (
        <div className={`form-control ${customStyle.custom_form}`}>
            <label className={customStyle.custom_label} htmlFor={name}>{label} {required == true ? <span className='required'>*</span> : '' }</label>
            <Field as="select" id={name} name={name} {...rest}>
                {
                    ({field}) => {
                        return options.map(option => {
                            return (
                                <div className={customStyle.radio_options} key={option.key}>
                                    <React.Fragment>
                                        <input type="radio" id={option.key} {...field} value={option.key} checked={field.value == option.key} disabled={disabled} />
                                        <label htmlFor={option.key}>{option.value}</label>
                                    </React.Fragment>
                                </div>
                            )
                        })
                    }
                }
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}
