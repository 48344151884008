import React, { useEffect, useState } from 'react';
import './institutes.css';
import {
  Flex,
  ButtonGroup,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Box,
  Button,
  Input,
  Text,
} from '@chakra-ui/react';
import { AiFillEdit } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { ceil } from 'lodash';
import EvergreenPagination from '../../components/Pagination/EvergreenPagination';
import ToastMessage from '../../components/utility/ToastMessage';
import Space from '../../components/utility/Space';
import CustomSpinner from '../../components/utility/CustomSpinner';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../../components/common/CommonModal';

export const Institutes = () => {
  return <InstituteTableDemo />;
};

const token = localStorage.getItem('my_token');

function InstituteTableDemo() {
    const header = ['Institute', 'Shortname', 'Action'];
    const [institutes, setInstitutes] = useState([]);
    const limit = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [targetDeleteId, setTargetDeleteId] = useState(null);

    const [filterInstitute, setFilterInstitute] = useState('');
    const [filterShortname, setFilterShortname] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const getInstitutes = async () => {
        setIsLoading(true);
        try {
            const params = {
                limit,
                page: currentPage,
                institute: filterInstitute,
                shortname: filterShortname
            }

            let config = {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            };

            var apiResponse = (
                await axios.get(`${process.env.REACT_APP_API_URL}/api/institute`, {
                    params: params,
                    headers: config
                })
            ).data;
            const results = apiResponse.results;
            if (results[0] && results[0].length > 0) {
                setInstitutes(results[0]);
                if (results[1].count) {
                setTotalPages(ceil(results[1].count / limit));
                }
            }else{
                setInstitutes([]);
            }
        } catch (err) {
            const { data, status } = err.response;
            if(status === 401) {
                ToastMessage('error', data.message);
            }else{
                ToastMessage('error', 'Something went wrong');
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getInstitutes();
    }, [currentPage]);

    const handleSearch = () => {
        setCurrentPage(1);
        getInstitutes();
    }

    const handleClear = () => {
        setFilterInstitute('');
        setFilterShortname('');
    }

    const onClickInstituteDelete = (id) => {
        setDisplayDeleteModal(true);
        setTargetDeleteId(id);
    }

    const handleDelete = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/institute/${id}`,{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                getInstitutes();
                alert("Deleted Successfully");
            })
            .catch((error) => {
                const {status} = error?.response;

                let message = 'Something went wrong';
                if(status == 422) {
                    message = 'Invalid Input';
                }
                ToastMessage('error',message);
            });
    }

    const DisplayData = () => {
        return (
            <Table
                variant="striped"
                color={'black'}
                display={{
                    base: 'block',
                    md: 'table',
                }}
                sx={{
                    '@media print': {
                    display: 'table',
                    },
                }}
            >
                <Thead
                    display={{
                    base: 'none',
                    md: 'table-header-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-header-group',
                    },
                    }}
                >
                    <Tr>
                    {header.map((x) => (
                        <Th key={x} textAlign={'center'}>
                            {x}
                        </Th>
                    ))}
                    </Tr>
                </Thead>
                <Tbody
                    display={{
                    base: 'block',
                    lg: 'table-row-group',
                    }}
                    sx={{
                    '@media print': {
                        display: 'table-row-group',
                    },
                    }}
                >
                    {institutes.map((institute, idx) => {
                        return (
                            <Tr
                                key={idx}
                                display={{
                                    base: 'grid',
                                    md: 'table-row',
                                }}
                                sx={{
                                    '@media print': {
                                    display: 'table-row',
                                    },
                                    gridTemplateColumns: 'minmax(0px, 35%) minmax(0px, 65%)',
                                    gridGap: '10px',
                                }}
                            >
                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {institute.institute}
                                </Td>

                                <Td
                                    fontSize="md"
                                    fontWeight="hairline"
                                    textAlign={'center'}
                                >
                                    {institute.shortname}
                                </Td>

                                <Td textAlign={'center'}>
                                    <ButtonGroup variant="solid" size="sm" spacing={3}>
                                        <IconButton
                                            colorScheme="green"
                                            aria-label="edit"
                                            icon={<AiFillEdit />}
                                            onClick={() => navigate(`/institute/${institute.id}/edit`)}
                                        />
                                        <IconButton
                                            colorScheme="red"
                                            variant="outline"
                                            aria-label="delete"
                                            icon={<BsFillTrashFill />}
                                            onClick={() => onClickInstituteDelete(institute?.id)}
                                        />
                                    </ButtonGroup>
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        )
    }

    return (
        <>
            <Flex justifyContent={'center'}>
                <Text fontSize="xl">INSTITUTES</Text>
            </Flex>

            <Flex justifyContent={'center'} w={{ base: '100%', md: '100%', sm: 'full' }} className="admin_add_button">
                <Box>
                    <Button
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        variant={'solid'}
                        size="sm"
                        onClick={() => navigate('/institute/add')}
                    >
                        Add New
                    </Button>
                </Box>
            </Flex>

            { displayDeleteModal &&
                <CommonModal
                    reset={setDisplayDeleteModal}
                    setTargetDeleteId={setTargetDeleteId}
                    deleteAction={handleDelete}
                    targetDeleteId={targetDeleteId}
                />
            }

            {/* Filter Box */}
            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                backgroundColor="#ffffff"
                borderRadius={'lg'}
                className="admin_table"
                direction="column"
            >

                <Flex className='search_items' gap="4">
                    <Input placeholder='Institute' size='md' value={filterInstitute} onChange={(e) => setFilterInstitute(e.target.value)} />
                    <Input placeholder='Shortname' size='md' value={filterShortname} onChange={(e) => setFilterShortname(e.target.value)} />
                </Flex>
                <Flex mt={'10px'} direction='row' gap='4'>
                    <Button colorScheme='teal' variant='outline' size='sm' onClick={handleSearch}>
                        Search
                    </Button>
                    <Button colorScheme='red' variant='outline' size='sm' onClick={handleClear}>
                        Clear
                    </Button>
                </Flex>
            </Flex>

            <Space />

            <Flex
                w={{ base: '95%', md: '95%', sm: 'full' }}
                m={'auto'}
                p={3}
                alignItems="center"
                justifyContent="center"
                border={'1px'}
                borderColor="gray.300"
                borderRadius={'lg'}
            >
                {isLoading
                    ? <CustomSpinner />
                    : <DisplayData />
                }
            </Flex>
            <EvergreenPagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
}
